import React, { useState, useEffect } from 'react';
import JobPostsCard from '../../components/Shared/JobPostsCard';
// @ts-ignore
import { ReactComponent as ArrowBottom } from '../../assets/img/arrow-bottom.svg';
import SortPopup from '../../components/Shared/SortPopup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { jobPost as jobPostType } from '../../types/jobPost';
import BusinessesSkeleton from '../ManageBusiness/BusinessesSkeleton';
import ReactPaginate from 'react-paginate';
import qs from 'query-string';
import { sortItems } from '../../types/sortPopup';
import { observer } from 'mobx-react-lite';
import { businessStore, userStore, jobPostStore } from '../../store';
import { useLazyQuery } from '@apollo/client';
import {
  ADMIN_GET_ALL_JOB_POSTS,
  GET_ADMIN_ALL_BUSINESS_USERS,
} from '../../apollo/queries';
import proxy from '../../tools/proxy';
// @ts-ignore
import SnackbarProvider from 'react-simple-snackbar';

const LIMIT = 10;

type searchObj = {
  searchBy: string;
  sortBy: sortItems;
  paginationDto: {
    skip: number;
    limit: number;
  };
};

const JobPosts: React.FC = () => {
  const { getBusiness } = userStore;
  const { isLocation, isBilled } = businessStore;
  const { setUsers, isUsers, setJobPostCount } = jobPostStore;

  const [sortPopupOpen, toggleSortPopup] = useState<boolean>(false);
  const [pagesCount, updatePagesCount] = useState<number>(0);
  const [currentQueryPage, setCurrentQueryPage] = useState<number>(0);
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
  const [searchedObject, setSearchObj] = useState<searchObj>({
    searchBy: '',
    sortBy: sortItems.BY_LAST_ADDED_DATE,
    paginationDto: {
      skip: 0,
      limit: LIMIT,
    },
  });

  const { search } = useLocation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [getJobPosts, { data, loading }] = useLazyQuery(
    ADMIN_GET_ALL_JOB_POSTS,
    {
      variables: {
        businessId: id,
        ...searchedObject,
      },
      fetchPolicy: 'network-only',
    },
  );

  const [getAllUsers] = useLazyQuery(GET_ADMIN_ALL_BUSINESS_USERS, {
    variables: {
      businessId: id,
      searchBy: '',
      sortBy: sortItems.BY_LAST_ADDED_DATE,
      paginationDto: {
        skip: 0,
        limit: 10000,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const query = existingQuery();
    if ('page' in query) {
      const offset = (Number(query.page) - 1) * LIMIT;
      setCurrentQueryPage(Number(query.page) - 1);
      const obj = {
        paginationDto: {
          limit: LIMIT,
          skip: offset,
        },
      };
      setSearchObj((prevState): searchObj => {
        return { ...prevState, ...obj };
      });
    }

    // @ts-ignore
    if ('searchBy' in query && query.searchBy.length) {
      setSearchObj((prevState): searchObj => {
        return {
          ...prevState,
          paginationDto: {
            limit: 10,
            skip: 0,
          },
        };
      });
      setCurrentQueryPage(0);
    }

    setSearchObj((prevState): searchObj => {
      return { ...prevState, ...query };
    });
  }, [search]);

  useEffect(() => {
    getJobPosts();
  }, [getBusiness]);

  useEffect(() => {
    if (!isPageLoaded) {
      setIsPageLoaded(true);
    }
    getJobPosts();
  }, [searchedObject]);

  useEffect(() => {
    getUsersForJobPost();
  }, []);

  const getUsersForJobPost = async () => {
    try {
      const { data } = await getAllUsers();
      setUsers(data.adminGetAllBusinessUsersBusinessWide.collection);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    proxy.subscribe('get_job_posts', getJobPosts);
    if (data) {
      const count = Math.ceil(
        data.getAllAdminJobPostsByBusinessWideCollection.count / LIMIT,
      );
      updatePagesCount(count);
    }

    return () => {
      proxy.unsubscribe('get_job_posts');
    };
  }, [data]);

  useEffect(() => {
    if (data) {
      const { count } = data.getAllAdminJobPostsByBusinessWideCollection;
      setJobPostCount(count);
    }
  }, [data]);

  const onPopupToggle = () => {
    toggleSortPopup(!sortPopupOpen);
  };

  const onPopupClose = () => {
    toggleSortPopup(false);
  };

  const onSelect = (type: string) => {
    const queryParams = existingQuery();
    const newQueries = { ...queryParams, sortBy: type };
    history.push({ search: qs.stringify(newQueries) });
    toggleSortPopup(false);
  };

  const setSortText = () => {
    const queryObject = existingQuery();
    if ('sortBy' in queryObject) {
      switch (queryObject.sortBy) {
        case sortItems.BY_NAME_A_TO_Z:
          return 'Name (A to Z)';
        case sortItems.BY_NAME_Z_TO_A:
          return 'Name (Z to A)';
        case sortItems.BY_LAST_ADDED_DATE:
          return 'By last added date';
      }
    }
    return 'By last added date';
  };

  const existingQuery = () => {
    // eslint-disable-next-line no-restricted-globals
    return qs.parse(location.search);
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    if (!isPageLoaded) return;
    const queryParams = existingQuery();
    const newQueries = { ...queryParams, page: selectedItem.selected + 1 };
    history.push({ search: qs.stringify(newQueries) });
  };

  const findCount = (
    item: { _id: string },
    arr: { _id: string; count: number }[],
  ) => {
    const found = arr.find((element) => element._id === item._id);
    if (found) {
      return found.count;
    }
    return 0;
  };

  return (
    <div className="business job-post">
      <div>
        {!isLocation && !loading ? (
          <h5 className="mb-3">
            In order to create job post, at least one location should be created
          </h5>
        ) : null}
        {!isUsers && !loading ? (
          <h5 className="mb-3">
            In order to create job post, at least one user should be created
          </h5>
        ) : null}
      </div>
      {data &&
      data.getAllAdminJobPostsByBusinessWideCollection.collection.length ? (
        <div className="label-holder flex align-center">
          <span className="item" onClick={onPopupToggle}>
            {setSortText()}
          </span>
          <ArrowBottom
            className={sortPopupOpen ? 'up' : 'down'}
            onClick={onPopupToggle}
          />
          {sortPopupOpen && (
            <SortPopup onClose={onPopupClose} onSelect={onSelect} />
          )}
        </div>
      ) : null}
      <div className="job-container">
        <div>
          {loading ? (
            <BusinessesSkeleton />
          ) : (
            data &&
            data.getAllAdminJobPostsByBusinessWideCollection.collection.map(
              (item: jobPostType, index: number) => {
                return (
                  <div
                    key={item._id}
                    className={`${index > 0 ? 'mt-2' : ''} ${
                      index === 0 ? 'first' : ''
                    }`}>
                    <SnackbarProvider>
                      <JobPostsCard
                        count={findCount(
                          item,
                          data.getAllAdminJobPostsByBusinessWideCollection
                            .matchesCountList || [],
                        )}
                        jobPost={item}
                      />
                    </SnackbarProvider>
                  </div>
                );
              },
            )
          )}
        </div>
        <div>
          <div>
            {pagesCount > 1 && (
              <div className="pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pagesCount}
                  initialPage={currentQueryPage}
                  disableInitialCallback={true}
                  previousLabel="<"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(JobPosts);
