import React from 'react';
// @ts-ignore
import { default as Warning } from '../../assets/img/warning-icon.svg';

interface RemovePopupProps {
  onClose: () => void;
  onRemoveItem: () => void;
  itemToRemove: String;
  type: String;
  loading?: boolean;
}

const RemovePopup: React.FC<RemovePopupProps> = ({
  loading,
  onClose,
  itemToRemove,
  onRemoveItem,
  type,
}) => {
  return (
    <div className="remove-popup">
      <div className="flex align-center">
        <img src={Warning} alt="warning-icon" className="mr-1" />
        <h5>Are you sure you want to delete?</h5>
      </div>
      <p className="remove-popup--content">
        You are about to delete <span className="item">“{itemToRemove}”</span>{' '}
        {type}. Are you sure? <br /> If you delete it, the record will be
        erased.
      </p>
      <div className="half-wrap flex justify-content-between mt-4">
        <button
          type="submit"
          className="btn btn--danger w-48"
          disabled={loading}
          onClick={onRemoveItem}>
          Delete {type}
        </button>
        <button
          className="btn btn--secondary w-48"
          type="button"
          onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default RemovePopup;
