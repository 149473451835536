import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import { userStore } from '../../store';
import { RouteProps } from 'react-router';
import { Roles } from '../../types/roles';

const PermissionRoute = ({
  roles,
  ...props
}: { roles: (keyof typeof Roles)[] } & RouteProps) => {
  const { userRole } = userStore;

  return roles.includes(userRole) ? <Route {...props} /> : <Redirect to="/" />;
};

export default PermissionRoute;
