import React, { useState, useEffect } from 'react';
// @ts-ignore
import { default as Passed } from '../../../assets/img/passed-icon.svg';
import Dropdown from '../../Shared/Dropdown';
import AdditionalCompensation from './AdditionalCompensation';

interface CompensationProps {
  updateData: (dataType: string, data: string | null) => void;
  selected: {
    compensationType: string;
    additionalCompensation: string;
    rateRangeFrom: string;
    rateRangeTo: string;
  };
}

type Item = {
  _id: string;
  name: string;
};

const compensation = [
  {
    _id: 1,
    name: 'Hourly',
  },
  {
    _id: 2,
    name: 'Salary',
  },
];

const CompensationType: React.FC<CompensationProps> = ({
  updateData,
  selected,
}) => {
  const [valTo, setValTo] = useState<string>('');
  const [valFrom, setValFrom] = useState<string>('');
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [inCorrectValues, setInCorrectValues] = useState<boolean>(false);

  useEffect(() => {
    if (
      selected &&
      selected.rateRangeFrom &&
      selected.rateRangeTo &&
      !isLoaded
    ) {
      setIsLoaded(true);
      setValFrom(selected.rateRangeFrom);
      setValTo(selected.rateRangeTo);
    }
  }, [selected]);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    updateData(type, event.target.value);
    switch (type) {
      case 'rateRangeFrom':
        setValFrom(event.target.value);
        break;
      case 'rateRangeTo':
        setValTo(event.target.value);
        break;
      default:
        return;
    }
  };

  const onSelect = (data: Item) => {
    let type = '';
    switch (data.name) {
      case 'Hourly':
        type = 'HOURLY';
        break;
      case 'Salary':
        type = 'SALARY';
        break;
      default:
        type = 'SALARY';
    }
    updateData('compensationType', type);
  };

  const selectedName = () => {
    if (selected && selected.compensationType) {
      switch (selected.compensationType) {
        case 'HOURLY':
          return 'Hourly';
        case 'SALARY':
          return 'Salary';
        default:
          return 'Salary';
      }
    }
    return undefined;
  };

  const preventForbiddenChars = (e: any) => {
    const integers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (
      e.code === 'Minus' ||
      e.code === 'KeyE' ||
      e.code === 'NumpadSubtract' ||
      !integers.includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (valFrom && valTo) {
      if (Number(valTo) < Number(valFrom)) {
        setInCorrectValues(true);
      } else {
        setInCorrectValues(false);
      }
    }
  }, [valTo, valFrom]);

  return (
    <div className="job-post--item">
      <div className="flex align-center justify-content-between head">
        <div className="flex align-center">
          {selected &&
          selected.compensationType &&
          selected.rateRangeFrom &&
          selected.rateRangeTo ? (
            <img src={Passed} alt="success" />
          ) : (
            <p className="number">3</p>
          )}
          <p className="headline">What type of compensation for this role?*</p>
        </div>
      </div>
      <div>
        <p className="sub-headline">Select type of compensation</p>
        <div className="wrap flex align-center justify-content-between">
          <div className="flex-1 mr-1">
            <Dropdown
              onSelect={onSelect}
              selected={selectedName()}
              data={compensation as []}
              placeholder="Select compensation type"
            />
          </div>
          <input
            placeholder="From $20.00"
            onChange={(e) => onChange(e, 'rateRangeFrom')}
            value={valFrom}
            type="text"
            onKeyPress={preventForbiddenChars}
            className="input-item mr-1"
          />
          <input
            placeholder="To $40.00"
            onChange={(e) => onChange(e, 'rateRangeTo')}
            value={valTo}
            type="text"
            onKeyPress={preventForbiddenChars}
            className="input-item mr-1"
          />
          <div className="ml-1">
            <AdditionalCompensation
              data={{
                additionalCompensation: selected.additionalCompensation,
                compensationType: selected.compensationType,
              }}
              updateData={updateData}
            />
          </div>
        </div>
        {inCorrectValues && (
          <div className="error mt-2">
            Maximal rate must not be less then minimal
          </div>
        )}
      </div>
    </div>
  );
};

export default CompensationType;
