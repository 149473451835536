import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import React, { useEffect, useState } from 'react';
import {
  ADMIN_CREATE_BUSINESS_REGISTRATION,
  ADMIN_UPDATE_BUSINESS,
} from '../../apollo/mutations';
import { GET_ADMIN_ALL_USERS } from '../../apollo/queries';
import { useMutation } from '@apollo/client';
import {
  transformFieldsToString,
  capitalizeFirstLetter,
} from '../../tools/heplers';
import { STATES, BACKGROUND } from '../../constants';
// @ts-ignore
import { useSnackbar } from 'react-simple-snackbar';
import extractGraphQLErrors from '../../tools/extractGraphqlErrors';
import { business } from '../../types/business';
import { useLazyQuery } from '@apollo/client';
import { businessStore } from '../../store';
import { observer } from 'mobx-react';
import CustomSelect from '../Shared/CustomSelect';

interface AddBusinessProps {
  onClose: () => void;
  onSuccess: () => void;
  itemToEdit: business | null;
}

const AddBusiness: React.FC<AddBusinessProps> = ({
  onClose,
  onSuccess,
  itemToEdit,
}) => {
  const { setUsersList, transformedUserList } = businessStore;

  const [createBusiness] = useMutation(
    itemToEdit ? ADMIN_UPDATE_BUSINESS : ADMIN_CREATE_BUSINESS_REGISTRATION,
  );

  const [getAllUsers] = useLazyQuery(GET_ADMIN_ALL_USERS);

  useEffect(() => {
    if (!transformedUserList.length) {
      getUsers();
    }
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getUsers = () => {
    setIsLoading(true);
    getAllUsers()
      .then(({ data }) => {
        setUsersList(data.adminGetAllUsers);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const initialValues: business = {
    name: itemToEdit && itemToEdit.name ? itemToEdit.name : '',
    phone: itemToEdit && itemToEdit.phone ? itemToEdit.phone : '+1',
    address1: itemToEdit && itemToEdit.address1 ? itemToEdit.address1 : '',
    address2: itemToEdit && itemToEdit.address2 ? itemToEdit.address2 : '',
    owner: itemToEdit && itemToEdit.owner ? itemToEdit.owner._id : '',
    city: itemToEdit && itemToEdit.city ? itemToEdit.city : '',
    backgroundColor:
      itemToEdit && itemToEdit.backgroundColor
        ? itemToEdit.backgroundColor
        : 'red',
    zip: itemToEdit && itemToEdit.zip ? Number(itemToEdit.zip) : '',
    state: itemToEdit && itemToEdit.state ? itemToEdit.state : STATES[0].value,
    active: itemToEdit && 'active' in itemToEdit ? itemToEdit.active : true,
  };

  const options = {
    style: {
      textAlign: 'center',
    },
  };

  const [openSnackbar] = useSnackbar(options);

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required('Company name is required'),
    phone: Yup.string()
      .test('empty', 'Phone is required', (val) => !!val)
      .test(
        'startsWith',
        'Phone should start with +1',
        (val) => !!val?.startsWith('+1'),
      )
      .test(
        'len',
        'Length should be 12 characters',
        (val) => val?.length === 12,
      ),
    address1: Yup.string().required('Address1 is required'),
    address2: Yup.string(),
    owner: Yup.string(),
    city: Yup.string().required('City is required'),
    zip: Yup.mixed().required('Zip is required'),
  });

  const createNewBusiness = async (data: business) => {
    const fieldsToTransform = ['zip'];
    if (itemToEdit) {
      data.businessId = itemToEdit._id;
    }

    const transformedData = transformFieldsToString(fieldsToTransform, data);
    if (itemToEdit && itemToEdit.name === data.name) {
      delete transformedData.name;
    }
    if (itemToEdit && itemToEdit.owner) {
      delete transformedData.owner;
    }

    try {
      setIsLoading(true);
      await createBusiness({
        variables: {
          dto: {
            ...transformedData,
          },
        },
      });
      onSuccess();
      onClose();
    } catch (e: any) {
      const err = extractGraphQLErrors(e);
      if (
        err.name &&
        err.name === 'error.name.businessWithThisNameAlreadyExist'
      ) {
        openSnackbar('Business with this name already exists');

        setIsLoading(false);
        return;
      } else if (err.zip && err.zip === 'error.zip.zipNotFound') {
        openSnackbar("Wrong zip code or doesn't available");

        setIsLoading(false);
        return;
      }
      openSnackbar(e.message);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return (
    <div className="add-business">
      <Formik
        enableReinitialize={false}
        initialValues={initialValues}
        validationSchema={SignupSchema}
        onSubmit={(values: business) => {
          const valCopy = { ...values };
          valCopy.phone = `${valCopy.phone}`;
          createNewBusiness(valCopy);
        }}>
        {({ errors, touched, dirty, isValid }) => {
          return (
            <Form>
              <div className="flex justify-between mb-4">
                <h5>Business Information</h5>
                <div className="flex align-center">
                  <p className="m-0">Active Business</p>
                  <div className="flex align-center styled-switcher">
                    <Field
                      type="checkbox"
                      id="switch"
                      name="active"
                      className="input"
                    />
                    <label htmlFor="switch" className="checkbox-label">
                      Toggle
                    </label>
                  </div>
                </div>
              </div>
              <div className="input-wrap mb-3">
                <label className="input-label">COMPANY NAME</label>
                {isLoading ? (
                  <div className="ssc-square skeleton-item"></div>
                ) : (
                  <Field
                    name="name"
                    type="text"
                    placeholder="Company Name"
                    className="input"
                  />
                )}
                {errors.name && touched.name ? (
                  <div className="error">{errors.name}</div>
                ) : null}
              </div>
              {!itemToEdit ? (
                <div className="input-wrap mb-3">
                  <label className="input-label">COMPANY OWNER</label>
                  {isLoading ? (
                    <div className="ssc-square skeleton-item"></div>
                  ) : (
                    <Field
                      name="owner"
                      options={transformedUserList}
                      component={CustomSelect}
                      placeholder="Select owner..."
                      isMulti={false}
                    />
                  )}
                </div>
              ) : null}
              <div className={`input-wrap mb-3 ${!isLoading && 'phone-wrap'}`}>
                <label className="input-label">PHONE NUMBER</label>
                {isLoading ? (
                  <div className="ssc-square skeleton-item"></div>
                ) : (
                  <Field
                    name="phone"
                    type="text"
                    placeholder="+11111111111"
                    className="input"
                  />
                )}
                {errors.phone && touched.phone ? (
                  <div className="error">{errors.phone}</div>
                ) : null}
              </div>
              <div className="input-wrap mb-3">
                <label className="input-label">ADDRESS 1</label>
                {isLoading ? (
                  <div className="ssc-square skeleton-item"></div>
                ) : (
                  <Field
                    name="address1"
                    type="text"
                    placeholder="Address 1"
                    className="input"
                  />
                )}
                {errors.address1 && touched.address1 ? (
                  <div className="error">{errors.address1}</div>
                ) : null}
              </div>
              <div className="input-wrap mb-3">
                <label className="input-label">ADDRESS 2</label>
                {isLoading ? (
                  <div className="ssc-square skeleton-item"></div>
                ) : (
                  <Field
                    name="address2"
                    type="text"
                    placeholder="Address 2"
                    className="input"
                  />
                )}
                {errors.address2 && touched.address2 ? (
                  <div className="error">{errors.address2}</div>
                ) : null}
              </div>
              <div className="input-wrap mb-3">
                <label className="input-label">CITY</label>
                {isLoading ? (
                  <div className="ssc-square skeleton-item"></div>
                ) : (
                  <Field
                    name="city"
                    type="text"
                    placeholder="City"
                    className="input"
                  />
                )}
                {errors.city && touched.city ? (
                  <div className="error">{errors.city}</div>
                ) : null}
              </div>
              <div className="flex justify-content-between mb-3 row">
                <div className="col-4">
                  <div className="input-wrap">
                    <label className="input-label">BACKGROUND</label>
                    {isLoading ? (
                      <div className="ssc-square skeleton-item"></div>
                    ) : (
                      <Field
                        as="select"
                        name="backgroundColor"
                        className="select">
                        {BACKGROUND.map((el) => {
                          return (
                            <option key={el} value={el}>
                              {capitalizeFirstLetter(el)}
                            </option>
                          );
                        })}
                      </Field>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <div className="input-wrap">
                    <label className="input-label">STATE</label>
                    {isLoading ? (
                      <div className="ssc-square skeleton-item"></div>
                    ) : (
                      <Field as="select" name="state" className="select">
                        {STATES.map((el) => {
                          return (
                            <option key={el.value} value={el.value}>
                              {el.label}
                            </option>
                          );
                        })}
                      </Field>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <div className="input-wrap">
                    <label className="input-label">ZIP</label>
                    {isLoading ? (
                      <div className="ssc-square skeleton-item"></div>
                    ) : (
                      <Field
                        name="zip"
                        type="text"
                        placeholder="ZIP"
                        className="input"
                      />
                    )}
                    {errors.zip && touched.zip ? (
                      <div className="error">{errors.zip}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="half-wrap flex justify-content-between">
                <button
                  type="submit"
                  disabled={isLoading || !(dirty && isValid)}
                  className="btn btn--main w-48">
                  {itemToEdit ? 'Update Business' : 'Add Business'}
                </button>
                <button
                  className="btn btn--secondary w-48"
                  type="button"
                  onClick={onClose}>
                  Cancel
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default observer(AddBusiness);
