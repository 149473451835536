import React, { useState, useEffect } from 'react';
// @ts-ignore
import { default as Passed } from '../../../assets/img/passed-icon.svg';

interface RateProps {
  updateData: (dataType: string, data: string) => void;
  selected: string | number;
}

const RateOfExperience: React.FC<RateProps> = ({ updateData, selected }) => {
  const [val, setVal] = useState<string | number>('');

  useEffect(() => {
    setVal(selected);
  }, [selected]);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    setVal(event.target.value);
    updateData(type, event.target.value);
  };

  const preventForbiddenChars = (e: any) => {
    if (
      e.code === 'Minus' ||
      e.code === 'KeyE' ||
      e.code === 'NumpadSubtract'
    ) {
      e.preventDefault();
    }
  };

  return (
    <div className="job-post--item">
      <div className="flex align-center justify-content-between head">
        <div className="flex align-center">
          {selected ? (
            <img src={Passed} alt="success" />
          ) : (
            <p className="number">4</p>
          )}
          <p className="headline">Enter your range of years of experience.*</p>
        </div>
      </div>
      <div>
        <p className="sub-headline">Enter years</p>
        <input
          placeholder="Years"
          onChange={(e) => onChange(e, 'experience')}
          onWheel={(e) => e.preventDefault()}
          onScroll={(e) => e.preventDefault()}
          type="text"
          onKeyPress={preventForbiddenChars}
          value={val}
          className="input-item"
        />
      </div>
    </div>
  );
};

export default RateOfExperience;
