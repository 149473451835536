import React, { useState, useEffect } from 'react';
// @ts-ignore
import { default as Passed } from '../../../assets/img/passed-icon.svg';
import Dropdown from '../../Shared/Dropdown';
import { GET_ADMIN_LOCATION } from '../../../apollo/queries';
import { useQuery } from '@apollo/client';
import { sortItems } from '../../../types/sortPopup';
import { useParams } from 'react-router-dom';

interface LocationProps {
  updateData: (dataType: string, data: string | Item) => void;
  selected: string;
}

type Item = {
  _id: string;
  name: string;
};

const AddLocation: React.FC<LocationProps> = ({ updateData, selected }) => {
  const [locations, setLocations] = useState<[]>([]);

  const { id } = useParams<{ id: string }>();

  const { data, loading } = useQuery(GET_ADMIN_LOCATION, {
    variables: {
      businessId: id,
      getAdminLocationsDto: {
        searchBy: '',
        sortBy: sortItems.BY_NAME_A_TO_Z,
        skip: 0,
        limit: 10000,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      setLocations(data.getAdminLocations.collection);
    }
  }, [data]);

  const onSelect = (data: Item) => {
    updateData('location', data);
  };

  return (
    <div className="job-post--item">
      <div className="flex align-center justify-content-between head">
        <div className="flex align-center">
          {selected ? (
            <img src={Passed} alt="success" />
          ) : (
            <p className="number">10</p>
          )}
          <p className="headline">Select a location for this role*</p>
        </div>
      </div>
      <div className="mt-3">
        {loading ? (
          <div className="ssc-square skeleton-item job-skeleton"></div>
        ) : (
          <Dropdown
            up={true}
            onSelect={onSelect}
            data={locations}
            selected={selected}
            placeholder="Select Location"
          />
        )}
      </div>
    </div>
  );
};

export default AddLocation;
