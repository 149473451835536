export const demoFirebaseConfig = {
  apiKey: 'AIzaSyAw2PWl7FImStXNtfQgv_k5xPOl6gF7oS4',
  authDomain: 'registration_app-f8b7b.firebaseapp.com',
  databaseURL: 'https://madruga-26baa.firebaseio.com',
  projectId: 'madruga-26baa',
  storageBucket: 'madruga-26baa.appspot.com',
  messagingSenderId: '348813616421',
  appId: '1:348813616421:ios:5635fe8cc7be69900826cc',
};

export const prodFirebaseConfig = {
  apiKey: 'AIzaSyDDr4o5TGbNWZsawsGtFiIjAfKxZRNO5y0',
  authDomain: 'seekr-production.firebaseapp.com',
  databaseURL: 'https://seekr-production-default-rtdb.firebaseio.com',
  projectId: 'seekr-production',
  storageBucket: 'seekr-production.appspot.com',
  messagingSenderId: '171118614381',
  appId: '1:171118614381:ios:bd5d9a79527262f5e02c64',
};
