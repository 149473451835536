import { users } from '../../types/users';
import React, { useRef, useState } from 'react';
// @ts-ignore
import { default as Arrow } from '../../assets/img/arrow-bottom.svg';
import Popup from 'reactjs-popup';
// @ts-ignore
import { default as CrossIcon } from '../../assets/img/cross.svg';
import RemovePopup from './RemovePopup';
import { userStore } from '../../store';
import { Animated } from 'react-animated-css';
import {
  ADMIN_REMOVE_BUSINESS_RELATION,
  ADMIN_REMOVE_BUSINESS_USER_BUSINESS,
  ADMIN_REMOVE_BUSINESS_USER_SYSTEM,
  ADMIN_REMOVE_USER_SYSTEM,
} from '../../apollo/mutations';
import { useMutation } from '@apollo/client';
import proxy from '../../tools/proxy';
// @ts-ignore
import { useSnackbar } from 'react-simple-snackbar';
import { Roles } from '../../types/roles';
import { useParams } from 'react-router-dom';

interface CardUserProps {
  data: users;
  onSuccess: () => void;
  onEdit: (item: users) => void;
  currentBusinessName?: string;
  type?: string;
}

enum removeTypeEnum {
  user = 'user',
  role = 'role',
}

enum removeRoleType {
  user = 'user',
  admin = 'admin',
  manager = 'manager',
  recruiter = 'recruiter',
}

const UserCard: React.FC<CardUserProps> = ({
  data,
  onSuccess,
  type = 'business',
  onEdit,
  currentBusinessName,
}) => {
  const [removeUser] = useMutation(ADMIN_REMOVE_BUSINESS_USER_BUSINESS);
  const [removeRole] = useMutation(ADMIN_REMOVE_BUSINESS_RELATION);
  const [removeRoleSystem] = useMutation(ADMIN_REMOVE_USER_SYSTEM);
  const [removeSystemUser] = useMutation(ADMIN_REMOVE_BUSINESS_USER_SYSTEM);

  const [removeRoleTypeState, setRemoveRoleType] = useState<removeRoleType>(
    removeRoleType.user,
  );
  const [removeType, setRemoveType] = useState<removeTypeEnum>(
    removeTypeEnum.user,
  );
  const [isOpen, toggleOpen] = useState<boolean>(false);
  const [relationId, setRelationId] = useState<string>('');
  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  const { userRole } = userStore;
  const ref = useRef<any>();
  const { id } = useParams<{ id: string }>();

  const options = {
    style: {
      textAlign: 'center',
    },
  };

  const [openSnackbar] = useSnackbar(options);

  const onRemove = async () => {
    setIsRemoving(true);
    if (type === 'business') {
      try {
        if (removeTypeEnum.user === removeType) {
          await removeUser({
            variables: {
              userId: data._id,
              businessId: id,
            },
          });
          openSnackbar('User was successfully removed');
        }
        if (removeTypeEnum.role === removeType) {
          await removeRole({
            variables: {
              businessRelationId: relationId,
              businessId: id,
            },
          });
          openSnackbar('Role was successfully removed');
        }
        proxy.publish('get_users');
        ref.current.close();
        setIsRemoving(false);
      } catch (e: any) {
        ref.current.close();
        console.error(e);
        openSnackbar(e.message);
        setIsRemoving(false);
      }
    }
    if (type === 'system') {
      try {
        if (removeTypeEnum.user === removeType) {
          await removeSystemUser({
            variables: {
              userId: data._id,
            },
          });
          openSnackbar('User was successfully removed');
        }
        if (removeTypeEnum.role === removeType) {
          await removeRoleSystem({
            variables: {
              businessRelationId: relationId,
            },
          });
          openSnackbar('Role was successfully removed');
        }
        proxy.publish('get_users');
        ref.current.close();
        setIsRemoving(false);
      } catch (e: any) {
        ref.current.close();
        console.error(e);
        openSnackbar(e.message);
        setIsRemoving(false);
      }
    }
  };

  const onToggleOpen = () => {
    toggleOpen(!isOpen);
  };

  const handleClick = (
    type: removeTypeEnum,
    relationId: string | null,
    data: any,
  ) => {
    if (relationId) setRelationId(relationId);
    if (type === removeTypeEnum.role) {
      setRemoveType(type);
      switch (data.businessRole) {
        case 'ADMIN':
          setRemoveRoleType(removeRoleType.admin);
          break;
        case 'MANAGER':
          setRemoveRoleType(removeRoleType.manager);
          break;
        case 'RECRUITER':
          setRemoveRoleType(removeRoleType.recruiter);
          break;
        default:
          setRemoveRoleType(removeRoleType.admin);
      }
    } else {
      setRemoveRoleType(removeRoleType.user);
      setRemoveType(type);
    }
    ref.current.open();
  };

  const renderCapitalizedRole = (role: string) => {
    switch (role) {
      case 'ADMIN':
        return 'Admin';
      case 'RECRUITER':
        return 'Recruiter';
      case 'MANAGER':
        return 'Manager';
      default:
        return '';
    }
  };

  const onEditRelation = (
    item: users,
    relation: {
      business: {
        _id: string;
        locations: {
          _id: string;
          name: string;
          address1: string;
          phone: string;
        }[];
      };
      businessLocations: [{ _id: string }];
      businessRole: string;
      _id: string;
      active: boolean;
    },
  ) => {
    const locations = relation.businessLocations.map((loc) => loc._id);
    let outputData = {
      ...item,
      businessRole: relation.businessRole,
      businessRelationId: relation._id,
      userId: item._id,
      active: relation.active,
      locations,
    };
    if (type === 'system') {
      outputData = {
        ...outputData,
        businessId: relation.business._id,
      };
    }
    onEdit(outputData);
  };

  const addRole = (data: users) => {
    onEdit(data);
  };

  return (
    <div>
      <div className={`card-long users ${data.isArchived && 'archived'}`}>
        <div
          className={`name card-long__item flex align-center ${
            data.businessRelations && data.businessRelations.length && 'pointer'
          }`}
          onClick={onToggleOpen}>
          <p className="name">{`${data.firstName} ${data.lastName}`} </p>
          {data.businessRelations && data.businessRelations.length ? (
            <img
              className={isOpen ? 'down' : 'up'}
              src={Arrow}
              alt="arrow"
              style={{ width: '24px' }}
            />
          ) : null}
        </div>
        <div className="address card-long__item">
          {<p>{data.businessRelations && data.businessRelations.length}</p>}
        </div>
        <div className="email card-long__item">
          {<p>{data && data.email}</p>}
        </div>
        <div className="phone card-long__item">
          <p>
            {typeof data.phone === 'string'
              ? data.phone.includes('+1')
                ? data.phone
                : null
              : null}
          </p>
        </div>
        <div className="card-long__item action">
          {(userRole === 'super' || userRole === 'admin') && (
            <div className="remove">
              {(userRole === 'super' || userRole === 'admin') && (
                <button
                  className="action-button remove mr-1"
                  onClick={() =>
                    handleClick(removeTypeEnum.user, null, data)
                  }></button>
              )}
            </div>
          )}
          <div className="add">
            {(userRole === 'super' || userRole === 'admin') && (
              <button
                className="action-button add"
                onClick={() => addRole(data)}></button>
            )}
          </div>
        </div>
        <Popup
          closeOnDocumentClick={false}
          ref={ref}
          className="business"
          modal
          nested>
          {(close: any) => (
            <div className="modal business-modal">
              <img
                onClick={close}
                src={CrossIcon}
                alt="close"
                className="cross-icon"
              />
              <RemovePopup
                loading={isRemoving}
                onClose={close}
                onRemoveItem={onRemove}
                itemToRemove={data.firstName || ''}
                type={removeRoleTypeState}
              />
            </div>
          )}
        </Popup>
      </div>
      {isOpen && (
        <Animated
          animationIn="bounceInLeft"
          animationOut="fadeOut"
          isVisible={isOpen}>
          <div className="card-relations">
            <div className="card-relations__head mb-2">
              <p className="item m-0 flex justify-content-center">Role</p>
              <p className="item m-0 flex justify-content-center">
                Business Name
              </p>
              <p className="item m-0 flex justify-content-center">
                Location Name
              </p>
              <p className="item m-0 flex justify-content-center">
                Location Address
              </p>
              <p className="item m-0 flex justify-content-center">
                Location Phone
              </p>
              <p className="item m-0 flex justify-content-center">Action</p>
            </div>
            {data.businessRelations &&
              data.businessRelations.map((el) => {
                return (
                  <div
                    key={el._id}
                    className="card-relations__item flex justify-content-between align-center">
                    <p className="m-0 role flex justify-content-center">
                      {renderCapitalizedRole(el.businessRole)}
                    </p>
                    <div className="flex flex-column justify-center info">
                      {el.businessRole === 'ADMIN' ? (
                        <div>
                          {el.business.locations.map((location) => {
                            return (
                              <div
                                key={location._id}
                                className="flex justify-content-between mb-1">
                                <p className="m-0 info__item flex justify-content-center">
                                  {el.business.name}
                                </p>
                                <p className="m-0 info__item flex justify-content-center">
                                  {location.name}
                                </p>
                                <p className="m-0 info__item flex justify-content-center">
                                  {location.address1}
                                </p>
                                <p className="m-0 info__item flex justify-content-center">
                                  {location.phone}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div>
                          {el.businessLocations.map((location) => {
                            return (
                              <div
                                key={location._id}
                                className="flex justify-content-between mb-1">
                                <p className="m-0 info__item flex justify-content-center">
                                  {el.business.name}
                                </p>
                                <p className="m-0 info__item flex justify-content-center">
                                  {location.name}
                                </p>
                                <p className="m-0 info__item flex justify-content-center">
                                  {location.address1}
                                </p>
                                <p className="m-0 info__item flex justify-content-center">
                                  {location.phone}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {/*{el.businessLocations.map((location) => {
                        return (
                          <div
                            key={location._id}
                            className="flex justify-content-between mb-1">
                            <p className="m-0 info__item flex justify-content-center">
                              {el.business.name}
                            </p>
                            <p className="m-0 info__item flex justify-content-center">
                              {location.name}
                            </p>
                            <p className="m-0 info__item flex justify-content-center">
                              {location.address1}
                            </p>
                            <p className="m-0 info__item flex justify-content-center">
                              {location.phone}
                            </p>
                          </div>
                        );
                      })}*/}
                    </div>
                    {(userRole === Roles.super ||
                      (userRole === Roles.admin &&
                        el.business.name === currentBusinessName)) && (
                      <div className="flex role justify-content-center">
                        <button
                          className="action-button action-button--small edit mr-1"
                          onClick={() => onEditRelation(data, el)}></button>
                        <button
                          className="action-button action-button--small remove"
                          onClick={() =>
                            handleClick(removeTypeEnum.role, el._id, el)
                          }></button>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </Animated>
      )}
    </div>
  );
};

export default UserCard;
