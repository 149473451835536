import { makeAutoObservable } from 'mobx';
import { Roles } from '../types/roles';
import { LoggedUser } from '../types/common';
import { GET_USER_PROFILE } from '../apollo/queries';
import apolloClient from '../../src/apollo/client';

class UserStore {
  isLoading: boolean = false;
  authorized: boolean = false;
  loggedInUser = {} as LoggedUser;
  user = {
    role: Roles.super,
  };
  openPopupFromSidebar: boolean = false;
  selectedBusinessId: string | null = null;
  getBusiness: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  openPopup = async () => {
    try {
      const { data } = await apolloClient.query({
        query: GET_USER_PROFILE,
        fetchPolicy: 'network-only',
      });
      const { businessProfile } = data;
      this.setUser(businessProfile);
    } catch (e) {
      throw e;
    }
    this.openPopupFromSidebar = !this.openPopupFromSidebar;
  };

  updateBusiness = () => {
    this.getBusiness = !this.getBusiness;
  };

  setBusinessId = (id: string | null) => {
    this.selectedBusinessId = id;
  };

  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  setAuthorization = (isAuthorized: boolean) => {
    this.authorized = isAuthorized;
  };

  setUser = (user: LoggedUser) => {
    this.loggedInUser = user;
    this.authorized = true;
  };

  setUserRole = (role: Roles) => {
    this.user.role = Roles[role];
  };

  logOutUser = () => {
    this.authorized = false;
    this.loggedInUser = {
      firstName: '',
      lastName: '',
      email: '',
      type: '',
      businessRelations: [],
    };
    this.setUserRole(Roles.super);
    this.setBusinessId(null);
  };

  get isAuthorized() {
    return this.authorized;
  }

  get userRole() {
    return this.user.role;
  }
}

const userStore = new UserStore();

export default userStore;
