// @ts-ignore
import { ReactComponent as LogoIcon } from '../../assets/img/logo-dark.svg';
// @ts-ignore
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import LoginScreen from '../../assets/img/login-screen-image.png';
import Auth from '../../tools/Auth';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
// @ts-ignore
import { useSnackbar } from 'react-simple-snackbar';

const ForgotPassword: React.FC = () => {
  const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
  });

  const [loading, setLoading] = useState<boolean>(false);

  const options = {
    style: {
      textAlign: 'center',
    },
  };

  const [openSnackbar] = useSnackbar(options);

  const history = useHistory();

  const redirect = (type: string) => {
    switch (type) {
      case 'forgot':
        history.push('/forgot-password');
        break;
      case 'create':
        history.push('/register');
        break;
      default:
        return false;
    }
  };

  return (
    <div className="wrapper">
      <div className="auth-wrap">
        <div className="left-part">
          <div className="logo-wrap">
            <LogoIcon />
          </div>
          <div className="form-wrap">
            <div className="form-wrap--child">
              <h3 className="head">Forgot your password?</h3>
              <p className="subheadline">
                We are here to help. Enter your email address to get started.
              </p>
              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={SignupSchema}
                onSubmit={(values, { resetForm }) => {
                  const { email } = values;
                  setLoading(true);
                  Auth.resetPassword(email)
                    .then(() => {
                      resetForm();
                      openSnackbar('Check your email');
                      setLoading(false);
                    })
                    .catch((e) => {
                      setLoading(false);
                      openSnackbar('Check writing email');
                      console.error(e);
                    });
                }}>
                {({
                  errors,
                  touched,
                  handleSubmit,
                  setValues,
                  isSubmitting,
                  dirty,
                  isValid,
                }) => (
                  <Form>
                    <div className="input-wrap">
                      <label>EMAIL*</label>
                      <Field
                        name="email"
                        type="email"
                        placeholder="Email"
                        className="input"
                      />
                      {errors.email && touched.email ? (
                        <div className="error">{errors.email}</div>
                      ) : null}
                    </div>
                    <div>
                      <button
                        className="btn btn--main w-100"
                        disabled={!(dirty && isValid) || loading}>
                        {loading ? 'Loading' : 'Reset password'}
                      </button>
                    </div>
                    <div className="bottom-hint">
                      <p className="bottom-hint--left">
                        You don't have an account?
                      </p>
                      <p
                        className="bottom-hint--right"
                        onClick={() => redirect('create')}>
                        Create an account
                      </p>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div
          className="image-wrap"
          style={{
            backgroundImage: 'url(' + LoginScreen + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}></div>
      </div>
    </div>
  );
};

export default observer(ForgotPassword);
