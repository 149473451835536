import UsersSystem from './components/UsersSystem';
import SearchInput from '../../components/Shared/SearchInput';
import React, { useEffect, useRef, useState } from 'react';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import { default as AddIcon } from '../../assets/img/add-icon.svg';
import Popup from 'reactjs-popup';
import AddSystemWideUser from '../../components/AddSystemWideUser/AddSystemWideUser';
// @ts-ignore
import { default as CrossIcon } from '../../assets/img/cross.svg';
// @ts-ignore
import SnackbarProvider from 'react-simple-snackbar';
import { users } from '../../types/users';
import { location as locations } from '../../types/locations';

const ManageUsers: React.FC = () => {
  const ref = useRef<any>();
  const [searchBy, setSearchBy] = useState<string>('');
  const history = useHistory();
  const [itemToEdit, setItemToEdit] = useState<locations | users | null>(null);

  const onEditUser = (data: users) => {
    setItemToEdit(data);
    ref.current.open();
  };

  const existingQuery = () => {
    // eslint-disable-next-line no-restricted-globals
    return qs.parse(location.search);
  };

  const onSubmit = (searchValue: string) => {
    const queryParams = existingQuery();
    if ('page' in queryParams) {
      delete queryParams.page;
    }
    const newQueries = { ...queryParams, searchBy: searchValue };
    history.push({ search: qs.stringify(newQueries) });
  };

  const openPopup = () => {
    ref.current.open();
  };

  const onClose = () => {
    setItemToEdit(null);
  };

  const onPopupOpen = () => {};

  useEffect(() => {
    const queryParams = existingQuery();
    if ('searchBy' in queryParams) {
      setSearchBy(queryParams.searchBy as string);
    }
  }, []);

  return (
    <div className="business pb-0">
      <div className="d-flex justify-content-between align-center">
        <h3>Manage Users</h3>
        <div className="flex align-center">
          <div className="mr-1">
            <SearchInput
              placeholder="Search users"
              inputValue={searchBy}
              onSubmit={onSubmit}
            />
          </div>
          <button
            onClick={openPopup}
            className="btn btn--main pr-1 pl-1 business-btn ml-1">
            <span>Add Team Member</span>
            <img src={AddIcon} alt="icon" className="icon-image" />
          </button>
        </div>
      </div>
      <UsersSystem onEditUser={onEditUser} />
      <Popup
        closeOnDocumentClick={false}
        onOpen={onPopupOpen}
        onClose={onClose}
        ref={ref}
        className="business"
        modal
        nested>
        {(close: any) => (
          <div className="modal business-modal">
            <img
              onClick={close}
              src={CrossIcon}
              alt="close"
              className="cross-icon"
            />
            <SnackbarProvider>
              <AddSystemWideUser
                onClose={close}
                itemToEdit={itemToEdit}
                onSuccess={() => {}}
              />
            </SnackbarProvider>
          </div>
        )}
      </Popup>
    </div>
  );
};

export default ManageUsers;
