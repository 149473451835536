import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import get from 'lodash/get';
import Auth from '../tools/Auth';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI });

const authLink = setContext(async (_, { headers }) => {
  const token = await Auth.getIdToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ operation, graphQLErrors }) => {
  if (
    operation.operationName !== 'getCustomToken' &&
    get(graphQLErrors, '0.extensions.0.errors', []).includes('unauthorized')
  ) {
    // console.error(graphQLErrors);
  }
});

const client = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
