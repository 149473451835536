// @ts-ignore
import { ReactComponent as LogoIcon } from '../../assets/img/logo-dark.svg';
// @ts-ignore
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import LoginScreen from '../../assets/img/login-screen-image.png';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Auth from '../../tools/Auth';
// @ts-ignore
import { useSnackbar } from 'react-simple-snackbar';
// @ts-ignore
import { default as EyeVisible } from '../../assets/img/eye-visible.svg';
// @ts-ignore
import { default as EyeInvisible } from '../../assets/img/eye-invisible.svg';

const Login: React.FC = () => {
  const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(4, 'Password should be at least 4 characters')
      .max(120, 'Password should not exceed 120 characters'),
  });

  const options = {
    style: {
      textAlign: 'center',
    },
  };

  const [openSnackbar] = useSnackbar(options);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const history = useHistory();

  const redirect = (type: string) => {
    switch (type) {
      case 'forgot':
        history.push('/forgot-password');
        break;
      case 'create':
        history.push('/register');
        break;
      default:
        return false;
    }
  };

  return (
    <div className="wrapper">
      <div className="auth-wrap">
        <div className="left-part">
          <div className="logo-wrap">
            <LogoIcon />
          </div>
          <div className="form-wrap">
            <div className="form-wrap--child">
              <h3 className="head">Welcome to Seekr!</h3>
              <p className="subheadline">Login or create your account</p>
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                  remember: false,
                }}
                validationSchema={SignupSchema}
                onSubmit={async (values) => {
                  const { email, password, remember } = values;
                  setLoading(true);
                  try {
                    await Auth.signInWithEmailAndPassword(
                      email,
                      password,
                      remember,
                    );
                  } catch (e) {
                    console.error(e);
                    openSnackbar('Wrong email or password');
                  }
                  setLoading(false);
                }}>
                {({
                  errors,
                  touched,
                  handleSubmit,
                  setValues,
                  isSubmitting,
                  dirty,
                  isValid,
                }) => (
                  <Form>
                    <div className="input-wrap">
                      <label>EMAIL*</label>
                      <Field
                        name="email"
                        type="email"
                        placeholder="Email"
                        className="input"
                      />
                      {errors.email && touched.email ? (
                        <div className="error">{errors.email}</div>
                      ) : null}
                    </div>
                    <div className="input-wrap">
                      <label>PASSWORD*</label>
                      <Field
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        className="input"
                      />
                      {showPassword ? (
                        <img
                          onClick={() => setShowPassword(false)}
                          src={EyeVisible}
                          alt="EyeVisible"
                          className="input-wrap-img"
                        />
                      ) : (
                        <img
                          onClick={() => setShowPassword(true)}
                          src={EyeInvisible}
                          alt="EyeInvisible"
                          className="input-wrap-img"
                        />
                      )}
                      {errors.password && touched.password ? (
                        <div className="error">{errors.password}</div>
                      ) : null}
                    </div>
                    <div className="bottom-wrap register mb-3">
                      <Field
                        className="styled-checkbox"
                        id="styled-checkbox-1"
                        type="checkbox"
                        name="remember"
                      />
                      <label htmlFor="styled-checkbox-1">Remember me</label>
                    </div>
                    <div>
                      <button
                        className="btn btn--main w-100"
                        disabled={!(dirty && isValid) || loading}>
                        {loading ? 'Loading' : 'Login'}
                      </button>
                    </div>
                    <div className="bottom-hint">
                      <p className="bottom-hint--left">
                        You don't have an account?
                      </p>
                      <p
                        className="bottom-hint--right"
                        onClick={() => redirect('create')}>
                        Create an account
                      </p>
                    </div>
                    <div className="bottom-hint">
                      <p
                        className="bottom-hint--right mt-0"
                        onClick={() => redirect('forgot')}>
                        Forgot password
                      </p>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div
          className="image-wrap"
          style={{
            backgroundImage: 'url(' + LoginScreen + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}></div>
      </div>
    </div>
  );
};

export default observer(Login);
