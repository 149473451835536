const PrivacyPolicy = () => {
  return (
    <div className="conditions">
      <h5>Seekr Privacy Policy</h5>
      <div className="conditions__content">
        <div className="mb-5">
          <p>Please read this policy carefully before using Seekr.</p>
          <p>
            Your privacy is important to us and maintaining your trust is our
            priority. Madruga, LLC. ("Seekr," "we," "our," or "us"), respects
            your privacy and is committed to protecting it through our
            compliance with this Privacy Policy
          </p>
          <p>
            Please read our privacy policy carefully to get a clear
            understanding of how we collect, use, protect or otherwise handle
            your personal data in accordance with our Site.
          </p>
          <p>
            This policy (together with our Terms of Use), sets out the basis on
            which any personal data we collect from you, or that you provide to
            us, will be processed by us. This privacy policy covers the
            information we collect about you through any web pages under the
            www.SEEKR.com domain and the Seekr mobile apps (collectively, the
            "Site"), and through all products, services, communications, and
            offerings provided (the "Services").
          </p>
          <p>
            When visiting the Seekr website or using our mobile application
            “Seekr” (our “App”) or using any of the services offered via the
            Website or the App (the “Services”), you acknowledge and consent to
            the practices described in this policy.
          </p>
        </div>
        <div className="mb-5">
          <p>1. Data we may collect about you</p>
          <p>
            As permitted by applicable law in the country from which you access
            our Website and use our Services, we may collect, use, store and
            transfer different kinds of Personal Data about you. We have grouped
            these into the following categories of{' '}
          </p>
          <p>
            When accessing Seekr Services through the mobile App or Website, we
            may automatically collect the following information:
          </p>
          <p>
            We use tracking tools like cookies and pixels to automatically
            collect information about how you interact with our Site, online
            content, and emails.
          </p>
          <p>
            Technical information such as IP address, computer operating system,
            browser type, opt-in sharing of location information, system
            analytics and crash reports.
          </p>
          <p>
            Device specific data, such as hardware model, OS version, unique
            device identifiers, Universal Device Identifier (UDID), ID for
            Advertisers (IDFA) and mobile network information.
          </p>
          <p>
            In order to identify and provide you with the most relevant content
            and connections, we may collect personal information pertaining to
            the following categories of information:
          </p>
          <p>Types of Information Collected:</p>
          <p>
            Account Registration Information: such as full name, username, text
            verification code, phone, email, password, city, state, zip code,
            GPS coordinates.
          </p>
          <p>
            If you sign up through a third-party login such as Google, Facebook,
            Apple, we may have access to login information provided by that
            third-party service.
          </p>
          <p>Purpose of Use:</p>
          <p>• To provide you with the Services.</p>
          <p>• Secure our services and Users.</p>
          <p>
            Profile information: such as candidate discovery and filtering, job
            discovery and filtering searches, job applications, employment
            history, education, pictures, video, sound, employment availability
            and preferences, ratings, reviews, languages, hobbies, feedback and
            survey responses, orders made by you, preferences, and
            user-generated content.
          </p>
          <p>Purpose of Use:</p>
          <p>
            • To provide you with the Services and facilitate application to job
            opportunities.
          </p>
          <p>
            • We may also provide your profile information to facilitate
            applications to external jobs outside of Seekr’s network.
          </p>
          <p>
            Company information: such as company name, website, logo, pictures,
            video, company description, job content and description.
          </p>
          <p>Purpose of Use:</p>
          <p>
            • To provide you with the Services and to publish job opportunities
            and business profiles, connect with applicants and opportunities,
            and browse prospective job seeker and business profiles.
          </p>
          <p>
            Customer service information: such as questions you submit to us
            through online forms, email, in-app contact us, phone.
          </p>
          <p>Purpose of Use:</p>
          <p>
            • To facilitate communications and provide customer service to you.
            Social media information: such as pictures, video, audio, opinions,
            preferences, interests, likes, dislikes, views, life history,
            current events, friends, connections, associations, intentions, and
            other information you share with our Services.
          </p>
          <p>Purpose of Use:</p>
          <p>
            • To provide better matching as well as the ability to link social
            media content to our Services.
          </p>
          <p>
            Financial Data: such as payment card details. However, please note
            that payment methods (e.g., credit card number and/or PayPal and/or
            bank account details) are not collected by us directly, but by
            secure payment service providers.
          </p>
          <p>Purpose of Use:</p>
          <p>
            • To enable payment of Services provided by Seekr. Transaction Data:
            such as details about payments to and from you, and details
            regarding the services that you have subscribed to or purchased
            through a Seekr Website.
          </p>
          <p>Purpose of Use:</p>
          <p>
            • To enable payment of Services provided by Seekr, as well as a
            record of payment. Technical Data: such as Device IDs, your login
            data, access dates and times, browser type and version, device
            information, cookie data, time zone setting and location, browser
            plug-in types and versions, operating system and platform and other
            technology on the devices you use to access the Website.
          </p>
          <p>Purpose of Use:</p>
          <p>• To provide you with the Services.</p>
          <p>• Secure our services and Users.</p>
          <p>
            Usage Data: such as information about how you use our Website,
            products and services.
          </p>
          <p>Purpose of Use:</p>
          <p>• To provide you with the Services.</p>
          <p>• Secure our services and Users.</p>
          <p>
            Marketing and Communications Data: such as your preferences in
            receiving marketing from us and our third-parties and your
            communication preferences.
          </p>
          <p>Purpose of Use:</p>
          <p>
            • To facilitate communications and provide customer service to you.
          </p>
          <p>
            Non-Personal Data: We also collect, use and share Aggregated Data
            such as statistical or demographic data for any purpose. Aggregated
            Data may be derived from your Personal Data but is not considered
            Personal Data since it does not directly or indirectly reveal your
            identity. For example, we may aggregate your Usage Data to calculate
            the percentage of users accessing a specific website feature.
          </p>
          <p>Purpose of Use:</p>
          <p>
            • To provide our Members with relevant anonymized; industry specific
            data such as aggregated salary information, localized economic data
            such as employment trends, and any other anonymized data that we
            believe may be value added to our Members.{' '}
          </p>
          <p>
            • Internal use to improve matching, Member experiences, and internal
            optimization.
          </p>
        </div>
        <div className="mb-5">
          <p>2. When do we collect information?</p>
          <p>
            We collect information from you when you register on our Site or
            mobile App, create a job seeker or company profile, apply to a job,
            publish a job posting, subscribe to a newsletter, fill out a form or
            enter information on our Site.
          </p>
          <p>
            When using our Services, we may also contact third parties in order
            to enable your use of the Services e.g., information provided by
            service providers such as Facebook, Google, Apple, Twilio, and other
            services and APIs.
          </p>
        </div>
        <div className="mb-5">
          <p>3. How do we use your information?</p>
          <p>
            We may use the information we collect from you when you register,
            sign up for our newsletter, email communications, login to the Site
            or App, or use certain other site features in ways such as the
            following:
          </p>
          <p>
            • Provide, maintain, and improve our Services, develop new features,
            send product updates, and provide customer support to you.
          </p>
          <p>
            • Perform internal operations such as to prevent fraud and abuse of
            our Services; to troubleshoot software bugs and conduct data
            analysis, testing, research, and to monitor and analyze usage and
            activity trends.
          </p>
          <p>
            • Send you communications that might be of interest to you such as
            job alert, candidate alerts, promotions, and updates.
          </p>
          <p>
            • Personalize and improve the Services and content we provide to
            you.
          </p>
          <p>
            • Measure the performance of job postings both on and off of our
            Site through ad networks and exchanges.
          </p>
          <p>
            • Ask for ratings, reviews, and feedback of services or products.
          </p>
          <p>
            • Protect the security of the Services and our users, and prevent,
            detect, mitigate fraud, security breaches, and potentially
            prohibited or illegal activities.
          </p>
        </div>
        <div className="mb-5">
          <p>4. How is information shared</p>
          <p>
            We may share your personal information with other Members on our
            Services, for example:
          </p>
          <p>
            • With employers and other businesses in our network so that they
            can search for your profile and contact you with information about
            jobs and job-related services in which you have expressed interest.
          </p>
          <p>
            • With third parties to provide you a service you requested through
            a partnership or promotional offering made by a third party or us.
            By including links to a third-party website and apps, we increase
            the value provide to you. Seekr does not endorse or recommend any
            products or services offered or information contained at the
            third-party website. These linked sites and apps have separate and
            independent privacy policies. Therefore, we have no responsibility
            or liability for the content and activities of these linked sites
            and apps
          </p>
          <p>
            • With third parties with whom you choose to let us share
            information, for example other apps or websites that integrate with
            our API or Services, or those with an API or Service with which we
            integrate.
          </p>
          <p>
            • With the general public if you submit content in a public forum,
            such as blog comments, social media posts, or other features of our
            Services that are viewable by the general public.
          </p>
          <p>
            • Where permitted by applicable law, we may share your Non-Personal
            Data for any purpose not prohibited by applicable law or
            inconsistent with this Privacy Policy or any written representation
            we have made to you at the point and time of the data collection.
          </p>
        </div>
        <div className="mb-5">
          <p>5. How do we protect your information?</p>
          <p>
            Sensitive information between your browser and our Website and App
            is transferred in encrypted form using secure socket layer (“SSL”)
            or equivalent cryptographic protocols using certificates issued by a
            trusted third-party authority.
          </p>
          <p>
            However, no data transmission over the internet can be guaranteed to
            be secure from intrusion. Nonetheless, we maintain commercially
            reasonable physical, electronic and procedural safeguards to protect
            your personal information in accordance with data protection
            legislative requirement.
          </p>
        </div>
        <div className="mb-5">
          <p>6. Cookies and Tracking technology</p>
          <p>
            Seekr and third parties, such as background check verification
            services, may use cookies or similar tracking technologies (e.g.,
            pixels, beacons, scripts, etc.) (“Tracking Technologies”) to
            distinguish you from other users, analyze trends, administer the
            Website, track users’ movements around the Website, to gather
            demographic information about our user base as a whole, and to help
            us provide you with a good experience when you use our Website or
            App. Cookies and Tracking Technologies allows us to improve our
            Services and offer you a more tailored experience for future visits.
          </p>
          <p>
            You can control the use of cookies at the individual browser level,
            but if you choose to disable cookies, it may limit your use of
            certain features or functions on our website or service. For more
            information about the types of cookies and Tracking Technologies
            that we, and certain third parties use, and why we use them, and how
            you can control these, please review our Cookie Policy.
          </p>
          <p>
            If you are accessing the Website from a location outside the EEA or
            U.K., you understand that we apply U.S. law and the U.S. advertising
            industry self-regulatory program to Tracking Technologies associated
            with the Website.
          </p>
          <p>
            We may partner with a third party to either display advertising on
            our websites or to manage our advertising on other sites. Our third
            party partner may use cookies or similar technologies in order to
            provide you advertising based upon your browsing activities and
            interests. If you wish to opt out of any interest-based advertising
            click here. Please note that you will continue to receive generic
            ads.
          </p>
          <p>
            We use mobile analytics software to allow us to better understand
            the functionality of our Mobile Software on your phone. This
            software may record information such as how often you use the
            application, the events that occur within the application,
            aggregated usage, performance data and the website from which the
            application was downloaded.
          </p>
          <p>
            To the extent permitted by applicable law, we may send you push
            notifications from time-to-time in order to update you about any
            events or promotions that we may be running. If you no longer wish
            to receive these types of communications, you may turn them off at
            the device level. To ensure you receive proper notifications, we
            will need to collect certain information about your device such as
            operating system and user identification information.
          </p>
        </div>
        <div className="mb-5">
          <p>7. How Long will your data be kept?</p>
          <p>
            Different retention periods apply for different types of Personal
            Data and in determining the appropriate retention period, we
            consider several criteria, including, but not limited to, the
            purpose for which we hold your Personal Data, our legal, accounting,
            reporting and/or regulatory obligations in relation to that Personal
            Data, (e.g., financial or tax reporting obligations), whether our
            relationship with you is ongoing (e.g., where you use more than one
            of our products and/or services), any specific request you may have
            made in relation to the deletion of your Personal Data, or where we
            have a legitimate interest in relation to the management of our own
            rights (e.g., the defense of any claims). In some circumstances we
            may anonymize your Personal Data so that it can no longer be
            associated with you, in which case we may use such information
            without further notice to you.
          </p>
          <p>
            Once you no longer have a relationship with us, we will retain or
            securely delete or destroy your Personal Data subject to the
            foregoing and in accordance with applicable laws and regulations. If
            you request to receive no further contact from us, we will keep some
            basic information about you on our suppression list in order to
            avoid sending you unwanted materials in the future.
          </p>
        </div>
        <div className="mb-5">
          <p>8. Your Choices and Rights</p>
          <p>
            You have certain rights with respect to your information as further
            described in this Section, in addition to any rights discussed
            elsewhere in this Privacy Policy.
          </p>
          <p>
            You may edit your account information anytime inside the App. If you
            would like to delete your account, you may do so in your Profile
            inside the App, or email us at @Seekr.com. Please note that in some
            cases we may retain certain information about you as required by
            law, or for legitimate business purposes to the extent permitted by
            law. For instance, if we believe you have committed fraud or
            violated our Terms, we may seek to resolve the issue before deleting
            your information.
          </p>
        </div>
        <div className="mb-5">
          <p>9. Marketing</p>
          <p>
            We may use your Personal Data to send you information about the
            services provided by Seekr; provided, however that we will obtain
            consent to do so where required by applicable law. You may
            unsubscribe from our marketing e-mails by clicking "Unsubscribe" in
            any promotional e-mail communications we send you. As permitted by
            applicable law, you cannot opt-out of non-marketing,
            services-related notices.
          </p>
        </div>
        <div className="mb-5">
          <p>10. California Privacy Rights</p>
          <p>
            California Civil Code Section 1798.83, permits California residents
            to request and obtain from businesses a list of what personal
            information (if any) was disclosed to third parties for direct
            marketing purposes in the preceding calendar year and the names and
            addresses of those third parties. Under Section 1798.83, we
            currently do not share your personal information with third parties
            for their direct marketing purposes.
          </p>
          <p>
            If you reside in California, you also have the right to make the
            following more specific requests with respect to your personal
            information:
          </p>
          <p>
            • Inform you about the categories of personal information we collect
            or disclose about you; the categories of sources of such
            information; the business or commercial purpose for collecting your
            personal information; and the categories of third parties with whom
            we share/disclose personal information.
          </p>
          <p>
            • Provide access to and/or a copy of certain personal information we
            hold about you.
          </p>
          <p>• Delete certain personal information we have about you.</p>
          <p>
            • Provide you with information about the financial incentives that
            we offer to you, if any. Seekr will not discriminate against you for
            exercising any of the above rights.
          </p>
          <p>
            You can exercise these rights by contacting us at{' '}
            <a href="mailto:info@Seekr.com">info@Seekr.com</a>.
          </p>
          <p>
            We will take reasonable steps to fulfill your request but may also
            deny certain requests, or fulfill a request only in part, based on
            our legal rights and obligations. For example, we may retain
            personal information as permitted by law, such as for record keeping
            purposes, to maintain an active account, and to process transactions
            and facilitate customer requests
          </p>
          <p>
            For purposes of California residents making these CCPA requests,
            please also note the following regarding how we collect, use, and
            share your personal information as described in this Policy above.
          </p>
          <p>Cookies and Advertising</p>
          <p>
            Please refer to our Cookie statement above (6. Do we use Cookies)
            for more information about your choices around cookies and related
            technologies.
          </p>
          <p>
            This website does not respond to browser “Do Not Track” signals.
          </p>
          <p>COPPA (Children Online Privacy Protection Act)</p>
          <p>
            When it comes to the collection of personal information from
            children under the age of 13 years old, the Children's Online
            Privacy Protection Act (COPPA) puts parents in control. The Federal
            Trade Commission, United States' consumer protection agency,
            enforces the COPPA Rule, which spells out what operators of websites
            and online services must do to protect children's privacy and safety
            online.
          </p>
          <p>
            Do we let third-parties, including ad networks or plug-ins collect
            PII from children under 13? We do not specifically market to
            children under the age of 13 years old.
          </p>
        </div>
        <div className="mb-5">
          <p>11. Changes to Policy</p>
          <p>
            We reserve the right to modify this Policy at any time, so please
            review this Policy frequently for any changes. Amendments to this
            Policy will be posted on the Sites or otherwise noticed to you in
            accordance with applicable laws and will be effective when posted.
            If you continue to use the Services or the Site, then you have
            acknowledged that you have read and understood this revised Privacy
            Policy and the fact that its terms may have changed since your last
            visit.
          </p>
          <p>Policy was last updated on the date indicated above.</p>
          <p>By using our Site or App, you consent to our privacy policy.</p>
        </div>
        <div>
          <p>12. Contact us</p>
          <p>
            If you have any questions regarding this privacy policy, please
            contact us at <a href="mailto:info@Seekr.com">info@Seekr.com</a> or
            at the follow address:
          </p>
          <p className="m-0">Madruga, LLC</p>
          <p className="m-0">10 Aragon ave PH1510</p>
          <p className="m-0">Coral Gables, Fl, 33134</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
