import axiosFactory from 'axios';
import Auth from '../tools/Auth';

export const axios = axiosFactory.create({
  baseURL: process.env.REACT_APP_MAIN_API,
});

axios.interceptors.request.use(setHeader);
axios.interceptors.request.use(setAuthHeader);

function setHeader(config) {
  const createPost = '/job-post/admin-job-posts/business/';
  const updatePost = '/job-post/admin-job-posts/business/';
  if (
    (config.method === 'post' && config.url.includes(createPost)) ||
    (config.method === 'put' && config.url.includes(updatePost))
  ) {
    config.headers['Content-Type'] = 'multipart/form-data';
    return config;
  }
  config.headers['Content-Type'] = 'application/json';
  return config;
}

async function setAuthHeader(config) {
  const token = await Auth.getIdToken();
  config.headers['Authorization'] = `Bearer ${token}`;

  return config;
}
