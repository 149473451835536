export const transformFieldsToString = (opts: string[], { ...data }: any) => {
  opts.forEach((el) => {
    // @ts-ignore
    data[el] = data[el].toString();
  });

  return data;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
