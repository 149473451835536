const TermsAndConditions = () => {
  return (
    <div className="conditions">
      <h5>Seekr Terms of Service</h5>
      <div className="conditions__content">
        <div className="mb-5">
          <p>1. AGREEMENT TO TERMS</p>
          <p>
            These Terms of Service are legally binding agreements made between
            you, whether personally or on behalf of an entity (“you”) and
            Madruga, LLC, doing business as “Seekr”, and applies to your
            interactions with Seekr, through the following ways, which are
            collectively referred to as the “Services”: On Seekr websites,
            through Seekr mobile applications, chat, email, SMS, or any other
            online communications between you and Seekr. You agree that by
            accessing the Services, you have read, understood, and agreed to be
            bound by all of these Terms of Service. If you disagree with any
            part of the terms, then you do not have permission to access our
            Services.
          </p>
          <p>
            Seekr reserves the right, in its sole discretion, to make changes or
            modifications to our Terms of Service at any time and for any
            reason. This includes any and all supplemental terms and conditions
            or documents that may be posted on the Services. By your continued
            use of our Services you agree to be bound by these Terms or Service
            and will be deemed to have been made aware and to have accepted any
            and all changes, additions, or deletions to our Terms of Service.
            Seekr will alert you about any changes by updating the “Last
            updated” date of these Terms of Service. You waive any and all
            rights to receive specific notices of each such changes. It is your
            responsibility to periodically review the Terms of Service to stay
            informed of updates.{' '}
          </p>
          <p>
            The information provided within the Services is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject Seekr to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Services from other locations do so
            on their own initiative and are solely responsible for compliance
            with local laws, if and to the extent local laws are applicable.
          </p>
          <p>
            In order for you to form a binding contract with Seekr, you must be
            at least eighteen (18) years of age or the age of majority in the
            jurisdiction in which you reside. If you are under the age of
            eighteen (18) or the age of majority, and you are permitted to work
            in the jurisdiction in which you reside, you represent that a parent
            or legal guardian has reviewed and agrees to this Agreement on your
            behalf.
          </p>
        </div>
        <div className="mb-5">
          <p>2. USE OF SERVICES</p>
          <p>
            By using our Services in the United States, you acknowledge that we
            do not have control over the accuracy, quality, truthfulness,
            completeness, or legality of content provided by third parties. In
            addition, you acknowledge that we do not have control over the
            integrity, responsibility or actions of businesses who are our
            clients (Members), or Job Seekers (Seekers).
          </p>
        </div>
        <div className="mb-5">
          <p>3. USER REPRESENTATIONS</p>
          <p>
            By using our Services, you represent and warrant that: (1) all
            registration information you submit is true, accurate, current, and
            complete; (2) you agree to maintain the accuracy of such information
            and promptly update such registration information as necessary; (3)
            you have the legal capacity and you agree to comply with these Terms
            of Service; (4) you are not under the age of 13; (5) you are not a
            minor in the jurisdiction in which you reside, or if a minor, you
            have received parental permission to use the Service; (6) you will
            not access the Service through automated or non-human means, whether
            through a bot, script, or otherwise; (7) you will not use the
            Service for any illegal or unauthorized purpose; and (8) your use of
            the Service will not violate any applicable law or regulation.
          </p>
          <p>
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Services (or any portion thereof). If your use of the Services has
            been previously terminated or suspended by Seekr, you may not use
            the Services, unless we have provided you with specific prior
            written authorization to re-use the Services.
          </p>
        </div>
        <div className="mb-5">
          <p>4. USER REGISTRATION</p>
          <p>
            You may be required to register with the Services. You agree to keep
            your password confidential and will be responsible for all use of
            your account and password. We reserve the right to remove, reclaim,
            or change a username you select if we determine, in our sole
            discretion, that such username is inappropriate, obscene, or
            otherwise objectionable.
          </p>
        </div>
        <div className="mb-5">
          <p>5. PROHIBITED ACTIVITIES</p>
          <p>
            You may not access or use the Services for any purpose other than
            that for which we make the Services available. The Services may not
            be used in connection with any commercial endeavors except those
            that are specifically endorsed or approved by us.
          </p>
          <p>As a user of the Services, you agree not to:</p>
          <p>
            1. Systematically retrieve data or other content from the Services
            to create or compile, directly or indirectly, a collection,
            compilation, database, or directory without written permission from
            us.
          </p>
          <p>
            2. Make any unauthorized use of the Services, including collecting
            usernames and/or email addresses of users by electronic or any other
            means for the purpose of sending unsolicited email, or creating user
            accounts by automated means or under false pretenses.
          </p>
          <p>
            3. Use the Services to advertise or offer to sell goods and
            services.
          </p>
          <p>
            4. Use a buying agent or purchasing agent to make purchases on the
            Services.
          </p>
          <p>
            5. Circumvent, disable, or otherwise interfere with security-related
            features of the Services, including features that prevent or
            restrict the use or copying of any Content or enforce limitations on
            the use of the Service and/or the Content contained therein.
          </p>
          <p>6. Engage in unauthorized framing of or linking to the Service.</p>
          <p>
            7. Trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords.
          </p>
          <p>
            8. Make improper use of our support services or submit false reports
            of abuse or misconduct.
          </p>
          <p>
            9. Engage in any automated use of the system, such as using scripts
            to send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools.
          </p>
          <p>
            10. Interfere with, disrupt, or create an undue burden on the
            Services or the networks or services connected to the Services.
          </p>
          <p>
            11. Attempt to impersonate another user or person or use the
            username of another user.
          </p>
          <p>12. Sell or otherwise transfer your profile.</p>
          <p>
            13. Use any information obtained from the Services in order to
            harass, abuse, or harm another person.
          </p>
          <p>
            14. Use the Services as part of any effort to compete with us or
            otherwise use the Services and/or the Content for any
            revenue-generating endeavor or commercial enterprise.
          </p>
          <p>
            15. Decipher, decompile, disassemble, or reverse engineer any of the
            software comprising or in any way making up a part of the Services.
          </p>
          <p>
            16. Attempt to bypass any measures of the Services designed to
            prevent or restrict access to the Services, or any portion of the
            Services.
          </p>
          <p>
            17. Harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any portion of the Services to you.
          </p>
          <p>
            18. Delete the copyright or other proprietary rights notice from any
            Content.
          </p>
          <p>
            19. Copy or adapt the Services’ software, including but not limited
            to Flash, PHP, HTML, JavaScript, or other code.
          </p>
          <p>
            20. Upload or transmit (or attempt to upload or to transmit)
            viruses, Trojan horses, or other material, including excessive use
            of capital letters and spamming (continuous posting of repetitive
            text), that interferes with any party’s uninterrupted use and
            enjoyment of the Services or modifies, impairs, disrupts, alters, or
            interferes with the use, features, functions, operation, or
            maintenance of the Services.
          </p>
          <p>
            21. Upload or transmit (or attempt to upload or to transmit) any
            material that acts as a passive or active information collection or
            transmission mechanism, including without limitation, clear graphics
            interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or
            other similar devices (sometimes referred to as “spyware” or
            “passive collection mechanisms” or “pcms”).
          </p>
          <p>
            22. Except as may be the result of standard search engine or
            Internet browser usage, use, launch, develop, or distribute any
            automated system, including without limitation, any spider, robot,
            cheat utility, scraper, or offline reader that accesses the Service,
            or using or launching any unauthorized script or other software.
          </p>
          <p>
            23. Disparage, tarnish, or otherwise harm, in our opinion, us and/or
            the Services.
          </p>
          <p>
            24. Use the Services in a manner inconsistent with any applicable
            laws or regulations.
          </p>
          <p>
            25. Providing any content that violates any laws, infringes the
            rights (including the intellectual property rights) of others, is
            defamatory or otherwise creates liability for the Services.
          </p>
        </div>
        <div className="mb-5">
          <p>6. INTELLECTUAL PROPERTY RIGHTS</p>
          <p>
            Unless otherwise indicated, the Services we offer are our
            proprietary property and all source code, databases, functionality,
            software, website designs, app designs, audio, video, text,
            photographs, and graphics on the Services (collectively, called
            “Content”) and all trademarks, service marks, and logos contained
            therein (called “Marks”) are owned or controlled by us or licensed
            to us, and are protected by copyright and trademark laws and various
            other intellectual property rights and unfair competition laws of
            the United States, foreign jurisdictions, and international
            conventions. The Content and the Marks are provided on our Services
            “AS IS” for your information and personal use only. Except as
            expressly provided in these Terms of Service, no part of the
            Services and no Content or Marks may be copied, reproduced,
            aggregated, republished, uploaded, posted, publicly displayed,
            encoded, translated, transmitted, distributed, sold, licensed, or
            otherwise exploited for any commercial purpose whatsoever, without
            our express prior written permission. We reserve all rights not
            expressly granted to you in and to the Service, the Content and the
            Marks.
          </p>
        </div>
        <div className="mb-5">
          <p>7. COPYRIGHT POLICY</p>
          <p>
            We respect the intellectual property rights of others. It is our
            policy to respond to any claim that Content posted on the Services
            infringes on the copyright or other intellectual property rights
            ("Infringement") of any person or entity.
          </p>
          <p>
            If you are a copyright owner, or authorized on behalf of one, and
            you believe that the copyrighted work has been copied in a way that
            constitutes copyright infringement, please submit your claim via
            email to info@Seekr.com, with the subject line: "Copyright
            Infringement" and include in your claim a detailed description of
            the alleged Infringement as detailed below, under Article 17: DMCA
            Notice and Procedures for Copyright Infringement Claims.
          </p>
          <p>
            At our discretion, you may be held accountable for damages
            (including costs and attorneys' fees) for misrepresentation or bad
            faith claims on the infringement of any Content found on and/or
            through the Services on your copyright.
          </p>
        </div>
        <div className="mb-5">
          <p>8. DMCA Notice and Procedures for Copyright Infringement Claims</p>
          <p>
            You may submit a notification pursuant to the Digital Millennium
            Copyright Act (DMCA) by providing our Copyright Agent with the
            following information in writing (see 17 U.S.C 512(c)(3) for further
            detail): an electronic or physical signature of the person
            authorized to act on behalf of the owner of the copyright's
            interest; a description of the copyrighted work that you claim has
            been infringed, including the URL (i.e., web page address) of the
            location where the copyrighted work exists or a copy of the
            copyrighted work; identification of the URL or other specific
            location on the Services where the material that you claim is
            infringing is located; your address, telephone number, and email
            address; a statement by you that you have a good faith belief that
            the disputed use is not authorized by the copyright owner, its
            agent, or the law; a statement by you, made under penalty of
            perjury, that the above information in your notice is accurate and
            that you are the copyright owner or authorized to act on the
            copyright owner's behalf.
          </p>
          <p>
            You can contact our Copyright Agent via email at{' '}
            <a href="mailto:info@Seekr.com">info@Seekr.com</a>, or by mail at
            the address listed at the bottom of this Terms of Service agreement.
          </p>
        </div>
        <div className="mb-5">
          <p>9. USER GENERATED CONTENT</p>
          <p>
            The Services may invite you to chat, contribute to, or participate
            in blogs, message boards, online forums, and other functionality,
            and may provide you with the opportunity to create, submit, post,
            display, transmit, perform, publish, distribute, or broadcast
            content and materials to us or on the Services, including but not
            limited to text, writings, video, audio, photographs, graphics,
            comments, suggestions, or personal information or other material
            (collectively, "User Content").{' '}
          </p>
          <p>
            User Content may be viewable by other users of the Services and
            through third-party websites. As such, any User Content you transmit
            may be treated as non-confidential and non-proprietary. When you
            create or make available any User Content, you thereby represent and
            warrant that:
          </p>
          <p>
            1. The creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your User
            Content does not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party.
          </p>
          <p>
            2. You are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize
            us, the Services, and other users of the Services to use your User
            Content in any manner contemplated by the Services and these Terms
            of Service.
          </p>
          <p>
            3. You have the written consent, release, and/or permission of each
            and every identifiable individual person in your User Content to use
            the name or likeness of each and every such identifiable individual
            person to enable inclusion and use of your User Content in any
            manner contemplated by the Services and these Terms of Service.
          </p>
          <p>4. Your User Content is not false, inaccurate, or misleading.</p>
          <p>
            5. Your User Content is not unsolicited or unauthorized advertising,
            promotional materials, pyramid schemes, chain letters, spam, mass
            mailings, or other forms of solicitation.
          </p>
          <p>
            6. Your User Content is not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us).
          </p>
          <p>
            7. Your User Content does not ridicule, mock, disparage, intimidate,
            or abuse anyone.
          </p>
          <p>
            8. Your User Content does not advocate the violent overthrow of any
            government or incite, encourage, or threaten physical harm against
            another.
          </p>
          <p>
            9. Your User Content does not violate any applicable law,
            regulation, or rule.
          </p>
          <p>
            10. Your User Content does not violate the privacy or publicity
            rights of any third party.
          </p>
          <p>
            11. Your User Content does not contain any material that solicits
            personal information from anyone under the age of 18 or exploits
            people under the age of 18 in a sexual or violent manner.
          </p>
          <p>
            12. Your User Content does not violate any federal or state law
            concerning child pornography, or otherwise intended to protect the
            health or well-being of minors;
          </p>
          <p>
            13. Your User Content does not include any offensive comments that
            are connected to race, national origin, gender, sexual preference,
            or physical handicap.
          </p>
          <p>
            14. Your User Content does not otherwise violate, or link to
            material that violates, any provision of these Terms of Service, or
            any applicable law or regulation. Any use of the Services in
            violation of the foregoing violates these Terms of Service and may
            result in, among other things, termination or suspension of your
            rights to use the Services.
          </p>
        </div>
        <div className="mb-5">
          <p>10. CONTRIBUTION LICENSE</p>
          <p>
            By posting your User Content to any part of the Services or making
            User Content accessible to the Services by linking your account from
            the Services to any of your social networking accounts, you
            automatically grant, and you represent and warrant that you have the
            right to grant, to us an unrestricted, unlimited, irrevocable,
            perpetual, non-exclusive, transferable, royalty-free, fully-paid,
            worldwide right, and license to host, use, copy, reproduce,
            disclose, sell, resell, publish, broadcast, retitle, archive, store,
            cache, publicly perform, publicly display, reformat, translate,
            transmit, excerpt (in whole or in part), and distribute such User
            Content (including, without limitation, your image and voice) for
            any purpose, commercial, advertising, or otherwise, and to prepare
            derivative works of, or incorporate into other works, such User
            Content, and grant and authorize sublicenses of the foregoing. The
            use and distribution may occur in any media formats and through any
            media channels.
          </p>
          <p>
            This license will apply to any form, media, or technology now known
            or hereafter developed, and includes our use of your name, company
            name, and franchise name, as applicable, and any of the trademarks,
            service marks, trade names, logos, and personal and commercial
            images you provide. You waive all moral rights in your User Content,
            and you warrant that moral rights have not otherwise been asserted
            in your User Content.
          </p>
          <p>
            We do not assert any ownership over your User Content. You retain
            full ownership of all of your User Content and any intellectual
            property rights or other proprietary rights associated with your
            User Content. We are not liable for any statements or
            representations in your User Content provided by you in any area on
            the Services. You are solely responsible for your User Content to
            the Services and you expressly agree to exonerate us from any and
            all responsibility and to refrain from any legal action against us
            regarding your User Content.
          </p>
          <p>
            We have the right, in our sole and absolute discretion, (1) to edit,
            redact, or otherwise change any User Content; (2) to re-categorize
            any User Content to place them in more appropriate locations on the
            Services; and (3) to pre-screen or delete any User Content at any
            time and for any reason, without notice. We have no obligation to
            monitor your User Content.
          </p>
        </div>
        <div className="mb-5">
          <p>11. USER CONTENT AND THE U.S. COMMUNICATIONS DECENCY ACT</p>
          <p>
            Please note Section 230 of the U.S. Communications Decency Act
            ("CDA") (and the equivalent or similar laws in your jurisdiction) is
            intended to exclude or limit the liability of online service
            providers such as Seekr, when such online service providers provide
            or make available access to third-party user generated content (see
            § 230 (c)(1) which states: No provider or user of an interactive
            computer service shall be treated as the publisher or speaker of any
            information provided by another information content provider).
          </p>
          <p>
            The decision by Seekr to remove or not post or distribute any User
            Content, does not by itself amount to participation in the creation
            of such User Content and, accordingly, does not constitute a waiver
            of the immunity afforded by the CDA. Additionally, § 230 (c)(2) of
            the CDA specifically provides: "No provider or user of an
            interactive computer service shall be held liable on account of (A)
            any action voluntarily taken in good faith to restrict access to or
            availability of material that the provider or user considers to be
            obscene, lewd, lascivious, filthy, excessively violent, harassing,
            or otherwise objectionable whether or not such material is
            constitutionally protected…"
          </p>
        </div>
        <div className="mb-5">
          <p>2. GUIDELINES FOR REVIEWS</p>
          <p>
            We may provide you areas on the Services to leave reviews or
            ratings. When posting a review, you must comply with the following
            criteria: (1) you should have firsthand experience with the
            person/entity being reviewed; (2) your reviews should not contain
            offensive profanity, or abusive, racist, offensive, or hate
            language; (3) your reviews should not contain discriminatory
            references based on religion, race, gender, national origin, age,
            marital status, sexual orientation, or disability; (4) your reviews
            should not contain references to illegal activity; (5) you should
            not be affiliated with competitors if posting negative reviews; (6)
            you should not make any conclusions as to the legality of conduct;
            (7) you may not post any false or misleading statements; and (8) you
            may not organize a campaign encouraging others to post reviews,
            whether positive or negative.
          </p>
          <p>
            We may accept, reject, or remove reviews in our sole discretion. We
            have absolutely no obligation to screen reviews or to delete
            reviews, even if anyone considers reviews objectionable or
            inaccurate. Reviews are not endorsed by us, and do not necessarily
            represent our opinions or the views of any of our affiliates or
            partners. We do not assume liability for any review or for any
            claims, liabilities, or losses resulting from any review. By posting
            a review, you hereby grant to us a perpetual, non-exclusive,
            worldwide, royalty-free, fully-paid, assignable, and sublicensable
            right and license to reproduce, modify, translate, transmit by any
            means, display, perform, and/or distribute all content relating to
            reviews.
          </p>
        </div>
        <div className="mb-5">
          <p>13. MOBILE APPLICATION LICENSE</p>
          <p>Use License</p>
          <p>
            If you access the Services via a mobile or tablet device ("Mobile
            Software"), you must have a mobile device that is compatible with
            the Mobile Service. Seekr does not warrant that the Mobile Software
            will be compatible with your mobile device. Seekr hereby grants you
            a non-exclusive, non-transferable, revocable license to use a
            compiled code copy of the Mobile Software for your account on mobile
            devices owned or leased solely by you or your authorized agents, for
            your personal or business use. You may not : (1) modify,
            disassemble, decompile or reverse engineer the Mobile Software,
            except to the extent that such restriction is expressly prohibited
            by law; (2) rent, lease, loan, resell, sublicense, distribute or
            otherwise transfer the Mobile Software to any third party or use the
            Mobile Software to provide time sharing or similar services for any
            third party; (3) make any copies of the Mobile Software; (4) remove,
            circumvent, disable, damage or otherwise interfere with
            security-related features of the Mobile Software, features that
            prevent or restrict use or copying of any content accessible through
            the Mobile Software, or features that enforce limitations on use of
            the Mobile Software; or (5) remove, alter, or obscure any
            proprietary notice (including any notice of copyright or trademark)
            posted by us or the licensors of the Mobile Software; (6) violate
            any applicable laws, rules, or regulations in connection with your
            access or use of the application); (7) use the application for
            creating a product, service, or software that is, directly or
            indirectly, competitive with or in any way a substitute for the
            application; (8) use the application to send automated queries to
            any website or to send any unsolicited commercial e-mail; or (9) use
            any proprietary information or any of our interfaces or our other
            intellectual property in the design, development, manufacture,
            licensing, or distribution of any applications, accessories, or
            devices for use with the application. You acknowledge that Seekr may
            from time to time issue upgraded versions of the Mobile Software,
            and may automatically electronically upgrade the version of the
            Mobile Software that you are using on your mobile device. You
            consent to such automatic upgrading on your mobile device and agree
            that the terms and conditions of this Agreement will apply to all
            such upgrades. Any third-party code that may be incorporated in the
            Mobile Software is covered by the applicable open source or
            third-party license EULA, if any, authorizing use of such code. The
            foregoing license grant is not a sale of the Mobile Software or any
            copy thereof, and Seekr or its third-party partners or suppliers
            retain all right, title, and interest in the Mobile Software (and
            any copy thereof). Any attempt by you to transfer any of the rights,
            duties or obligations hereunder, except as expressly provided for in
            this Agreement, is void. Seekr reserves all rights not expressly
            granted under this Agreement.
          </p>
          <p>Apple and Android Devices</p>
          <p>
            The following terms apply when you use a mobile application obtained
            from either the Apple Store or Google Play (each an “App
            Distributor”) to access the Services: (1) the license granted to you
            for our mobile application is limited to a non-transferable license
            to use the application on a device that utilizes the Apple iOS or
            Android operating systems, as applicable, and in accordance with the
            usage rules set forth in the applicable App Distributor’s terms of
            service; (2) we are responsible for providing any maintenance and
            support services with respect to the mobile application as specified
            in the terms and conditions of this mobile application license
            contained in these Terms of Service or as otherwise required under
            applicable law, and you acknowledge that each App Distributor has no
            obligation whatsoever to furnish any maintenance and support
            services with respect to the mobile application; (3) in the event of
            any failure of the mobile application to conform to any applicable
            warranty, you may notify the applicable App Distributor, and the App
            Distributor, in accordance with its terms and policies, may refund
            the purchase price, if any, paid for the mobile application, and to
            the maximum extent permitted by applicable law, the App Distributor
            will have no other warranty obligation whatsoever with respect to
            the mobile application; (4) you represent and warrant that (i) you
            are not located in a country that is subject to a U.S. government
            embargo, or that has been designated by the U.S. government as a
            “terrorist supporting” country and (ii) you are not listed on any
            U.S. government list of prohibited or restricted parties; (5) you
            must comply with applicable third-party terms of agreement when
            using the mobile application, e.g., if you have a VoIP application,
            then you must not be in violation of their wireless data service
            agreement when using the mobile application; and (6) you acknowledge
            and agree that the App Distributors are third-party beneficiaries of
            the terms and conditions in this mobile application license
            contained in these Terms of Service, and that each App Distributor
            will have the right (and will be deemed to have accepted the right)
            to enforce the terms and conditions in this mobile application
            license contained in these Terms of Service against you as a
            thirdparty beneficiary thereof.
          </p>
          <p>
            If the Mobile Software is being acquired on behalf of the United
            States Government, then the following provision applies: Use,
            duplication, or disclosure of the Mobile Software by the U.S.
            Government is subject to restrictions set forth in this Agreement
            and as provided in DFARS 227.7202-1(a) and 227.7202-3(a) (1995),
            DFARS 252.227-7013(c)(1)(ii) (OCT 1988), FAR 12.212(a) (1995), FAR
            52.227-19, or FAR 52.227-14 (ALT III), as applicable. The Mobile
            Software originates in the United States and is subject to United
            States export laws and regulations. The Mobile Software may not be
            exported or re-exported to certain countries or those persons or
            entities prohibited from receiving exports from the United States.
            In addition, the Mobile Software may be subject to the import and
            export laws of other countries. You agree to comply with all
            applicable laws related to use of the Mobile Software and the
            Services.
          </p>
        </div>
        <div className="mb-5">
          <p>
            14. FREE TRIALS AND PAID SERVICES FOR MEMBERS EXCEPT AS OTHERWISE
            PROVIDED IN A WRITTEN AGREEMENT BETWEEN YOU AND SEEKR, THE FOLLOWING
            TERMS WILL APPLY TO MEMBERS USING PAID SERVICES.
          </p>
          <p>A. Billing Policies</p>
          <p>
            Certain aspects of the Services may be provided for a fee. If you
            are a Member and elect to use a paid feature of the Services, you
            agree to the pricing and payment terms for the applicable Services,
            as we may update them from time to time. Any recurring charges are
            billed in advance of service. Seekr may (1) add new services and
            products for additional fees and charges, at any time in its sole
            discretion, or (2) amend fees and charges for existing services, at
            any time in its sole discretion upon notice. Cancellation requests
            should be made in accordance with Section 14(E)(4) below. We may
            also provide certain products or services via our third-party
            partners, such as paid background checks, and you agree that your
            use of such third-party products or services is subject to the
            contractual (including payment) terms presented by such third
            parties should you wish to use their services. You further agree
            that Seekr has no responsibility for such third-party services and
            your use of such services is entirely at your own risk. Any change
            to the fees for paid services shall become effective in the billing
            cycle following notice of such change to you.
          </p>
          <p>B. No Refunds for Paid Services</p>
          <p>
            You may cancel your subscription to paid services at any time,
            pursuant to Section 14.E below, and you accept that you will not
            receive a refund of pre-paid fees for such cancellation, except
            where otherwise set out in this Agreement. In the event that Seekr
            suspends, limits, conditions, or terminates your access to the
            Services and/or your account due to your material breach of this
            Agreement or any other agreement that you have with Seekr, you
            understand and accept that you will not receive a refund for any
            unused time with respect to fees that you have pre-paid for a
            subscription plan, product or service, and to the extent that you
            have not paid the applicable fees, you will remain liable to pay
            Seekr the entire fees due for the subscription, product or service,
            as applicable.
          </p>
          <p>C. Payment Information; Taxes; Delinquent Payments</p>
          <p>
            If you are a Member, you authorize Seekr to provide any Authorized
            User associated with your account, or such other persons as you may
            hereafter specify in writing, access to view, change, and inquire,
            about bank, payment, tax or other information relating to your
            business relationship with Seekr.
          </p>
          <p>
            All information that you provide in connection with a monetary
            transaction must be accurate, complete, and current. You agree to
            pay all charges incurred by users of your credit card, debit card,
            or other payment method used in connection with a purchase or
            transaction at the prices in effect when such charges are incurred.
            You will also pay any applicable taxes relating to such
            transactions. If at any time Seekr is required by a taxing authority
            to pay any taxes not previously collected from you, you will
            promptly submit such taxes (including applicable penalties and
            interest, if any) to Seekr upon written notice.
          </p>
          <p>
            If you elect to add additional paid services or products to your
            selected subscription or upgrade your subscription, we will invoice
            you for such services, products and upgrades, and/or charge your
            credit or debit card (or other payment method) accordingly.
          </p>
          <p>
            Delinquent payments may bear interest at the rate of
            one-and-one-half percent per month (eighteen percent per annum) or
            the highest rate permitted by law, if less, from the payment due
            date until paid in full. You will be responsible for all reasonable
            expenses (including legal fees and expenses) incurred by Seekr in
            collecting delinquent amounts, except where the delinquent amounts
            are due to billing inaccuracies. In addition, if you are late in
            payment of any fees, we reserve the right to either (i) immediately
            suspend or terminate your access to the applicable Services without
            notice, and in the event of any such suspension or termination, you
            will remain responsible for the entire amount of fees owing for such
            Services; or (ii) bill your credit/debit card on file with us under
            this or any other agreement. Unless your subscription plan is
            terminated timely and correctly, you will continue to accrue charges
            even if you don’t use the subscribed Services.
          </p>
        </div>
        <div className="mb-5">
          <p>D. Credit and Debit Cards</p>
          <p>
            In the event that you pay for any Services using a credit card or
            debit card, you authorize Seekr to charge your credit or debit card
            (or other acceptable payment method that you have chosen from those
            offered) ("Payment Method"), for the applicable fees for the
            duration of the applicable term of your selected subscription plan
            or other paid service or product, together with any applicable
            sales, use, excise, goods and services tax (GST), consumption or
            other similar tax applicable to the Services. You represent that you
            are authorized to use the Payment Method on your company’s behalf,
            and you agree to accept an electronic record of a receipt. If at any
            time your Payment Method will not accept the charge for fees due,
            you agree that Seekr may suspend or terminate your access to the
            applicable Services and that you will continue to remain liable for
            the full payment for such Services even after your access to the
            Services has been terminated or suspended. By providing any Payment
            Method, you agree that applicable credit card, debit card and
            billing information may be shared with third parties such as payment
            processors and credit agencies, and/or collection agencies for the
            purposes of checking your credit, effecting payment, collecting
            payments and late fees if applicable, and for providing the
            applicable Services to you or in order to enforce our agreement with
            you. We may also share this information with law enforcement
            authorities and in response to subpoenas, court orders, and search
            warrants.
          </p>
        </div>
        <div className="mb-5">
          <p>
            E. Free Trials and Subscription Services (for Members that have not
            executed a paper contract)
          </p>
          <p>
            EXCEPT AS OTHERWISE PROVIDED IN A WRITTEN AGREEMENT BETWEEN YOU AND
            SEEKR, OR BETWEEN YOU AND ONE OF OUR THIRD-PARTY PARTNERS, YOU
            ACKNOWLEDGE AND AGREE THAT YOUR SUBSCRIPTION TO THE PAID SERVICES
            WILL AUTOMATICALLY RENEW UNLESS YOU CANCEL IT OR WE SUSPEND OR
            TERMINATE IT IN ACCORDANCE WITH THESE TERMS.
          </p>
          <p>
            (1) Free Trial. If you have been offered a free trial to the job
            posting Services ("Free Trial"), you may be required to: (a) select
            a desired subscription plan ("Subscription Plan"), and (b) provide
            valid credit card/debit card and billing information. Your
            credit/debit card will not be charged until the expiration of the
            Free Trial. If you don’t cancel prior to the end of the Free Trial
            term, we will automatically charge your debit/credit card for the
            first 30 days (if you are on a daily or monthly Subscription Plan)
            or the first year (if you chose an annual Subscription Plan) or
            other applicable recurring period depending on the selected
            Subscription Plan ("Initial Plan Term"). We will continue to charge
            your debit/credit card at the beginning of the applicable renewal
            period following the Initial Plan Term unless you notify Seekr that
            you do not wish to renew prior to the end of the Initial Plan Term.
          </p>
          <p>
            (2) Free Trial Cancellation. You can cancel at any time during the
            Free Trial by calling us at 1-OUR google number (US). You may also
            send us an email at support@SEEKR.com. If you wish to cancel online,
            follow the instructions on the website to cancel your free trial
            immediately. It is your responsibility to timely cancel your Free
            Trial prior to the expiration of the term of the Free Trial to avoid
            any unwanted charges. Once your Free Trial is cancelled, you will
            lose access to information regarding candidates, Job Advertisements
            and other features of the applicable Subscription Plan.
          </p>
          <p>
            (3) Subscription Plans and Renewals. Subscription Plans will
            automatically renew for recurring periods depending on the duration
            of your selected Subscription Plan (e.g., every 1 day, 30 days, 3
            months, 6 months, annual or other period), and, as authorized by you
            by agreeing to the automatic renewal of your Subscription Plan
            during the sign-up process, unless you notify Seekr of any changes
            or requests not to renew (i.e., to cancel), prior to the end of the
            Initial Plan Term (each, a "Renewal Plan Term") or each Renewal Plan
            Term. Cancellation requests should be made in accordance with the
            instructions provided on the applicable Seekr website or in
            accordance with Section 14(E)(4) below. At the time of the automatic
            renewal, the applicable subscription or other fees will be charged
            to the Payment Method provided and/or associated with your account,
            or you will be invoiced for payment. (4) Subscription Plan
            Cancellation. For paid Subscription Plans, you may cancel at any
            time effective at the end of the Initial Plan Term or any Renewal
            Plan Term by calling us at 1-OUR google number (US). You may also
            send us an email at support@SEEKR.com, and one of our
            representatives will contact you. Make sure you cancel prior to your
            next billing renewal date to avoid any unwanted charges. Your
            account will be cancelled effective on your next billing date. Once
            your account is cancelled you will lose access to all content and
            features under your selected Subscription Plan. You agree and accept
            that you will not receive any refund of prepaid fees if you request
            a cancellation and that the cancellation request shall become
            effective at the end of the Initial Plan Term or Renewal Plan Term
            in which the cancellation notice is received by Seekr.
          </p>
        </div>
        <div className="mb-5">
          <p>F. Authorized Users</p>
          <p>
            You are responsible for all activity, acts or omissions of any
            person or entity that is able to access the Services under your
            account and you will limit access to the number of users that are
            allowed under your account to those permitted under your selected
            Subscription Plan (each, an "Authorized User"). Additionally, you
            agree that: (1) you will not share log-in credentials and account
            information with third parties; (2) you will not sell or sublicense
            access to your account or the Services; (3) you will not charge, or
            receive payment, in cash or in kind, from any individual or entity
            for use of, or access to, any portion of the Services; (4) you will
            ensure that Authorized Users comply with this Agreement and any
            other Specific Terms of Service or other agreement that you have
            with Seekr; (5) you will immediately notify Seekr of any suspected
            or alleged violation of this Agreement, including any unauthorized
            use of any password or account information, or any other known or
            suspected breach of security; and (6) you will cooperate with Seekr
            with respect to investigation of any suspected or alleged violation
            of this Agreement and any action by Seekr to enforce this Agreement.
          </p>
          <p>
            Seekr may suspend, limit, condition, or terminate an Authorized
            User's access to the Services or any features thereof, in the event
            that Seekr reasonably determines that such Authorized User has
            violated this Agreement or appears likely to do so.
          </p>
        </div>
        <div className="mb-5">
          <p>G. Suspension or Termination</p>
          <p>
            (i) Suspension or Termination for Cause. Seekr may suspend or
            terminate your access to the Services and/or this Agreement in the
            following instances: (I) if you breach this Agreement and cannot
            correct the breach within that period required by Seekr if the
            breach is capable of cure; (II) immediately, if you fail to pay any
            monies owed to Seekr for paid services; (III) immediately if you
            become the subject of a bankruptcy, insolvency, receivership,
            liquidation, assignment for the benefit of creditors or similar
            proceeding; (IV) immediately in the event of violations of this
            Agreement; (V) for any abuse, inappropriate behavior, or other
            conduct (including posting inaccurate, false, misleading, or
            dishonest Job Advertisements), which in our sole discretion
            adversely affects or has the potential to affect us, our employees
            or users of our Services; (VI) immediately if you send excessive,
            disruptive, deceptive, dishonest, or abusive communications to
            another User by mail, email, text, phone, or other mode of
            communication (as may be determined in Seekr’s sole discretion),
            regardless if such communication takes place on or off the Services;
            (VII) immediately if you in any way disclose, transfer, or sell any
            Data from job seekers profiles or resumes to a third party, except
            as expressly permitted by this Agreement; and/or (VIII) immediately,
            if you charge, or attempt to charge, a Job Seeker any money to
            review or otherwise proceed with the hiring process after such Job
            Seeker submits a job application (Match) on the Services. In the
            event of any suspension of your access to the Services and/or
            termination of this Agreement or any other agreement you have with
            Seekr (other than a termination for convenience by Seekr), you
            remain responsible for the entire amount of the fees (pre-paid
            and/or due) and you will not receive a refund of any pre-paid fees,
            for the applicable Services. Seekr may also suspend your access to
            the Services without penalty and without notice, in the event of a
            suspected violation of this Agreement. In the event your access to
            the Services is terminated for cause in accordance with clauses (V),
            (VI), (VII), or (VIII) above, you shall further be prohibited from
            signing up for or using any other User account, free trial, or paid
            service on the Services.
          </p>
          <p>
            (ii) Suspension or Termination for Convenience. Seekr can terminate
            your access to the Services and/or this Agreement for convenience
            via email notification to you and, in the event of such termination
            for convenience, you will receive a pro-rata refund of any pre-paid
            fees pertaining to Services that will not be provided as a result of
            the termination.
          </p>
          <p>
            (iii) Your Termination Rights. You may terminate this Agreement if
            Seekr materially breaches this Agreement and does not cure the
            breach within ten (10) days after receipt of written notice from
            you, which notice must be emailed to:
            whatemailbusinessaffairs@SEEKR.com. Pre-paid fees are
            non-refundable, except in the case of an uncured material breach by
            Seekr (and in the absence of any contributory act and/or omission to
            act by you), in which case you will receive a pro-rata refund of any
            pre-paid fees pertaining to Services paid for, but not provided, for
            the balance of the applicable term of your then-current Subscription
            Plan.
          </p>
        </div>
        <div className="mb-5">
          <p>15. SOCIAL MEDIA</p>
          <p>
            As part of the functionality of the Services, you may link your
            account with online accounts you have with third-party service
            providers (each such account, a “Third-Party Account”) by either:
            (1) providing your Third-Party Account login information through the
            Service; or (2) allowing us to access your Third-Party Account, as
            is permitted under the applicable terms and conditions that govern
            your use of each Third-Party Account. You represent and warrant that
            you are entitled to disclose your Third-Party Account login
            information to us and/or grant us access to your ThirdParty Account,
            without breach by you of any of the terms and conditions that govern
            your use of the applicable Third-Party Account, and without
            obligating us to pay any fees or making us subject to any usage
            limitations imposed by the third-party service provider of the
            Third-Party Account. By granting us access to any Third-Party
            Accounts, you understand that (1) we may access, make available, and
            store (if applicable) any content that you have provided to and
            stored in your Third-Party Account (the “Social Network Content”) so
            that it is available on and through the Service via your account,
            including without limitation any friend lists and (2) we may submit
            to and receive from your Third-Party Account additional information
            to the extent you are notified when you link your account with the
            Third-Party Account. Depending on the Third-Party Accounts you
            choose and subject to the privacy settings that you have set in such
            Third-Party Accounts, personally identifiable information that you
            post to your Third-Party Accounts may be available on and through
            your account on the Service. Please note that if a Third-Party
            Account or associated service becomes unavailable or our access to
            such Third Party Account is terminated by the third-party service
            provider, then Social Network Content may no longer be available on
            and through the Services. You will have the ability to disable the
            connection between your account on the Service and your Third-Party
            Accounts at any time.
          </p>
          <p>
            PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE
            PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED
            SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS.
            We make no effort to review any Social Network Content for any
            purpose, including but not limited to, for accuracy, legality, or
            non-infringement, and we are not responsible for any Social Network
            Content. You acknowledge and agree that we may access your email
            address book associated with a Third-Party Account and your contacts
            list stored on your mobile device or tablet computer solely for
            purposes of identifying and informing you of those contacts who have
            also registered to use the Services. You can deactivate the
            connection between the Services and your Third-Party Account by
            contacting us using the contact information below or through your
            account settings (if applicable). We will attempt to delete any
            information stored on our servers that was obtained through such
            Third-Party Account, except the username and profile picture that
            become associated with your account
          </p>
        </div>
        <div className="mb-5">
          <p>16. SUBMISSIONS</p>
          <p>
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback, or other information regarding the Services
            ("Submissions") provided by you to us are non-confidential and shall
            become our sole property. We shall own exclusive rights, including
            all intellectual property rights, and shall be entitled to the
            unrestricted use and dissemination of these Submissions for any
            lawful purpose, commercial or otherwise, without acknowledgment or
            compensation to you. You hereby waive all moral rights to any such
            Submissions, and you hereby warrant that any such Submissions are
            original with you or that you have the right to submit such
            Submissions. You agree there shall be no recourse against us for any
            alleged or actual infringement or misappropriation of any
            proprietary right in your Submissions.
          </p>
        </div>
        <div className="mb-5">
          <p>17. THIRD-PARTY WEBSERVICES AND CONTENT</p>
          <p>
            The Services may contain (or you may be sent via the Services) links
            to other websites ("Third-Party Websites") as well as articles,
            photographs, text, graphics, pictures, designs, music, sound, video,
            information, applications, software, and other content or items
            belonging to or originating from third parties ("Third-Party
            Content"). Such Third-Party Websites and Third-Party Content are not
            investigated, monitored, or checked for accuracy, appropriateness,
            or completeness by us, and we are not responsible for any
            Third-Party Websites accessed through the Service or any Third-Party
            Content posted on, available through, or installed from the
            Services, including the content, accuracy, offensiveness, opinions,
            reliability, privacy practices, or other policies of or contained in
            the Third-Party Websites or the Third-Party Content. Inclusion of,
            linking to, or permitting the use or installation of any Third-Party
            Websites or any Third-Party Content does not imply approval or
            endorsement thereof by us. If you decide to leave the Services and
            access the Third-Party Websites or to use or install any Third-Party
            Content, you do so at your own risk, and you should be aware these
            Terms of Service no longer govern.
          </p>
          <p>
            You should review the applicable terms and policies, including
            privacy and data gathering practices, of any website to which you
            navigate from the Service or relating to any applications you use or
            install from the Services. Any purchases you make through
            Third-Party Websites will be through other websites and from other
            companies, and we take no responsibility whatsoever in relation to
            such purchases which are exclusively between you and the applicable
            third party. You agree and acknowledge that we do not endorse the
            products or services offered on Third-Party Websites and you shall
            hold us harmless from any harm caused by your purchase of such
            products or services. Additionally, you shall hold us harmless from
            any losses sustained by you or harm caused to you relating to or
            resulting in any way from any Third-Party Content or any contact
            with Third-Party Websites.
          </p>
        </div>
        <div className="mb-5">
          <p>18. SERVICE MANAGEMENT</p>
          <p>
            We reserve the right, but not the obligation, to: (1) monitor the
            Services for violations of these Terms of Service; (2) take
            appropriate legal action against anyone who, in our sole discretion,
            violates the law or these Terms of Service, including without
            limitation, reporting such user to law enforcement authorities; (3)
            in our sole discretion and without limitation, refuse, restrict
            access to, limit the availability of, or disable (to the extent
            technologically feasible) any of your User Content or any portion
            thereof; (4) in our sole discretion and without limitation, notice,
            or liability, to remove from the Services or otherwise disable all
            files and content that are excessive in size or are in any way
            burdensome to our systems; and (5) otherwise manage the Services in
            a manner designed to protect our rights and property and to
            facilitate the proper functioning of the Services.
          </p>
        </div>
        <div className="mb-5">
          <p>19. PRIVACY POLICY</p>
          <p>
            We care about data privacy and security. By using the Services, you
            agree to be bound by our Privacy Policy posted on the Service, which
            is incorporated into these Terms of Service. Please be advised the
            Service is hosted in the United States. If you access the Services
            from the European Union, Asia, or any other region of the world with
            laws or other requirements governing personal data collection, use,
            or disclosure that differ from applicable laws in the United States,
            then through your continued use of the Services, you are
            transferring your data to the United States, and you expressly
            consent to have your data transferred to and processed in the United
            States. Further, we do not knowingly accept, request, or solicit
            information from children or knowingly market to children.
            Therefore, in accordance with the U.S. Children’s Online Privacy
            Protection Act, if we receive actual knowledge that anyone under the
            age of 13 has provided personal information to us without the
            requisite and verifiable parental consent, we will delete that
            information from the Service as quickly as is reasonably practical.{' '}
          </p>
        </div>
        <div className="mb-5">
          <p>20. TERM AND TERMINATION</p>
          <p>
            These Terms of Service shall remain in full force and effect while
            you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
            TERMS OF SERVICE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION
            AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
            SERVICE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR
            ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH
            OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
            TERMS OF SERVICE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
            TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICE OR DELETE YOUR
            ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME,
            WITHOUT WARNING, IN OUR SOLE DISCRETION.
          </p>
          <p>
            If we terminate or suspend your account for any reason, you are
            prohibited from registering and creating a new account under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.
          </p>
        </div>
        <div className="mb-5">
          <p>21. MODIFICATIONS AND INTERRUPTIONS</p>
          <p>
            We reserve the right to change, modify, or remove the contents of
            the Services at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Services. We also reserve the right to modify or
            discontinue all or part of the Services without notice at any time.
            We will not be liable to you or any third party for any
            modification, price change, suspension, or discontinuance of the
            Services. We cannot guarantee the Services will be available at all
            times. We may experience hardware, software, or other problems or
            need to perform maintenance related to the Services, resulting in
            interruptions, delays, or errors. We reserve the right to change,
            revise, update, suspend, discontinue, or otherwise modify the
            Services at any time or for any reason without notice to you. You
            agree that we have no liability whatsoever for any loss, damage, or
            inconvenience caused by your inability to access or use the Services
            during any downtime or discontinuance of the Services. Nothing in
            these Terms of Service will be construed to obligate us to maintain
            and support the Services or to supply any corrections, updates, or
            releases in connection therewith.
          </p>
        </div>
        <div className="mb-5">
          <p>22. GOVERNING LAW</p>
          <p>
            These Terms of Service and your use of the Service are governed by
            and construed in accordance with the laws of the State of Delaware
            applicable to agreements made and to be entirely performed within
            the State of Delaware, without regard to its conflict of law
            principles.
          </p>
        </div>
        <div className="mb-5">
          <p>23. DISPUTE RESOLUTION</p>
          <p>Informal Negotiations</p>
          <p>
            To expedite resolution and control the cost of any dispute,
            controversy, or claim related to these Terms of Service (each a
            "Dispute" and collectively, the “Disputes”) brought by either you or
            us (individually, a “Party” and collectively, the “Parties”), the
            Parties agree to first attempt to negotiate any Dispute (except
            those Disputes expressly provided below) informally for at least
            thirty (30) days before initiating arbitration. Such informal
            negotiations commence upon written notice from one Party to the
            other Party.
          </p>
        </div>
        <div className="mb-5">
          <p>24. Binding Arbitration</p>
          <p>
            If the Parties are unable to resolve a Dispute through informal
            negotiations, the Dispute (except those Disputes expressly excluded
            below) will be finally and exclusively resolved by binding
            arbitration. You understand that without this provision, you would
            have the right to sue in court and have a jury trial. The
            arbitration shall be commenced and conducted under the Commercial
            Arbitration Rules of the American Arbitration Association ("AAA")
            and, where appropriate, the AAA’s Supplementary Procedures for
            Consumer Related Disputes ("AAA Consumer Rules"), both of which are
            available at the AAA website www.adr.org. Your arbitration fees and
            your share of arbitrator compensation shall be governed by the AAA
            Consumer Rules and, where appropriate, limited by the AAA Consumer
            Rules. The arbitration may be conducted in person, through the
            submission of documents, by phone, or online. The arbitrator will
            make a decision in writing but need not provide a statement of
            reasons unless requested by either Party. The arbitrator must follow
            applicable law, and any award may be challenged if the arbitrator
            fails to do so. Except where otherwise required by the applicable
            AAA rules or applicable law, the arbitration will take place in
            Miami-Dade County, FL. Except as otherwise provided herein, the
            Parties may litigate in court to compel arbitration, stay
            proceedings pending arbitration, or to confirm, modify, vacate, or
            enter judgment on the award entered by the arbitrator.
          </p>
          <p>
            If for any reason, a Dispute proceeds in court rather than
            arbitration, the Dispute shall be commenced or prosecuted in the
            state and federal courts located in Miami-Dade Country, FL, and the
            Parties hereby consent to, and waive all defenses of lack of
            personal jurisdiction, and forum non-conveniens with respect to
            venue and jurisdiction in such state and federal courts. Application
            of the United Nations Convention on Contracts for the International
            Sale of Goods and the Uniform Computer Information Transaction Act
            (UCITA) are excluded from these Terms of Service. In no event shall
            any Dispute brought by either Party related in any way to the
            Services be commenced more than one (1) years after the cause of
            action arose. If this provision is found to be illegal or
            unenforceable, then neither Party will elect to arbitrate any
            Dispute falling within that portion of this provision found to be
            illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
          </p>
        </div>
        <div className="mb-5">
          <p>25. Restrictions</p>
          <p>
            The Parties agree that any arbitration shall be limited to the
            Dispute between the Parties individually. To the full extent
            permitted by law, (a) no arbitration shall be joined with any other
            proceeding; (b) there is no right or authority for any Dispute to be
            arbitrated on a class-action basis or to utilize class action
            procedures; and (c) there is no right or authority for any Dispute
            to be brought in a purported representative capacity on behalf of
            the general public or any other persons.
          </p>
        </div>
        <div className="mb-5">
          <p>26. Exceptions to Informal Negotiations and Arbitration</p>
          <p>
            The Parties agree that the following Disputes are not subject to the
            above provisions concerning informal negotiations and binding
            arbitration: (a) any Disputes seeking to enforce or protect, or
            concerning the validity of, any of the intellectual property rights
            of a Party; (b) any Dispute related to, or arising from, allegations
            of theft, piracy, invasion of privacy, or unauthorized use; and (c)
            any claim for injunctive relief. If this provision is found to be
            illegal or unenforceable, then neither Party will elect to arbitrate
            any Dispute falling within that portion of this provision found to
            be illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
          </p>
        </div>
        <div className="mb-5">
          <p>27. CORRECTIONS</p>
          <p>
            There may be information on the Service that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Service at any time, without prior
            notice.
          </p>
        </div>
        <div className="mb-5">
          <p>28. DISCLAIMER OF WARRANTY</p>
          <p>
            The Services are provided on an as-is and as-available basis. You
            agree that your use of the Services and our services will be at your
            sole risk. To the fullest extent permitted by law, we disclaim all
            warranties, express or implied, in connection with the Services and
            your use thereof, including, without limitation, the implied
            warranties of merchantability, fitness for a particular purpose, and
            non-infringement. We make no warranties or representations about the
            accuracy or completeness of the service’s content or the content of
            any webservices linked to the Service and we will assume no
            liability or responsibility for any (1) errors, mistakes, or
            inaccuracies of content and materials, (2) personal injury or
            property damage, of any nature whatsoever, resulting from your
            access to and use of the Service, (3) any unauthorized access to or
            use of our secure servers and/or any and all personal information
            and/or financial information stored therein, (4) any interruption or
            cessation of transmission to or from the Service, (5) any bugs,
            viruses, trojan horses, or the like which may be transmitted to or
            through the Service by any third party, and/or (6) any errors or
            omissions in any content and materials or for any loss or damage of
            any kind incurred as a result of the use of any content posted,
            transmitted, or otherwise made available via the service. we do not
            warrant, endorse, guarantee, or assume responsibility for any
            product or service advertised or offered by a third party through
            the Services, any hyperlinked webservice, or any webservice or
            mobile application featured in any banner or other advertising, and
            we will not be a party to or in any way be responsible for
            monitoring any transaction between you and any third-party providers
            of products or services. as with the purchase of a product or
            service through any medium or in any environment, you should use
            your best judgment and exercise caution where appropriate.
          </p>
        </div>
        <div className="mb-5">
          <p>29. LIMITATIONS OF LIABILITY</p>
          <p>
            In no event will we or our directors, employees, or agents be liable
            to you or any third party for any direct, indirect, consequential,
            exemplary, incidental, special, or punitive damages, including lost
            profit, lost revenue, loss of data, or other damages arising from
            your use of the Service, even if we have been advised of the
            possibility of such damages. Notwithstanding anything to the
            contrary contained herein, our liability to you for any cause
            whatsoever and regardless of the form of the action, will at all
            times be limited to the amount paid, if any, by you to us during the
            six (6) month period prior to any cause of action arising. Certain
            state laws do not allow limitations on implied warranties or the
            exclusion or limitation of certain damages. If these laws apply to
            you, some or all of the above disclaimers or limitations may not
            apply to you, and you may have additional rights.
          </p>
        </div>
        <div className="mb-5">
          <p>30. INDEMNIFICATION</p>
          <p>
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out of: (1)
            your User Content; (2) use of the Services; (3) breach of these
            Terms of Service; (4) any breach of your representations and
            warranties set forth in these Terms of Service; ( 5) your violation
            of the rights of a third party, including but not limited to
            intellectual property rights; or (6) any overt harmful act toward
            any other user of the Service with whom you connected via the
            Services. Notwithstanding the foregoing, we reserve the right, at
            your expense, to assume the exclusive defense and control of any
            matter for which you are required to indemnify us, and you agree to
            cooperate, at your expense, with our defense of such claims. We will
            use reasonable efforts to notify you of any such claim, action, or
            proceeding which is subject to this indemnification upon becoming
            aware of it.
          </p>
        </div>
        <div className="mb-5">
          <p>31. USER DATA</p>
          <p>
            We will maintain certain data that you transmit to the Services for
            the purpose of managing the performance of the Services, as well as
            data relating to your use of the Services. Although we perform
            regular routine backups of data, you are solely responsible for all
            data that you transmit or that relates to any activity you have
            undertaken using the Service. You agree that we shall have no
            liability to you for any loss or corruption of any such data, and
            you hereby waive any right of action against us arising from any
            such loss or corruption of such data.
          </p>
        </div>
        <div className="mb-5">
          <p>32. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</p>
          <p>
            Visiting the Services, sending us emails, chatting with us within
            our Apps, and completing online forms constitute electronic
            communications. You consent to receive electronic communications,
            and you agree that all agreements, notices, disclosures, and other
            communications we provide to you electronically, via email and on
            the Services, satisfy any legal requirement that such communication
            be in writing.
          </p>
          <p>
            YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS,
            ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES,
            POLICIES. Some personal data may be collected from Users. AND
            RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
            SERVICE. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means
          </p>
        </div>
        <div className="mb-5">
          <p>33. FLORIDA USERS AND RESIDENTS</p>
          <p>
            If any complaint you may have with us is not satisfactorily
            resolved, you can contact the Consumer Protection Division of the
            Office of the Attorney General State of Florida PL-01 The Capitol
            Tallahassee, FL 32399-1050 or by telephone at{' '}
            <a href="tel:8504143300">850-414-3300</a> or
            <a href="tel:8504143300">(850)-414-3990</a>.
          </p>
        </div>
        <div className="mb-5">
          <p>34. MISCELLANEOUS</p>
          <p>
            These Terms of Service and any policies or operating rules posted by
            us on the Services or in respect to the Services constitute the
            entire agreement and understanding between you and us. Our failure
            to exercise or enforce any right or provision of these Terms of
            Service shall not operate as a waiver of such right or provision.
            These Terms of Service operate to the fullest extent permissible by
            law. We may assign any or all of our rights and obligations to
            others at any time. We shall not be responsible or liable for any
            loss, damage, delay, or failure to act caused by any cause beyond
            our reasonable control. If any provision or part of a provision of
            these Terms of Service is determined to be unlawful, void, or
            unenforceable, that provision or part of the provision is deemed
            severable from these Terms of Service and does not affect the
            validity and enforceability of any remaining provisions.
          </p>
          <p>
            There is no joint venture, partnership, employment or agency
            relationship created between you and us as a result of these Terms
            of Service or use of the Service. You agree that these Terms of
            Service will not be construed against us by virtue of having drafted
            them. You hereby waive any and all defenses you may have based on
            the electronic form of these Terms of Service and the lack of
            signing by the parties hereto to execute these Terms of Service.
          </p>
        </div>
        <div className="mb-5">
          <p>35. CONTACT US</p>
          <p>
            To resolve a complaint regarding the Services or to receive further
            information regarding use of the Service, please contact us at:
          </p>
          <p className="m-0">Madruga, LLC</p>
          <p className="m-0">10 Aragon ave, PH1510</p>
          <p className="m-0">Coral Gables, Fl 33134</p>
          <p className="m-0">United States</p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
