// @ts-ignore
import { ReactComponent as Woman } from '../../assets/img/woman.svg';
// @ts-ignore
import { ReactComponent as Arrow } from '../../assets/img/arrow.svg';
// @ts-ignore
import { default as Paper } from '../../assets/img/paper.svg';
// @ts-ignore
import { default as Heart } from '../../assets/img/heart.svg';
// @ts-ignore
import { default as Meditating } from '../../assets/img/meditating.svg';
// @ts-ignore
import { default as SettingsBlock } from '../../assets/img/settings-block.svg';
// @ts-ignore
import Photo from '../../assets/img/Photo.png';
import { observer } from 'mobx-react-lite';
import { userStore } from '../../store';
import { useHistory } from 'react-router-dom';
import { Roles } from '../../types/roles';

const Dashboard: React.FC = () => {
  const { loggedInUser, userRole } = userStore;
  const history = useHistory();

  return (
    <div className="dashboard">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12">
            <div className="card-item flex justify-between align-stretch">
              <div className="left-wrap justify-between flex direction-column">
                <div>
                  <img src={Photo} alt="log" className="logo-image" />
                  <div>
                    <h3>
                      Welcome to Seekr,{' '}
                      {loggedInUser.firstName
                        ? `${loggedInUser.firstName} ${loggedInUser.lastName}`
                        : null}
                      .
                    </h3>
                    <p className="hint-item">
                      Let us help you manage your account!
                    </p>
                  </div>
                </div>
                <div className="redirect">
                  <span className="text">View profile</span>
                  <Arrow />
                </div>
              </div>
              <div>
                <Woman />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="card-item mt-4">
              <img src={Meditating} alt="meditating" />
              <h4 className="card-item--headline mt-3">Manage Businesses</h4>
              <p className="card-item--headline">
                Administration for your locations, users, job posts, and more...
              </p>
              <div
                className="redirect"
                onClick={() => {
                  history.push('/manage-businesses');
                }}>
                <span className="text">View your business</span>
                <Arrow />
              </div>
            </div>
          </div>
          {userRole === Roles.super ? (
            <div className="col-4">
              <div className="card-item mt-4">
                <img src={Heart} alt="heart" />
                <h4 className="card-item--headline mt-3">Manage Users</h4>
                <p className="card-item--headline">
                  Administration for your locations, users, job posts, and
                  more...
                </p>
                <div
                  className="redirect"
                  onClick={() => {
                    history.push('/manage-users');
                  }}>
                  <span className="text">View your users</span>
                  <Arrow />
                </div>
              </div>
            </div>
          ) : null}
          <div className="col-4">
            <div className="card-item mt-4">
              <img src={Paper} alt="paper" />
              <h4 className="card-item--headline mt-3">Manage Job Postings</h4>
              <p className="card-item--headline">
                Administration for your locations, users, job posts, and more...
              </p>
              <div
                className="redirect"
                onClick={() => {
                  history.push('/manage-job-postings');
                }}>
                <span className="text">View job postings</span>
                <Arrow />
              </div>
            </div>
          </div>
        </div>
        {userRole === Roles.super ? (
          <div className="row">
            <div className="col-12">
              <div className="card-item mt-4">
                <div className="flex justify-between align-center">
                  <div className="flex">
                    <img src={SettingsBlock} alt="block" className="mr-1" />
                    <div>
                      <h4 className="card-item--headline">Manage Settings</h4>
                      <p className="card-item--headline mb-0 mt-2">
                        Manage all system settings, job types, etc
                      </p>
                    </div>
                  </div>
                  <div
                    className="redirect"
                    onClick={() => {
                      history.push('/manage-job-postings');
                    }}>
                    <span className="text">View job postings</span>
                    <Arrow />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default observer(Dashboard);
