import React, { useState, useEffect } from 'react';
// @ts-ignore
import { default as Passed } from '../../../assets/img/passed-icon.svg';
import { useQuery } from '@apollo/client';
import { ADMIN_GET_QUALIFICATIONS } from '../../../apollo/queries';
import DropdownMultiple from '../../Shared/DropdownMultiple';

interface NecessaryProps {
  updateData: (dataType: string, data: [string] | Item[]) => void;
  selected: Item[] | [];
}

type Item = {
  _id: string;
  name: string;
};

const NecessaryRole: React.FC<NecessaryProps> = ({ updateData, selected }) => {
  const [necessaryTypes, setNecessaryTypes] = useState<[]>([]);

  const { data, loading } = useQuery(ADMIN_GET_QUALIFICATIONS, {
    variables: {
      paginationDto: {
        limit: 1000,
        skip: 0,
      },
      searchBy: '',
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      setNecessaryTypes(data.adminGetQualifications.collection);
    }
  }, [data]);

  const select = (isChecked: boolean, item: Item) => {
    let arr = [...selected];
    if (isChecked) {
      arr.push(item);
    } else {
      arr = arr.filter((sel) => sel._id !== item._id);
    }
    updateData('necessary', [...arr]);
  };

  return (
    <div className="job-post--item">
      <div className="flex align-center justify-content-between head">
        <div className="flex align-center">
          {selected.length ? (
            <img src={Passed} alt="success" />
          ) : (
            <p className="number">6</p>
          )}
          <p className="headline">What’s necessary for the role?*</p>
        </div>
      </div>
      <p className="sub-headline">Choose up to 3</p>
      <div className="mt-3">
        {loading ? (
          <div className="ssc-square skeleton-item job-skeleton"></div>
        ) : (
          <DropdownMultiple
            max={3}
            data={necessaryTypes}
            selected={selected}
            onSelect={select}
            placeholder="Select necessary types"
          />
        )}
      </div>
    </div>
  );
};

export default NecessaryRole;
