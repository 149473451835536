import React, { useEffect, useState } from 'react';
// @ts-ignore
import { default as Passed } from '../../../assets/img/passed-icon.svg';
import { ADMIN_GET_AVAILABILITY } from '../../../apollo/queries';
import { useQuery } from '@apollo/client';
import Dropdown from '../../Shared/Dropdown';

interface JobTypeProps {
  updateData: (dataType: string, data: string | Item) => void;
  selected: string;
}

type Item = {
  _id: string;
  name: string;
};

const JobType: React.FC<JobTypeProps> = ({ updateData, selected }) => {
  const [jobPositionTypes, setJobPositionTypes] = useState<[]>([]);

  const { data, loading } = useQuery(ADMIN_GET_AVAILABILITY, {
    variables: {
      paginationDto: {
        limit: 1000,
        skip: 0,
      },
      searchBy: '',
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      setJobPositionTypes(data.adminGetAvailability.collection);
    }
  }, [data]);

  const onSelect = (data: Item) => {
    updateData('availability', data);
  };

  return (
    <div className="job-post--item">
      <div className="flex align-center justify-content-between head">
        <div className="flex align-center">
          {selected ? (
            <img src={Passed} alt="success" />
          ) : (
            <p className="number">2</p>
          )}
          <p className="headline">What type of position is this?*</p>
        </div>
      </div>
      <p className="sub-headline">Select type of position.</p>
      <div className="mt-3">
        {loading ? (
          <div className="ssc-square skeleton-item job-skeleton"></div>
        ) : (
          <Dropdown
            onSelect={onSelect}
            selected={selected}
            data={jobPositionTypes}
            placeholder="Select Type of position"
          />
        )}
      </div>
    </div>
  );
};

export default JobType;
