import { makeAutoObservable } from 'mobx';
import { users } from '../types/users';

type Images = {
  url: string;
};

class JobPostStore {
  images: Images[] = [];
  usersList: users[] = [];
  jobPostsCount: number = 0;
  // firstFiveJobPostsIds: { _id: string }[] = [];
  jobPostsList: { _id: string }[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setJobPostsList = (data: { _id: string }[]) => {
    this.jobPostsList = data;
  };

  setJobPostCount = (count: number) => {
    this.jobPostsCount = count;
  };

  updateImages = (images: []) => {
    this.images = [...images];
  };

  setUsers = (users: users[]) => {
    this.usersList = [...users];
  };

  get firstFiveJobPostsIds() {
    const output = [];

    // @ts-ignore
    for (const [i, value] of this.jobPostsList.entries()) {
      if (i < 5) {
        output.push({ _id: value._id });
      }
    }

    return output;
  }

  get transformedUsers() {
    return this.usersList.map((usr) => {
      return {
        name: `${usr.firstName} ${usr.lastName}`,
        _id: usr._id,
      };
    });
  }

  get isUsers() {
    return !!this.usersList.length;
  }

  get imagesUrls() {
    return this.images.map((img: Images) => {
      if (!img.url) {
        // @ts-ignore
        return URL.createObjectURL(img);
      } else {
        return img.url;
      }
    });
  }
}

const jobPostStore = new JobPostStore();

export default jobPostStore;
