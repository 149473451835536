import React, { useState, useEffect } from 'react';
// @ts-ignore
import { default as Passed } from '../../../assets/img/passed-icon.svg';
import { ADMIN_GET_JOB_TYPES } from '../../../apollo/queries';
import { useQuery } from '@apollo/client';
import Dropdown from '../../Shared/Dropdown';

interface JobPositionProps {
  updateData: (dataType: string, data: string | Item) => void;
  selected: string;
}

type Item = {
  _id: string;
  name: string;
};

const JobPosition: React.FC<JobPositionProps> = ({ updateData, selected }) => {
  const [jobPositionTypes, setJobPositionTypes] = useState<[]>([]);

  const { data, loading } = useQuery(ADMIN_GET_JOB_TYPES, {
    variables: {
      paginationDto: {
        limit: 1000,
        skip: 0,
      },
      searchBy: '',
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      setJobPositionTypes(data.adminGetJobTypes.collection);
    }
  }, [data]);

  const onSelect = (data: Item) => {
    updateData('jobType', data);
  };

  return (
    <div className="job-post--item">
      <div className="flex align-center justify-content-between head">
        <div className="flex align-center">
          {selected ? (
            <img src={Passed} alt="success" />
          ) : (
            <p className="number">1</p>
          )}
          <p className="headline">Which position are you hiring for?*</p>
        </div>
      </div>
      <p className="sub-headline">
        Choose one that best describes the position you are hiring for.
      </p>
      <div className="mt-3">
        {loading ? (
          <div className="ssc-square skeleton-item job-skeleton"></div>
        ) : (
          <Dropdown
            onSelect={onSelect}
            data={jobPositionTypes}
            selected={selected}
            placeholder="Select Position"
          />
        )}
      </div>
    </div>
  );
};

export default JobPosition;
