import { gql } from '@apollo/client';

export const GET_CUSTOM_TOKEN = gql`
  query getCustomToken {
    getCustomToken {
      customToken
    }
  }
`;

export const ADMIN_GET_ALL_ADMIN_BUSINESSES = gql`
  query adminGetAllAdminBusinesses(
    $searchBy: String!
    $sortBy: String!
    $paginationDto: PaginationDto!
  ) {
    adminGetAllAdminBusinesses(
      searchBy: $searchBy
      sortBy: $sortBy
      paginationDto: $paginationDto
    ) {
      count
      collection {
        _id
        name
        phone
        address1
        address2
        state
        zip
        city
        backgroundColor
        active
        owner {
          _id
          firstName
          lastName
        }
      }
    }
  }
`;

export const ADMIN_GET_ALL_ADMIN_BUSINESSES_FORM = gql`
  query adminGetAllAdminBusinesses(
    $searchBy: String!
    $sortBy: String!
    $paginationDto: PaginationDto!
  ) {
    adminGetAllAdminBusinesses(
      searchBy: $searchBy
      sortBy: $sortBy
      paginationDto: $paginationDto
    ) {
      collection {
        _id
        name
      }
    }
  }
`;

export const GET_BUSINESS_NAME = gql`
  query adminGetBusiness($businessId: String!) {
    adminGetBusiness(businessId: $businessId) {
      name
    }
  }
`;

export const GET_ADMIN_CONFIRM_TOKEN = gql`
  query getAdminConfirmToken($getConfirmTokenDto: GetConfirmTokenDto!) {
    getAdminConfirmToken(getConfirmTokenDto: $getConfirmTokenDto) {
      confirmToken
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query {
    businessProfile {
      _id
      phone
      email
      type
      firstName
      lastName
      seekerProfile {
        zip
        standOut
        lifeGoal
        active
        experience {
          role
          company
          dateFrom
          dateTo
          active
        }
        certification {
          name
          dateFrom
          dateTo
          status
          active
        }
        education {
          name
          dateFrom
          dateTo
          status {
            _id
            name
            active
          }
          active
        }
        photos {
          _id
          key
          name
          active
        }
        video {
          key
          name
          active
        }
        advantages {
          _id
          name
          active
        }
        jobTypes {
          _id
          name
          active
        }
        availability {
          _id
          name
          active
        }
        interests {
          _id
          name
          active
        }
        skills {
          _id
          name
          active
        }
        backgroundColor
      }
      businessRelations {
        _id
        businessRole
        business {
          createdAt
          name
          _id
          phone
          zip
          address1
          address2
          city
          state
          active
          locations {
            _id
            jobPosts
            name
            phone
            address1
            address2
            city
            state
            zip
            active
          }
          photos {
            _id
            key
            name
            active
          }
          video {
            key
            name
            active
          }
          categories {
            _id
            name
            active
          }
          backgroundColor
        }
      }
    }
  }
`;

export const GET_ADMIN_LOCATION = gql`
  query getAdminLocations(
    $getAdminLocationsDto: GetAdminLocationsDto!
    $businessId: String!
  ) {
    getAdminLocations(
      getAdminLocationsDto: $getAdminLocationsDto
      businessId: $businessId
    ) {
      count
      collection {
        _id
        jobPosts
        owner {
          email
        }
        name
        phone
        address1
        address2
        city
        state
        zip
        active
      }
    }
  }
`;

export const GET_ADMIN_ALL_BUSINESS_USERS = gql`
  query adminGetAllBusinessUsersBusinessWide(
    $sortBy: String!
    $searchBy: String!
    $paginationDto: PaginationDto!
    $businessId: String!
  ) {
    adminGetAllBusinessUsersBusinessWide(
      sortBy: $sortBy
      searchBy: $searchBy
      paginationDto: $paginationDto
      businessId: $businessId
    ) {
      collection {
        _id
        phone
        email
        type
        firstName
        lastName
        isArchived
        businessRelations {
          active
          _id
          businessRole
          businessLocations {
            _id
            name
            phone
            address1
            city
          }
          business {
            _id
            name
            phone
            zip
            address1
            address2
            city
            state
            active
            locations {
              _id
              jobPosts
              name
              phone
              address1
              address2
              city
              state
              zip
              active
            }
            photos {
              _id
              key
              name
              active
            }
            video {
              key
              name
              active
            }
            categories {
              _id
              name
              active
            }
            backgroundColor
          }
        }
      }
      count
    }
  }
`;

export const ADMIN_GET_JOB_TYPES = gql`
  query adminGetJobTypes($searchBy: String!, $paginationDto: PaginationDto!) {
    adminGetJobTypes(searchBy: $searchBy, paginationDto: $paginationDto) {
      collection {
        _id
        name
      }
    }
  }
`;

export const ADMIN_GET_AVAILABILITY = gql`
  query adminGetAvailability(
    $searchBy: String!
    $paginationDto: PaginationDto!
  ) {
    adminGetAvailability(searchBy: $searchBy, paginationDto: $paginationDto) {
      collection {
        _id
        name
      }
    }
  }
`;

export const ADMIN_GET_QUALIFICATIONS = gql`
  query adminGetQualifications(
    $searchBy: String!
    $paginationDto: PaginationDto!
  ) {
    adminGetQualifications(searchBy: $searchBy, paginationDto: $paginationDto) {
      collection {
        _id
        name
      }
    }
  }
`;

export const ADMIN_GET_ADVANTAGES = gql`
  query adminGetAdvantages($searchBy: String!, $paginationDto: PaginationDto!) {
    adminGetAdvantages(searchBy: $searchBy, paginationDto: $paginationDto) {
      collection {
        _id
        name
      }
    }
  }
`;

export const ADMIN_GET_ALL_JOB_POSTS = gql`
  query getAllAdminJobPostsByBusinessWideCollection(
    $searchBy: String!
    $businessId: String!
    $sortBy: String!
    $paginationDto: PaginationDto!
  ) {
    getAllAdminJobPostsByBusinessWideCollection(
      searchBy: $searchBy
      businessId: $businessId
      sortBy: $sortBy
      paginationDto: $paginationDto
    ) {
      count
      matchesCountList {
        _id
        count
      }
      collection {
        isSnooze
        backgroundColor
        owner {
          _id
          firstName
          lastName
        }
        _id
        jobType {
          _id
          name
          active
        }
        availability {
          _id
          name
          active
        }
        compensationType
        rate
        rateRange {
          from
          to
        }
        experience
        advantages {
          _id
          name
          active
        }
        necessary {
          _id
          name
          active
        }
        preferred {
          _id
          name
          active
        }
        about
        description
        photos {
          _id
          key
          name
          active
        }
        video {
          name
          key
          active
        }
        location {
          _id
          jobPosts
          name
          phone
          address1
          address2
          city
          state
          zip
          active
        }
      }
    }
  }
`;

export const GET_ADMIN_ALL_BUSINESS_USERS_SYSTEM_WIDE = gql`
  query adminGetAllBusinessUsersSystemWide(
    $sortBy: String!
    $searchBy: String!
    $paginationDto: PaginationDto!
  ) {
    adminGetAllBusinessUsersSystemWide(
      sortBy: $sortBy
      searchBy: $searchBy
      paginationDto: $paginationDto
    ) {
      collection {
        _id
        phone
        email
        type
        firstName
        lastName
        businessRelations {
          _id
          businessRole
          active
          business {
            _id
            name
            phone
            zip
            address1
            address2
            city
            state
            active
            locations {
              _id
              jobPosts
              name
              phone
              address1
              address2
              city
              state
              zip
              active
            }
            photos {
              _id
              key
              name
              active
            }
            video {
              key
              name
              active
            }
            categories {
              _id
              name
              active
            }
            backgroundColor
          }
          businessLocations {
            _id
            jobPosts
            name
            phone
            address1
            address2
            city
            state
            zip
            active
          }
        }
      }
      count
    }
  }
`;

export const GET_ADMIN_ALL_USERS = gql`
  query {
    adminGetAllUsers {
      _id
      phone
      email
      type
      firstName
      lastName
    }
  }
`;
