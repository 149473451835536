import React, { useState, useEffect } from 'react';
import { axios } from '../../utils/axios';
// @ts-ignore
import { useSnackbar } from 'react-simple-snackbar';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { businessStore } from '../../store';

type Product = {
  _id: string;
  name: string;
  default_price: string;
  price: {
    unit_amount: number;
  };
};

const Billing: React.FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [priceId, setPriceId] = useState<string | null>(null);
  const [products, setProducts] = useState<Array<Product>>([]);
  const history = useHistory();

  const { isBilled } = businessStore;

  useEffect(() => {
    setLoading(true);
    axios.get('/stripe/products').then(({ data }) => {
      setLoading(false);
      setProducts(data);
      setPriceId(data[0]?.default_price);
    });

    const query = history.location.search;
    if (query.includes('success')) {
      setIsSuccess(true);
    } else if (query.includes('cancel')) {
      setIsCancel(true);
    }
  }, []);

  const options = {
    style: {
      textAlign: 'center',
    },
  };

  const { id } = useParams<{ id: string }>();

  const [openSnackbar] = useSnackbar(options);

  const selectPlan = async (priceId: string) => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `/stripe/create-checkout-portal-session/business/${id}`,
        {
          priceId,
        },
      );
      window.open(data.url, '_self');
      setLoading(false);
    } catch (e: any) {
      const { data } = e.response.data;
      if (
        data &&
        data.length &&
        data[0].errors.length &&
        data[0].errors[0] === 'customerAlreadySubscribed'
      ) {
        openSnackbar('Customer Already Subscribed');
        setLoading(false);
        return;
      }
      openSnackbar(e.message);
      setLoading(false);
    }
  };

  const manageBilling = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `/stripe/create-customer-portal-session/business/${id}`,
      );
      window.open(data.url, '_self');
    } catch (e: any) {
      openSnackbar(e.message);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div>
      {isLoading ? (
        <div className="main-item ssc-square billing-skeleton"></div>
      ) : (
        <div>
          {isBilled ? (
            <div className="billing">
              <div className="flex align-center">
                <div className="card-holder mr-1"></div>
                <div className="flex flex-column justify-content-between content">
                  <h4 className="m-0 headline">Manage Subscription</h4>
                  <p className="m-0">
                    Proceed to view/manage payments, payment methods, and
                    subscription with Stripe.
                  </p>
                </div>
              </div>
              <div className="flex align-items-center">
                <button className="billing-button" onClick={manageBilling}>
                  Manage Billing <div className="arrow"></div>
                </button>
              </div>
            </div>
          ) : (
            <div>
              <h2 className="flex justify-content-center mt-4 mb-5">
                Select a subscription plan
              </h2>
              <div className="price-wrap mt-2">
                {products.map((product) => {
                  return (
                    <div key={product._id} className="price-card">
                      <h4 className="mb-3">{product.name}</h4>
                      <h5 className="mb-3">
                        ${product.price.unit_amount / 100} per month
                      </h5>
                      <button
                        onClick={() => selectPlan(product.default_price)}
                        disabled={false}
                        className="btn btn--main pr-2 pl-2">
                        Get Started
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
      {isSuccess && (
        <div className="flex mt-4 justify-content-center direction-column align-items-center">
          <h3 className="mb-3">Payment successful</h3>
          <div className="billing-success"></div>
        </div>
      )}
      {isCancel && (
        <div className="flex mt-4 justify-content-center direction-column align-items-center">
          <h3 className="mb-3">Payment canceled</h3>
          <div className="billing-failed"></div>
        </div>
      )}
    </div>
  );
};

export default observer(Billing);
