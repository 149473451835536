import React from 'react';
import '../../assets/skeleton/index.scss';

const Skeleton = () => {
  return (
    <div className="skeleton">
      <div className="ssc-square skeleton__sidebar"></div>
      <div className="skeleton__content">
        <div className="row">
          <div className="col-xs-12">
            <div className="main-item ssc-square"></div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-4">
            <div className="ssc-square small-item"></div>
          </div>
          <div className="col-4">
            <div className="ssc-square small-item"></div>
          </div>
          <div className="col-4">
            <div className="ssc-square small-item"></div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className="ssc-square bottom-item"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
