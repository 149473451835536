import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react';
import {
  ADMIN_CREATE_BUSINESS_USER_BUSINESS,
  ADMIN_UPDATE_BUSINESS_USER_BUSINESS,
} from '../../apollo/mutations';
import { users } from '../../types/users';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { REGEX, ROLES_LIST } from '../../constants';
import CustomSelect from '../Shared/CustomSelect';
// @ts-ignore
import { useSnackbar } from 'react-simple-snackbar';
import { GET_ADMIN_LOCATION } from '../../apollo/queries';
import { sortItems } from '../../types/sortPopup';
import proxy from '../../tools/proxy';
import extractGraphQLErrors from '../../tools/extractGraphqlErrors';

interface AddUserProps {
  onClose: () => void;
  onSuccess: () => void;
  itemToEdit: users | null;
}

const AddBusinessUser: React.FC<AddUserProps> = ({
  onClose,
  onSuccess,
  itemToEdit,
}) => {
  const [createUser] = useMutation(
    itemToEdit
      ? !itemToEdit.businessRelationId
        ? ADMIN_CREATE_BUSINESS_USER_BUSINESS
        : ADMIN_UPDATE_BUSINESS_USER_BUSINESS
      : ADMIN_CREATE_BUSINESS_USER_BUSINESS,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required')
      .matches(REGEX.numbersAndCharacters, 'Only letters and numbers allowed')
      .max(30, 'First name should not exceed 30 characters'),
    lastName: Yup.string()
      .required('Last name is required')
      .matches(REGEX.numbersAndCharacters, 'Only letters and numbers allowed')
      .max(30, 'Last name should not exceed 30 characters'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string()
      .test('empty', 'Phone is required', (val) => !!val)
      .test(
        'startsWith',
        'Phone should start with +1',
        (val) => !!val?.startsWith('+1'),
      )
      .test(
        'len',
        'Length should be 12 characters',
        (val) => val?.length === 12,
      ),
    locations: Yup.array().min(1),
    businessRole: Yup.string().required(),
    active: Yup.boolean(),
  });

  const { id } = useParams<{ id: string }>();

  const { data, loading } = useQuery(GET_ADMIN_LOCATION, {
    variables: {
      businessId: id,
      getAdminLocationsDto: {
        searchBy: '',
        sortBy: sortItems.BY_NAME_A_TO_Z,
        skip: 0,
        limit: 10000,
      },
    },
    fetchPolicy: 'network-only',
  });

  const initialValues: users = {
    firstName: itemToEdit && itemToEdit.firstName ? itemToEdit.firstName : '',
    phone: itemToEdit && itemToEdit.phone ? itemToEdit.phone : '+1',
    lastName: itemToEdit && itemToEdit.lastName ? itemToEdit.lastName : '',
    email: itemToEdit && itemToEdit.email ? itemToEdit.email : '',
    businessRole:
      itemToEdit && itemToEdit.businessRole ? itemToEdit.businessRole : 'ADMIN',
    locations: itemToEdit && itemToEdit.locations ? itemToEdit.locations : [],
    active: itemToEdit && 'active' in itemToEdit ? itemToEdit.active : true,
  };

  const options = {
    style: {
      textAlign: 'center',
    },
  };

  const [openSnackbar] = useSnackbar(options);

  const createNewUser = async (data: users) => {
    setIsLoading(true);
    try {
      if (itemToEdit && itemToEdit.businessRelationId) {
        await createUser({
          variables: {
            businessRelationId: itemToEdit.businessRelationId,
            userId: itemToEdit.userId,
            adminUpdateBusinessUser: {
              ...data,
              businessId: id,
            },
          },
        });
      } else {
        await createUser({
          variables: {
            businessId: id,
            adminCreateBusinessUser: {
              ...data,
              businessId: id,
            },
          },
        });
      }
      proxy.publish('get_users');
      onClose();
    } catch (e: any) {
      console.error(e);
      const errors = extractGraphQLErrors(e);

      if (errors.businessRole) {
        openSnackbar('Business role already exists');
        setIsLoading(false);
        return;
      }

      if (errors.phone) {
        openSnackbar('Phone number is already in use');
        setIsLoading(false);
        return;
      }

      if (errors.email) {
        openSnackbar('Email is already in use');
        setIsLoading(false);
        return;
      }
      openSnackbar(e.message);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const renderCapitalizedRole = (role: string) => {
    switch (role) {
      case 'ADMIN':
        return 'Admin';
      case 'RECRUITER':
        return 'Recruiter';
      case 'MANAGER':
        return 'Manager';
      default:
        return '';
    }
  };

  return (
    <div className="add-business">
      {loading ? (
        <div className="form-skeleton">
          <div className="ssc-square small-item mb-2"></div>
          <div className="ssc-square small-item mb-2"></div>
          <div className="ssc-square small-item mb-2"></div>
          <div className="ssc-square small-item mb-2"></div>
          <div className="ssc-square small-item mb-2"></div>
          <div className="ssc-square small-item mb-2"></div>
          <div className="ssc-square small-item mb-2"></div>
        </div>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={SignupSchema}
          onSubmit={(values: users) => {
            const valCopy = { ...values };
            createNewUser(valCopy);
          }}>
          {({ errors, touched, dirty, isValid }) => (
            <Form>
              <div className="flex justify-between mb-4">
                <h5>
                  {itemToEdit
                    ? itemToEdit.businessRelationId
                      ? 'Edit User'
                      : 'Add Role'
                    : 'Add User'}
                </h5>
                <div className="flex align-center">
                  <p className="m-0">Active User</p>
                  <div className="flex align-center styled-switcher">
                    <Field
                      disabled={itemToEdit && !itemToEdit.businessRelationId}
                      type="checkbox"
                      id="switch"
                      name="active"
                      className="input"
                    />
                    <label
                      htmlFor="switch"
                      className={`checkbox-label ${
                        itemToEdit &&
                        !itemToEdit.businessRelationId &&
                        'disabled'
                      }`}>
                      Toggle
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-between mb-3">
                <div className="input-wrap w-48">
                  <label className="input-label">FIRST NAME</label>
                  {isLoading ? (
                    <div className="ssc-square skeleton-item"></div>
                  ) : (
                    <Field
                      disabled={itemToEdit && !itemToEdit.businessRelationId}
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      className="input"
                    />
                  )}
                  {errors.firstName && touched.firstName ? (
                    <div className="error">{errors.firstName}</div>
                  ) : null}
                </div>
                <div className="input-wrap w-48">
                  <label className="input-label">LAST NAME</label>
                  {isLoading ? (
                    <div className="ssc-square skeleton-item"></div>
                  ) : (
                    <Field
                      disabled={itemToEdit && !itemToEdit.businessRelationId}
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      className="input"
                    />
                  )}
                  {errors.lastName && touched.lastName ? (
                    <div className="error">{errors.lastName}</div>
                  ) : null}
                </div>
              </div>
              <div className="input-wrap mb-3">
                <label>EMAIL</label>
                {isLoading ? (
                  <div className="ssc-square skeleton-item"></div>
                ) : (
                  <Field
                    disabled={itemToEdit && !itemToEdit.businessRelationId}
                    name="email"
                    type="email"
                    placeholder="Email"
                    className="input"
                  />
                )}

                {errors.email && touched.email ? (
                  <div className="error">{errors.email}</div>
                ) : null}
              </div>
              <div className={`input-wrap mb-3 ${!isLoading && 'phone-wrap'}`}>
                <label className="input-label">PHONE NUMBER</label>
                {isLoading ? (
                  <div className="ssc-square skeleton-item"></div>
                ) : (
                  <Field
                    disabled={itemToEdit && !itemToEdit.businessRelationId}
                    name="phone"
                    type="text"
                    placeholder="+11111111111"
                    className="input"
                  />
                )}
                {errors.phone && touched.phone ? (
                  <div className="error">{errors.phone}</div>
                ) : null}
              </div>
              <div className="input-wrap mb-3">
                <label className="input-label">LOCATIONS</label>
                {loading || isLoading ? (
                  <div className="ssc-square skeleton-item"></div>
                ) : (
                  <Field
                    name="locations"
                    options={data.getAdminLocations.collection}
                    component={CustomSelect}
                    placeholder="Select locations..."
                    isMulti={true}
                  />
                )}
              </div>
              <div className="input-wrap mb-3">
                <label className="input-label">USER PERMISSIONS</label>
                {isLoading ? (
                  <div className="ssc-square skeleton-item"></div>
                ) : (
                  <Field as="select" name="businessRole" className="select">
                    {ROLES_LIST.map((el, index) => {
                      return (
                        <option key={index} value={el}>
                          {renderCapitalizedRole(el)}
                        </option>
                      );
                    })}
                  </Field>
                )}
              </div>
              <div className="half-wrap flex justify-content-between">
                <button
                  disabled={isLoading || !(dirty && isValid)}
                  type="submit"
                  className="btn btn--main w-48">
                  {itemToEdit
                    ? itemToEdit.businessRelationId
                      ? 'Edit User'
                      : 'Add Role'
                    : 'Add User'}
                </button>
                <button
                  className="btn btn--secondary w-48"
                  type="button"
                  onClick={onClose}>
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default AddBusinessUser;
