// @ts-ignore
import { default as edit } from '../../assets/img/edit-ic.svg';
// @ts-ignore
import { default as logOut } from '../../assets/img/log-out-ic.svg';
// @ts-ignore
import { ReactComponent as Dots } from '../../assets/img/dots.svg';
import { useState, useRef, useEffect } from 'react';
import firebase from 'firebase';
import { observer } from 'mobx-react';
import { userStore } from '../../store';
import Popup from 'reactjs-popup';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
// @ts-ignore
import { default as CrossIcon } from '../../assets/img/cross.svg';

const Profile: React.FC = () => {
  const popupRef = useRef<any>();
  const { setAuthorization, logOutUser, loggedInUser, userRole } = userStore;
  const [isOpen, setOpen] = useState<boolean>(false);
  const [popupType, setPopupType] = useState<string>('privacy');
  const ref = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<any>(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (!event.target.classList.contains('pop')) {
      setOpen(false);
    }
  };

  const openPopup = (type: string) => {
    setPopupType(type);
    popupRef.current.open();
  };

  const onPopupOpen = () => {
    setOpen(!isOpen);
  };

  const onLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        logOutUser();
        setAuthorization(false);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const onOpen = () => {
    scrollRef.current.scrollIntoView(false);
  };

  return (
    <div className="profile-wrap">
      <div className="popup-wrap">
        <Popup
          closeOnDocumentClick={false}
          trigger={(open) => (
            <div>
              {open && (
                <button className="button">
                  <img src={CrossIcon} alt="close" />
                </button>
              )}
            </div>
          )}
          ref={popupRef}
          onOpen={onOpen}
          className="conditions"
          modal
          nested>
          <span ref={scrollRef}></span>
          {popupType === 'privacy' ? <PrivacyPolicy /> : <TermsAndConditions />}
        </Popup>
      </div>
      <div className="profile-info">
        <div className="flex">
          <div className="image-wrap mr-3"></div>
          <div>
            <p className="profile-info--name">
              {loggedInUser.firstName} {loggedInUser.lastName}
            </p>
            <p className="profile-info--role">
              {userRole === 'super' ? 'Administrator' : userRole}
            </p>
          </div>
        </div>
        <span onClick={onPopupOpen} className="dots pop">
          <Dots className="pop" />
          <div ref={ref}>
            {isOpen && (
              <span className="popup">
                <ul>
                  <li className="flex pop">
                    <img src={edit} alt="edit" />
                    Edit Profile
                  </li>
                  <li onClick={onLogout} className="pop" data-logout="logout">
                    <img src={logOut} alt="logout" />
                    Log Out
                  </li>
                </ul>
              </span>
            )}
          </div>
        </span>
      </div>
      <div className="profile-bottom">
        <p className="pr-1" onClick={() => openPopup('privacy')}>
          Privacy Policy
        </p>
        <p onClick={() => openPopup('terms')}>Terms of Sevice</p>
      </div>
      <p className="terms">
        © {new Date().getFullYear()} Seekr Inc. All rights reserved
      </p>
    </div>
  );
};

export default observer(Profile);
