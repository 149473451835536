import React, { useState, useRef } from 'react';
import { FileUploader } from 'react-drag-drop-files';
// @ts-ignore
import { default as Placeholder } from '../../../assets/img/image-placeholder.svg';
// @ts-ignore
import { default as Cross } from '../../../assets/img/cross.svg';
// @ts-ignore
import { useSnackbar } from 'react-simple-snackbar';

const fileTypes = ['JPG', 'PNG', 'GIF', 'JPEG'];

interface DropZoneProps {
  onSelect: (file: File | null) => void;
  name: string;
  selected: Selected | null;
  onRemoveFromAPI: (data: Selected) => void;
}

type Selected = {
  url: string;
  _id: string;
};

const DropZone: React.FC<DropZoneProps> = ({
  onSelect,
  name,
  selected,
  onRemoveFromAPI,
}) => {
  const wrapperRef = useRef<any>(null);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [file, setFile] = useState<object | null>(null);

  const options = {
    style: {
      textAlign: 'center',
    },
  };

  const [openSnackbar] = useSnackbar(options);

  const removeImage = (e: any) => {
    if (selected && selected.url) {
      onRemoveFromAPI(selected);
    }
    const current = wrapperRef.current;
    if (current) {
      current.children[0].children[0].value = '';
    }

    setFile(null);
    setImageUrl('');
    onSelect(null);
  };

  const handleChange = (file: File) => {
    setFile(file);
    const url = URL.createObjectURL(file);
    setImageUrl(url);
    onSelect(file);
  };

  const onError = (file: File) => {
    openSnackbar(file);
  };

  return (
    <div>
      <div
        className={`file-uploader ${!file ? 'is-empty' : 'image'}`}
        ref={wrapperRef}>
        <FileUploader
          fileOrFiles={file}
          handleChange={handleChange}
          maxSize={3}
          id={name}
          onSizeError={onError}
          name={name}
          disabled={file || (selected && selected.url)}
          multiple={false}
          types={fileTypes}>
          {(selected && selected.url) || imageUrl.length ? (
            <div className="image-wrap">
              <div className="layout">
                <img
                  src={Cross}
                  alt="cross"
                  className="remove"
                  onClick={(e) => removeImage(e)}
                />
              </div>
              <img
                src={selected && selected.url ? selected.url : imageUrl}
                alt="preview"
              />
            </div>
          ) : (
            <div className="file-uploader--inner">
              <div className="inner-wrap">
                <img src={Placeholder} className="mb-2" alt="placeholder" />
                <p className="top m-0 mb-1">Drop your image here or</p>
                <p className="bottom m-0">click to browse</p>
              </div>
            </div>
          )}
        </FileUploader>
      </div>
    </div>
  );
};

export default DropZone;
