import React from 'react';
import { observer } from 'mobx-react-lite';
import { jobPostStore } from '../../../store';

type DataObject = {
  [key: string]: any;
};

interface PreviewProps {
  selectedData: DataObject;
}

const Preview: React.FC<PreviewProps> = ({ selectedData }) => {
  const { imagesUrls } = jobPostStore;

  const topTitle = () => {
    if (
      selectedData.location &&
      selectedData.availability &&
      selectedData.jobType &&
      selectedData.advantages
    ) {
      return (
        <span>
          {selectedData.location.name +
            ' ' +
            'is looking for a' +
            ' ' +
            selectedData.availability.name +
            ' ' +
            selectedData.jobType.name +
            ' ' +
            'that is' +
            ' ' +
            advantages(selectedData.advantages) +
            '.'}
        </span>
      );
    }
  };

  const advantages = (arr: [{ name: string; _id: string }]) => {
    return arr
      .reduce((acc, { name }, index) => {
        if (index === 0) {
          return name;
        }

        if (index === arr.length - 1) {
          return [acc, 'and', name].join(' ');
        }

        return acc + ', ' + name;
      }, '')
      .toLowerCase();
  };

  const renderRateRangeFrom = () => {
    if (selectedData && selectedData.rateRangeFrom) {
      return selectedData.rateRangeFrom;
    } else if (selectedData && selectedData.rateRange) {
      return selectedData.rateRange.from;
    } else {
      return false;
    }
  };

  const renderRateRangeTo = () => {
    if (selectedData && selectedData.rateRangeTo) {
      return selectedData.rateRangeTo;
    } else if (selectedData && selectedData.rateRange) {
      return selectedData.rateRange.to;
    } else {
      return false;
    }
  };

  return (
    <div className="preview">
      <div
        className={`preview__main ${
          selectedData.backgroundColor ?? selectedData.backgroundColor
        }`}>
        <h4 className="mb-3">{topTitle()}</h4>
        <div
          className="preview__image-block"
          style={{
            backgroundImage: `url(${imagesUrls ? imagesUrls[0] : null})`,
            backgroundSize: 'cover',
          }}>
          {selectedData && selectedData.jobType && (
            <div className="label label--dark mr-1 mb-1">
              {selectedData.jobType.name}
            </div>
          )}
          {selectedData && selectedData.availability && (
            <div className="label label--dark mr-1 mb-1">
              {selectedData.availability.name}
            </div>
          )}
          {renderRateRangeFrom() && renderRateRangeTo() ? (
            <div className="label label--dark mr-1 mb-1 mt-2">
              {`$${renderRateRangeFrom()}-$${renderRateRangeTo()} ${
                selectedData.additionalCompensation
                  ? '+' +
                    ' ' +
                    selectedData.additionalCompensation.toLowerCase()
                  : ''
              }`}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="card mb-2 mt-4 mb-4">
        <div className="card__head">
          <p className="m-0">About the position</p>
        </div>
        <div className="card__content">
          {selectedData.about ? selectedData.about : null}
        </div>
      </div>
      {imagesUrls && imagesUrls[1] && (
        <div
          className="preview__image-block mt-3 mb-3"
          style={{
            backgroundImage: `url(${imagesUrls && imagesUrls[1]})`,
            backgroundSize: 'cover',
            height: '400px',
          }}></div>
      )}
      <div className="card mb-4">
        <div className="card__head">
          <p className="m-0">Necessary</p>
        </div>
        <div className="card__content card__content--border-bottom">
          {selectedData.necessary &&
            selectedData.necessary.map(
              (item: { _id: string; name: string }) => {
                return (
                  <div key={item._id} className="label label--light mr-1 mb-3">
                    {item.name}
                  </div>
                );
              },
            )}
        </div>
        <div className="card__head">
          <p className="m-0">Preferred</p>
        </div>
        <div className="card__content">
          {selectedData.preferred &&
            selectedData.preferred.map(
              (item: { _id: string; name: string }) => {
                return (
                  <div key={item._id} className="label label--light mr-1 mb-3">
                    {item.name}
                  </div>
                );
              },
            )}
        </div>
      </div>
      {/*<div className="card mb-4">
        <div className="card__head">
          <p className="m-0">Salary & Benefits</p>
        </div>
        <div className="card__subheadline">
          <p className="m-0 pb-2 pt-2">What is the salary for this position?</p>
        </div>
        <div className="card__content card__content--secondary">
          {selectedData.compensation ? (
            <div>
              <p className="m-0 pb-2 pt-2">
                ${Object.values(selectedData.compensation)[0]} /{' '}
                {Object.keys(selectedData.compensation)[0]}
              </p>
            </div>
          ) : null}
        </div>
        <div className="card__subheadline">
          <p className="m-0 pb-2 pt-2">
            What is the benefits for this position?
          </p>
        </div>
        <div className="card__content">
          {selectedData.roles &&
            selectedData.roles.map((item) => {
              return (
                <div key={item} className="label label--light mr-1 mb-3">
                  {item}
                </div>
              );
            })}
        </div>
      </div>*/}
      {imagesUrls && imagesUrls[2] && (
        <div
          className="preview__image-block mt-3 mb-3"
          style={{
            backgroundImage: `url(${imagesUrls && imagesUrls[2]})`,
            backgroundSize: 'cover',
            height: '200px',
          }}></div>
      )}
      <div className="card mb-4">
        <div className="card__head">
          <p className="m-0">Location</p>
        </div>
        <div className="card__content card__content--light">
          {selectedData.location ? selectedData.location.name : null}
        </div>
      </div>
      <div className="card mb-4">
        <div className="card__head">
          <p className="m-0">Job Description</p>
        </div>
        <div className="card__content card__content--light">
          {selectedData.description ? (
            <div
              dangerouslySetInnerHTML={{ __html: selectedData.description }}
            />
          ) : null}
        </div>
      </div>
      {imagesUrls && imagesUrls[3] && (
        <div
          className="preview__image-block mt-3 mb-3"
          style={{
            backgroundImage: `url(${imagesUrls && imagesUrls[3]})`,
            backgroundSize: 'cover',
            height: '400px',
          }}></div>
      )}
    </div>
  );
};

export default observer(Preview);
