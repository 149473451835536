import { action, makeObservable, observable } from 'mobx';

class TestStore {
  constructor() {
    makeObservable(this, {
      firstName: observable,
      lastName: observable,
      resetName: action.bound,
    });
  }

  firstName: string = 'Lorem';
  lastName: string = 'Ipsum';

  resetName() {
    this.firstName = '';
  }

  get userFirstName() {
    return this.firstName;
  }

  get userLastName() {
    return this.lastName;
  }
}

const testStore = new TestStore();

export default testStore;
