import React from 'react';
// @ts-ignore
import { ReactComponent as Arrow } from '../../assets/img/arrow.svg';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
// @ts-ignore
import { default as CrossIcon } from '../../assets/img/cross.svg';
import RemovePopup from './RemovePopup';
import { useHistory } from 'react-router-dom';
import { business } from '../../types/business';
import { useMutation } from '@apollo/client';
import { ADMIN_DELETE_BUSINESS } from '../../apollo/mutations';
import { useRef, useState } from 'react';
import { userStore } from '../../store';
import { observer } from 'mobx-react-lite';

interface CardBusinessProps {
  data: business;
  onSuccess: () => void;
  onEdit: (item: business) => void;
}

const CardBusiness: React.FC<CardBusinessProps> = ({
  data,
  onSuccess,
  onEdit,
}) => {
  const { loggedInUser, userRole } = userStore;
  const ref = useRef<any>();
  const [removeBusiness] = useMutation(ADMIN_DELETE_BUSINESS);
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleClick = () => {
    ref.current.open();
  };

  const history = useHistory();

  const redirect = () => {
    history.push(`/manage-businesses/${data._id}`);
  };

  const onRemove = async () => {
    setLoading(true);
    try {
      await removeBusiness({
        variables: {
          businessId: data._id,
        },
      });
      onSuccess();
      ref.current.close();
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className="card-long card-business">
      <div
        className="card-long__item name flex align-center"
        onClick={redirect}>
        <p className="name">{data.name}</p>
        <Arrow />
      </div>
      <div className="card-long__item address">
        <p>{data['address1']}</p>
      </div>
      <div className="card-long__item email">
        <p>{loggedInUser.email}</p>
      </div>
      <div className="card-long__item phone">
        <p>
          {typeof data.phone === 'string'
            ? data.phone.includes('+1')
              ? data.phone
              : null
            : null}
        </p>
      </div>
      <div className="card-long__item action">
        {(userRole === 'super' || userRole === 'admin') && (
          <button
            className="action-button edit mr-1"
            onClick={() => onEdit(data)}></button>
        )}
        <div className="remove">
          {userRole === 'super' && (
            <button
              className="action-button remove"
              onClick={handleClick}></button>
          )}
        </div>
        <Popup
          closeOnDocumentClick={false}
          ref={ref}
          className="business"
          modal
          nested>
          {(close: any) => (
            <div className="modal business-modal">
              <img
                onClick={close}
                src={CrossIcon}
                alt="close"
                className="cross-icon"
              />
              <RemovePopup
                onClose={close}
                loading={isLoading}
                onRemoveItem={onRemove}
                itemToRemove={data.name || ''}
                type="business"
              />
            </div>
          )}
        </Popup>
      </div>
    </div>
  );
};

export default observer(CardBusiness);
