/**
 * Extracts infos about fields errors and formats for formik callback
 */

import get from 'lodash/get';

type ErrorItem = {
  field: string;
  data?: Array<ErrorItem>;
  errors?: Array<string>;
  type?: string;
};

type ResultItem = {
  [key: string]: string | ResultItem;
};

const extract = (data: Array<ErrorItem>): ResultItem => {
  return data.reduce(
    (result: ResultItem, { field, data, errors = [''] }: ErrorItem) => {
      let errorMessage: string = '';

      if (!data) {
        errorMessage = `error.${field}.${errors[0]}`;
      }

      return {
        ...result,
        [field]: data ? extract(data) : errorMessage,
      };
    },
    {},
  );
};

export default function extractGraphQLErrors(error: unknown) {
  return extract(get(error, 'graphQLErrors.0.extensions') || []);
}
