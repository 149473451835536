import Dropdown from '../../Shared/Dropdown';
// @ts-ignore
import { default as Passed } from '../../../assets/img/passed-icon.svg';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { jobPostStore } from '../../../store';

interface SelectOwnerProps {
  updateData: (dataType: string, data: Item) => void;
  selected: {
    _id: string;
    firstName: string;
    lastName: string;
  };
}

type Item = {
  _id: string;
  name: string;
};

const SelectOwner: React.FC<SelectOwnerProps> = ({ updateData, selected }) => {
  const { transformedUsers } = jobPostStore;

  const onSelect = (data: Item) => {
    updateData('owner', data as Item);
  };

  return (
    <div className="job-post--item">
      <div className="flex align-center justify-content-between head">
        <div className="flex align-center">
          {selected ? (
            <img src={Passed} alt="success" />
          ) : (
            <p className="number">12</p>
          )}
          <p className="headline">Select owner*</p>
        </div>
      </div>
      <div className="mt-3">
        <Dropdown
          up={true}
          onSelect={onSelect}
          data={transformedUsers as Item[]}
          selected={
            selected ? `${selected.firstName} ${selected.lastName}` : ''
          }
          placeholder="Select owner"
        />
      </div>
    </div>
  );
};

export default observer(SelectOwner);
