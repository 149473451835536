// @ts-ignore
import { default as Calendar } from '../../assets/img/calendar.svg';
// @ts-ignore
import { default as IconDown } from '../../assets/img/icon-down.svg';
// @ts-ignore
import { default as IconUp } from '../../assets/img/icon-up.svg';
import { useEffect, useRef } from 'react';
import { sortItems } from '../../types/sortPopup';

interface SortPopupProps {
  onClose: () => void;
  onSelect: (type: sortItems) => void;
}

const SortPopup: React.FC<SortPopupProps> = ({ onClose, onSelect }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onClose();
    }
  };

  return (
    <div className="sort-popup" ref={wrapperRef}>
      <ul className="sort-popup--list">
        <li
          className="flex align-center"
          onClick={() => onSelect(sortItems.BY_NAME_A_TO_Z)}>
          <img src={IconDown} alt="icon" />
          <span>By Name (A to Z)</span>
        </li>
        <li
          className="flex align-center"
          onClick={() => onSelect(sortItems.BY_NAME_Z_TO_A)}>
          <img src={IconUp} alt="icon" />
          <span>By Name (Z to A)</span>
        </li>
        <li
          className="flex align-center"
          onClick={() => onSelect(sortItems.BY_LAST_ADDED_DATE)}>
          <img src={Calendar} alt="icon" />
          <span>By Date (last added)</span>
        </li>
      </ul>
    </div>
  );
};

export default SortPopup;
