import React, { useEffect } from 'react';
import { Router, useHistory } from 'react-router-dom';
import { userStore } from './store';
import { observer } from 'mobx-react-lite';
import apolloClient from '../src/apollo/client';
import AuthorizedLayout from './components/Layout/AuthorizedLayout';
import UnAuthorizedLayout from './components/Layout/UnAuthorizedLayout';
import firebase from 'firebase';
import { demoFirebaseConfig, prodFirebaseConfig } from './config';
import Auth from './tools/Auth';
import { useState } from 'react';
import Skeleton from './components/Skeleton/Skeleton';

const App: React.FC = () => {
  const [isAppLoaded, setIsAppLoaded] = useState<boolean>(false);
  const { isAuthorized, isLoading } = userStore;
  const newHistory = useHistory();

  useEffect(() => {
    if (!firebase.apps.length) {
      switch (process.env.REACT_APP_ENV) {
        case 'demo':
        case 'stage':
          firebase.initializeApp({ ...demoFirebaseConfig });
          break;
        case 'preprod':
        case 'prod':
          firebase.initializeApp({ ...prodFirebaseConfig });
          break;
        default:
          firebase.initializeApp({ ...demoFirebaseConfig });
      }
    }
    Auth.initialize(apolloClient);
    setUser();
    setIsAppLoaded(true);
  }, []);

  const setUser = async () => {
    try {
      await Auth.fetchProfileToUserStore();
    } catch (e) {
      setIsAppLoaded(true);
    }
    setIsAppLoaded(true);
  };

  return (
    <div>
      {!isAppLoaded || isLoading ? (
        <Skeleton />
      ) : (
        <Router history={newHistory}>
          {isAuthorized ? <AuthorizedLayout /> : <UnAuthorizedLayout />}
        </Router>
      )}
    </div>
  );
};

export default observer(App);
