import React, { ReactElement } from 'react';
import { userStore } from '../../store';
import { Roles } from '../../types/roles';

const Permission = ({ roles, children }: { roles: (keyof typeof Roles)[], children: ReactElement }) => {
  const { userRole } = userStore;

  return roles.includes(userRole) ? children : null;
};

export default Permission;
