const BusinessesSkeleton = () => {
  return (
    <div>
      <div className="ssc-square business-item__skeleton"></div>
      <div className="ssc-square business-item__skeleton mt-2"></div>
      <div className="ssc-square business-item__skeleton mt-2"></div>
      <div className="ssc-square business-item__skeleton mt-2"></div>
      <div className="ssc-square business-item__skeleton mt-2"></div>
      <div className="ssc-square business-item__skeleton mt-2"></div>
      <div className="ssc-square business-item__skeleton mt-2"></div>
      <div className="ssc-square business-item__skeleton mt-2"></div>
      <div className="ssc-square business-item__skeleton mt-2"></div>
      <div className="ssc-square business-item__skeleton mt-2"></div>
    </div>
  );
};

export default BusinessesSkeleton;
