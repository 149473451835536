import React, { useEffect, useState } from 'react';
import CardLocation from '../../components/Shared/CardLocation';
// @ts-ignore
import { ReactComponent as ArrowBottom } from '../../assets/img/arrow-bottom.svg';
import SortPopup from '../../components/Shared/SortPopup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_ADMIN_LOCATION } from '../../apollo/queries';
import { sortItems } from '../../types/sortPopup';
import { location as locations } from '../../types/locations';
import BusinessesSkeleton from '../ManageBusiness/BusinessesSkeleton';
import ReactPaginate from 'react-paginate';
import qs from 'query-string';
import { observer } from 'mobx-react';
import { businessStore } from '../../store';

interface LocationProps {
  getLocations: boolean;
  onEditLocation: (data: locations) => void;
}

type searchObj = {
  searchBy: string;
  sortBy: sortItems;
  skip: number;
  limit: number;
};

const LIMIT = 10;

const Locations: React.FC<LocationProps> = ({
  getLocations,
  onEditLocation,
}) => {
  const [searchBy, setSearchBy] = useState<string>('');
  const [sortPopupOpen, toggleSortPopup] = useState<boolean>(false);
  const [pagesCount, updatePagesCount] = useState<number>(0);
  const [currentQueryPage, setCurrentQueryPage] = useState<number>(0);
  const [locationList, setLocationList] = useState<[]>([]);
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
  const [searchedObject, setSearchObj] = useState<searchObj>({
    searchBy: '',
    sortBy: sortItems.BY_LAST_ADDED_DATE,
    skip: 0,
    limit: LIMIT,
  });

  const { setLocationLength } = businessStore;

  const { search } = useLocation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  useEffect(() => {
    setIsPageLoaded(true);
  }, []);

  const [getBusinessLocation, { data, loading }] = useLazyQuery(
    GET_ADMIN_LOCATION,
    {
      variables: {
        businessId: id,
        getAdminLocationsDto: {
          ...searchedObject,
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (data) {
      const count = Math.ceil(data.getAdminLocations.count / LIMIT);
      updatePagesCount(count);
      setLocationList(data.getAdminLocations.collection);
      setLocationLength(!!data.getAdminLocations.collection.length);
    }
  }, [data]);

  useEffect(() => {
    getBusinessLocation();
  }, [getLocations]);

  useEffect(() => {
    getBusinessLocation();
    const queryParams = existingQuery();
    if ('searchBy' in queryParams) {
      setSearchBy(queryParams.searchBy as string);
    }
  }, []);

  useEffect(() => {
    const query = existingQuery();
    if ('page' in query) {
      const offset = (Number(query.page) - 1) * LIMIT;
      setCurrentQueryPage(Number(query.page) - 1);
      const obj = {
        limit: LIMIT,
        skip: offset,
      };
      setSearchObj((prevState): searchObj => {
        return { ...prevState, ...obj };
      });
    }
    // @ts-ignore
    if ('searchBy' in query && query.searchBy.length) {
      setSearchObj((prevState): searchObj => {
        return {
          ...prevState,
          limit: 10,
          skip: 0,
        };
      });
      setCurrentQueryPage(0);
    }
    setSearchObj((prevState): searchObj => {
      const newQuery = { ...query };
      if ('page' in query) {
        delete newQuery.page;
      }
      return { ...prevState, ...newQuery };
    });
  }, [search]);

  const onPopupToggle = () => {
    toggleSortPopup(!sortPopupOpen);
  };

  const onPopupClose = () => {
    toggleSortPopup(false);
  };

  const onSelect = (type: string) => {
    const queryParams = existingQuery();
    const newQueries = { ...queryParams, sortBy: type };
    history.push({ search: qs.stringify(newQueries) });
    toggleSortPopup(false);
  };

  const existingQuery = () => {
    // eslint-disable-next-line no-restricted-globals
    return qs.parse(location.search);
  };

  const setSortText = () => {
    const queryObject = existingQuery();
    if ('sortBy' in queryObject) {
      switch (queryObject.sortBy) {
        case sortItems.BY_NAME_A_TO_Z:
          return 'Name (A to Z)';
        case sortItems.BY_NAME_Z_TO_A:
          return 'Name (Z to A)';
        case sortItems.BY_LAST_ADDED_DATE:
          return 'By last added date';
      }
    }
    return 'By last added date';
  };

  const onEditItem = ({ ...itemToEdit }: locations) => {
    onEditLocation(itemToEdit);
  };

  const getBusinessOnAdd = () => {
    getBusinessLocation();
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    if (!isPageLoaded) return;
    const queryParams = existingQuery();
    const newQueries = { ...queryParams, page: selectedItem.selected + 1 };
    history.push({ search: qs.stringify(newQueries) });
  };

  return (
    <div className="business location">
      {locationList.length ? (
        <div className="label-holder flex align-center">
          <span className="item" onClick={onPopupToggle}>
            {setSortText()}
          </span>
          <ArrowBottom
            className={sortPopupOpen ? 'up' : 'down'}
            onClick={onPopupToggle}
          />
          {sortPopupOpen && (
            <SortPopup onClose={onPopupClose} onSelect={onSelect} />
          )}
        </div>
      ) : null}
      <div className="location-container">
        <div>
          {loading ? (
            <BusinessesSkeleton />
          ) : (
            locationList.map((item: locations, index: number) => {
              return (
                <div
                  key={index}
                  className={`${index > 0 ? 'mt-2' : ''} ${
                    index === 0 ? 'first' : ''
                  }`}>
                  <CardLocation
                    onEdit={onEditItem}
                    data={item}
                    onSuccess={getBusinessOnAdd}
                  />
                </div>
              );
            })
          )}
        </div>
        <div>
          {pagesCount > 1 && (
            <div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pagesCount}
                initialPage={currentQueryPage}
                disableInitialCallback={true}
                previousLabel="<"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(Locations);
