import React, { useState, useEffect } from 'react';
import JobPosition from './components/JobPosition';
import JobType from './components/JobType';
import CompensationType from './components/CompensationType';
import RateOfExperience from './components/RateOfExperience';
import RoleType from './components/RoleType';
import NecessaryRole from './components/NecessaryRole';
import PreferredRole from './components/PreferredRole';
import AboutPosition from './components/AboutPosition';
import AddPhotos from './components/AddPhotos';
import AddLocation from './components/AddLocation';
import Preview from './components/Preview';
import SelectBackgroundColor from './components/SelectBackgroundColor';
import SelectOwner from './components/SelectOwner';
// @ts-ignore
import { useSnackbar } from 'react-simple-snackbar';
import { axios } from '../../utils/axios';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { userStore } from '../../store';

interface AddJobPostsProps {
  onClose: () => void;
  itemToEdit?: DataObject;
}

type DataObject = {
  [key: string]: any;
};

type Item = {
  _id: string;
  name: string;
};

// TODO refactor update and create job post (possibly combine in single function)

const AddJobPosts: React.FC<AddJobPostsProps> = ({ onClose, itemToEdit }) => {
  const [isCreatePost, toggleCreatePost] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [itemsToEdit, setItemsToEdit] = useState<Array<string>>([]);
  const [isValidToEdit, setIsValidToEdit] = useState<boolean>(true);
  const [isValidToSubmit, setIsValidToSubmit] = useState<boolean>(false);
  const [selectedDataObj, setDataToObj] = useState<DataObject>({});

  const { updateBusiness } = userStore;

  useEffect(() => {
    if (
      itemToEdit &&
      !(
        'advantages' in selectedDataObj &&
        'experience' in selectedDataObj &&
        'necessary' in selectedDataObj &&
        'preferred' in selectedDataObj &&
        'photos' in selectedDataObj &&
        'owner' in selectedDataObj &&
        'about' in selectedDataObj &&
        'rateRangeFrom' in selectedDataObj &&
        selectedDataObj.rateRangeFrom &&
        'rateRangeTo' in selectedDataObj &&
        selectedDataObj.rateRangeTo &&
        'compensationType' in selectedDataObj
      )
    ) {
      setIsValidToEdit(false);
    } else {
      setIsValidToEdit(true);
    }
    isValidForSubmit();
  }, [selectedDataObj]);

  const isValidForSubmit = () => {
    if (
      'jobType' in selectedDataObj &&
      'availability' in selectedDataObj &&
      'compensationType' in selectedDataObj &&
      'rateRangeTo' in selectedDataObj &&
      'rateRangeFrom' in selectedDataObj &&
      'experience' in selectedDataObj &&
      'advantages' in selectedDataObj &&
      'necessary' in selectedDataObj &&
      'preferred' in selectedDataObj &&
      'about' in selectedDataObj &&
      'photos' in selectedDataObj &&
      'location' in selectedDataObj &&
      'owner' in selectedDataObj &&
      'backgroundColor' in selectedDataObj
    ) {
      setIsValidToSubmit(true);
    } else {
      setIsValidToSubmit(false);
    }
  };

  useEffect(() => {
    if (itemToEdit) {
      setDataToObj({
        ...itemToEdit,
        ...{
          rateRangeFrom: itemToEdit.rateRange?.from,
          rateRangeTo: itemToEdit.rateRange?.to,
        },
      });
    }
    document.addEventListener('click', handleClickOutsideMult, true);
    document.addEventListener('click', handleClickOutsideSingle, true);
    return () => {
      document.removeEventListener('click', handleClickOutsideMult, true);
      document.removeEventListener('click', handleClickOutsideSingle, true);
    };
  }, []);

  const handleClickOutsideSingle = (e: any) => {
    if (
      !e.target.classList.contains('dropdown__input') &&
      !e.target.classList.contains('dropdown__list') &&
      !e.target.classList.contains('dropdown__item')
    ) {
      const closeEvent = new Event('closeDrop');
      document.dispatchEvent(closeEvent);
    }
  };

  const handleClickOutsideMult = (e: any) => {
    const classArr = Array.from(e.target.classList);
    if (
      !e.target.classList.contains('styled-checkbox') &&
      !classArr.includes('label') &&
      !e.target.classList.contains('dropdown__input')
    ) {
      const closeEvent = new Event('closeMult');
      document.dispatchEvent(closeEvent);
    }
  };

  const { id } = useParams<{ id: string }>();

  const options = {
    style: {
      textAlign: 'center',
    },
  };

  const [openSnackbar] = useSnackbar(options);

  const toggleForm = () => {
    toggleCreatePost(!isCreatePost);
  };

  const appendData = (
    dataType: string,
    data: string | [string] | null | Item[] | Item,
  ) => {
    if (itemToEdit && !itemsToEdit.includes(dataType)) {
      const items = [...itemsToEdit];
      items.push(dataType);
      setItemsToEdit([...items]);
    }
    const obj = { ...selectedDataObj };
    if (dataType === 'compensationType' && 'additionalCompensation' in obj) {
      delete obj.additionalCompensation;
    }
    obj[dataType] = data as string;
    if (
      ((typeof data === 'string' || Array.isArray(data)) && !data.length) ||
      !data
    ) {
      delete obj[dataType];
    }
    setDataToObj(obj);
  };

  const onSaveJobPost = async () => {
    if (!itemToEdit) {
      await createJobPost();
    } else {
      await updateJobPost();
    }
  };

  const getIds = (array: [{ _id: string }]) => {
    return array.map((el) => el._id);
  };

  const updateJobPost = async () => {
    const objectToSend: DataObject = {};
    const formData = new FormData();
    if (!selectedDataObj.rateRangeFrom) {
      objectToSend.rateRangeFrom = selectedDataObj.rateRange.from;
    }
    if (!selectedDataObj.rateRangeTo) {
      objectToSend.rateRangeTo = selectedDataObj.rateRange.to;
    }
    delete selectedDataObj.rateRange;
    delete selectedDataObj.video;
    delete selectedDataObj.rate;
    for (const i in selectedDataObj) {
      if (i === 'necessary' || i === 'advantages' || i === 'preferred') {
        objectToSend[i] = getIds(selectedDataObj[i]);
      } else if (
        (i === 'availability' ||
          i === 'jobType' ||
          i === 'location' ||
          i === 'owner') &&
        typeof selectedDataObj[i] !== 'string'
      ) {
        objectToSend[i] = selectedDataObj[i]._id;
      } else {
        objectToSend[i] = selectedDataObj[i];
      }
    }
    for (const i in objectToSend) {
      if (i !== 'photos') {
        const data =
          typeof objectToSend[i] === 'object'
            ? JSON.stringify(objectToSend[i])
            : (objectToSend[i] as string);
        formData.append(i, data);
      } else {
        for (const image of objectToSend[i]) {
          if (!image._id) {
            formData.append('photos', image);
          }
        }
      }
    }
    const cmp = formData.get('additionalCompensation');
    if (cmp && cmp === 'null') {
      formData.delete('additionalCompensation');
    }
    try {
      setLoading(true);
      await axios.put(
        `/job-post/admin-job-posts/business/${id}/job-post/${selectedDataObj._id}`,
        formData,
      );
      openSnackbar('Job post was updated');
      updateBusiness();
      onClose();
    } catch (e: any) {
      openSnackbar(e.message);
      console.error(e);
    }
    setLoading(false);
  };

  const createJobPost = async () => {
    const formData = new FormData();

    for (const i in selectedDataObj) {
      if (i === 'necessary' || i === 'advantages' || i === 'preferred') {
        const ids = getIds(selectedDataObj[i]);
        formData.append(i, JSON.stringify(ids));
      } else if (
        (i === 'availability' ||
          i === 'jobType' ||
          i === 'location' ||
          i === 'owner') &&
        typeof selectedDataObj[i] !== 'string'
      ) {
        formData.append(i, selectedDataObj[i]._id);
      } else if (i === 'photos') {
        for (const item of selectedDataObj[i] as []) {
          formData.append('photos', item);
        }
      } else {
        const data = Array.isArray(selectedDataObj[i])
          ? JSON.stringify(selectedDataObj[i])
          : (selectedDataObj[i] as string);
        formData.append(i, data);
      }
    }
    formData.append('isSnooze', 'false');
    const cmp = formData.get('additionalCompensation');
    if (cmp && cmp === 'null') {
      formData.delete('additionalCompensation');
    }

    try {
      setLoading(true);
      await axios.post(`/job-post/admin-job-posts/business/${id}`, formData);
      openSnackbar('Job post was created');
      updateBusiness();
      onClose();
    } catch (e: any) {
      openSnackbar(e.message);
      console.error(e);
    }
    setLoading(false);
  };

  const onSelect = (data: []) => {
    const imagesObj = {
      photos: [...data],
    };
    if (itemToEdit) {
      const items = [...itemsToEdit];
      if (!items.includes('photos')) {
        items.push('photos');
        setItemsToEdit([...items]);
      }
    }
    if (!data.length) {
      const obj = { ...selectedDataObj };
      delete obj.photos;
      setDataToObj(obj);
      return;
    }
    setDataToObj((prev) => ({ ...prev, ...imagesObj }));
  };

  return (
    <div className="job-post--popup">
      <div className="flex justify-content-between align-center">
        <h5>{itemToEdit ? 'Edit' : 'Add'} Job Post</h5>
        <p className="header-label" onClick={toggleForm}>
          {isCreatePost ? 'View Preview' : 'Go back to Job Post'}
        </p>
      </div>
      <div className={isCreatePost ? 'block' : 'hidden'}>
        <div className="mb-0">
          <JobPosition
            selected={
              selectedDataObj?.jobType?.name
                ? selectedDataObj.jobType.name
                : selectedDataObj.jobType
            }
            updateData={appendData}
          />
        </div>
        <div className="mb-0">
          <JobType
            selected={
              selectedDataObj?.availability?.name
                ? selectedDataObj.availability.name
                : selectedDataObj.availability
            }
            updateData={appendData}
          />
        </div>
        <div className="mb-0">
          <CompensationType
            selected={{
              rateRangeFrom:
                selectedDataObj && selectedDataObj.rateRange
                  ? (selectedDataObj.rateRange.from as string)
                  : selectedDataObj.rateRangeFrom
                  ? selectedDataObj.rateRangeFrom
                  : '',
              rateRangeTo:
                selectedDataObj && selectedDataObj.rateRange
                  ? (selectedDataObj.rateRange.to as string)
                  : selectedDataObj.rateRangeTo
                  ? selectedDataObj.rateRangeTo
                  : '',
              compensationType: selectedDataObj.compensationType as string,
              additionalCompensation:
                selectedDataObj.additionalCompensation as string,
            }}
            updateData={appendData}
          />
        </div>
        <div className="mb-0">
          <RateOfExperience
            updateData={appendData}
            selected={selectedDataObj.experience}
          />
        </div>
        <div className="mb-0">
          <RoleType
            selected={selectedDataObj.advantages || []}
            updateData={appendData}
          />
        </div>
        <div className="mb-0">
          <NecessaryRole
            selected={selectedDataObj.necessary || []}
            updateData={appendData}
          />
        </div>
        <div className="mb-0">
          <PreferredRole
            selected={selectedDataObj.preferred || []}
            updateData={appendData}
          />
        </div>
        <div className="mb-0">
          <AboutPosition
            selected={{
              description: selectedDataObj.description,
              about: selectedDataObj.about,
            }}
            updateData={appendData}
          />
        </div>
        <div className="mb-0">
          <AddPhotos
            selected={{
              photos: selectedDataObj.photos || [],
              postId: selectedDataObj?._id,
            }}
            onPhotosSelect={onSelect}
          />
        </div>
        <div className="mb-0">
          <AddLocation
            updateData={appendData}
            selected={
              selectedDataObj && selectedDataObj.location
                ? selectedDataObj.location.name || selectedDataObj.location
                : ''
            }
          />
        </div>
        <div className="mb-0">
          <SelectBackgroundColor
            updateData={appendData}
            selected={selectedDataObj.backgroundColor}
          />
        </div>
        <SelectOwner updateData={appendData} selected={selectedDataObj.owner} />
      </div>
      <div className={isCreatePost ? 'hidden' : 'block mb-4'}>
        <Preview selectedData={selectedDataObj} />
      </div>
      <div className="half-wrap flex justify-content-between pb-4">
        {itemToEdit && (
          <button
            onClick={onSaveJobPost}
            disabled={
              !isValidToEdit ||
              isLoading ||
              Number(selectedDataObj.rateRangeFrom) >
                Number(selectedDataObj.rateRangeTo)
            }
            className="btn btn--main w-48">
            {isLoading ? 'Loading' : 'Edit Job Post'}
          </button>
        )}
        {!itemToEdit && (
          <button
            onClick={onSaveJobPost}
            disabled={
              !isValidToSubmit ||
              isLoading ||
              Number(selectedDataObj.rateRangeFrom) >
                Number(selectedDataObj.rateRangeTo)
            }
            className="btn btn--main w-48">
            {isLoading ? 'Loading' : 'Add Job Post'}
          </button>
        )}
        <button
          className="btn btn--secondary w-48"
          type="button"
          onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default observer(AddJobPosts);
