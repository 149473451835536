import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import { default as Arrow } from '../../assets/img/arrow-bottom.svg';

type Item = {
  _id: string;
  name: string;
};

interface DropDownProps {
  data: [];
  placeholder: string;
  max: number;
  selected: Array<Item>;
  onSelect: (isSelected: boolean, item: Item) => void;
}

const DropdownMultiple: React.FC<DropDownProps> = ({
  data,
  onSelect,
  selected,
  placeholder,
  max,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener('closeMult', handleClickOutside, true);
    return () => {
      document.removeEventListener('closeMult', handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = () => {
    setOpen(false);
  };

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  const selectItem = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: { _id: string; name: string },
  ) => {
    onSelect(event.target.checked, item);
  };

  const isChecked = (item: Item): boolean => {
    return Boolean(selected.some((el) => el._id === item._id));
  };

  return (
    <div
      className="dropdown-multiple"
      data-dropdwown="drop"
      onClick={(e) => e.stopPropagation()}>
      <div
        onClick={toggleOpen}
        className="flex justify-content-between dropdown__input align-center">
        <div>
          {selected.length ? (
            selected.map((sel, index) => {
              return (
                <span key={sel._id} className="selected-items">
                  {sel.name}
                  {selected.length - 1 !== index && ','}
                </span>
              );
            })
          ) : (
            <span className="placeholder">{placeholder}</span>
          )}
        </div>
        <img
          className={isOpen ? 'up' : 'down'}
          src={Arrow}
          alt="arrow"
          style={{ width: '24px' }}
        />
      </div>
      {isOpen && (
        <ul className="dropdown__list" onClick={(e) => e.stopPropagation()}>
          {data.map((item: Item) => {
            return (
              <div
                key={item._id}
                className="dropdown__item flex align-center"
                onClick={(e) => e.stopPropagation()}>
                <input
                  onClick={(e) => e.stopPropagation()}
                  className="styled-checkbox"
                  onChange={(e) => selectItem(e, item)}
                  type="checkbox"
                  id={item.name}
                  name={item.name}
                  checked={isChecked(item)}
                  disabled={selected.length === max && !isChecked(item)}
                />
                <label
                  className="label"
                  htmlFor={item.name}
                  onClick={(e) => e.stopPropagation()}>
                  {item.name}
                </label>
              </div>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default DropdownMultiple;
