import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import firebase from 'firebase';
import get from 'lodash/get';
import {
  GET_CUSTOM_TOKEN,
  GET_ADMIN_CONFIRM_TOKEN,
  GET_USER_PROFILE,
} from '../apollo/queries';
import { userStore } from '../store';

const isUnauthorizedError = (error: Error) =>
  get(error, 'graphQLErrors.0.extensions.0.errors', []).includes(
    'unauthorized',
  ) || get(error, 'graphQLErrors.0.message') === 'Unauthorized';

class Auth {
  private static client: ApolloClient<NormalizedCacheObject>;

  static async initialize(client: ApolloClient<NormalizedCacheObject>) {
    this.client = client;
  }

  static async signInWithEmailAndPassword(
    email: string,
    password: string,
    rememberMe: boolean,
  ) {
    const persistence = rememberMe
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;

    const fbResponse = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    const fbToken = await fbResponse.user?.getIdToken();

    if (fbToken) {
      await Auth.synchronizeTokenWithGraphQL();
    }
  }

  private static async getCustomToken() {
    let customToken = null;

    try {
      const result = await Auth.client.query({
        query: GET_CUSTOM_TOKEN,
        fetchPolicy: 'network-only',
      });

      customToken = get(result, 'data.getCustomToken.customToken', null);
    } catch (error: any) {
      if (!isUnauthorizedError(error)) {
        throw error;
      }
    }

    return customToken;
  }

  static async synchronizeTokenWithGraphQL() {
    const customToken = await Auth.getCustomToken();

    if (customToken) {
      const newResult = await firebase
        .auth()
        .signInWithCustomToken(customToken);

      const newIdToken = await newResult.user?.getIdToken();

      if (newIdToken) {
        await Auth.fetchProfileToUserStore();
        return true;
      }
    }

    return false;
  }

  static async fetchProfileToUserStore() {
    userStore.setLoading(true);
    try {
      const { data } = await Auth.client.query({
        query: GET_USER_PROFILE,
        fetchPolicy: 'network-only',
      });
      const { businessProfile } = data;
      userStore.setUser(businessProfile);
      userStore.setAuthorization(true);
      userStore.setLoading(false);
    } catch (e) {
      userStore.setLoading(false);
      throw e;
    }
  }

  static async getCurrentUser(): Promise<firebase.User | null> {
    return new Promise((resolve, reject) => {
      const auth = firebase.auth();

      if (auth.currentUser) {
        resolve(auth.currentUser);
      } else {
        const timeout = setTimeout(reject, 20000);
        const unsubscribe = auth.onAuthStateChanged((user) => {
          clearTimeout(timeout);
          unsubscribe();
          resolve(user);
        });
      }
    });
  }

  static async getIdToken() {
    const user = await this.getCurrentUser();

    return (await user?.getIdToken()) || '';
  }

  static async registerUser(email: string, password: string) {
    try {
      const response = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);

      const fbToken = await response.user?.getIdToken();
      if (fbToken) {
        localStorage.setItem('seekrToken', fbToken);
      }
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  static async removeUserFromFB() {
    const user = firebase.auth().currentUser;

    if (user) {
      try {
        await user.delete();
        console.info('User was successfully removed from firebase');
      } catch (e) {
        console.error(e);
      }
    }
  }

  static async resetPassword(email: string) {
    return await firebase.auth().sendPasswordResetEmail(email);
  }

  static async selectRole(id: string) {
    try {
      const { data } = await Auth.client.query({
        query: GET_ADMIN_CONFIRM_TOKEN,
        variables: {
          getConfirmTokenDto: {
            businessRelationId: id,
          },
        },
        fetchPolicy: 'network-only',
      });
      if (data.getAdminConfirmToken.confirmToken) {
        await firebase
          .auth()
          .signInWithCustomToken(data.getAdminConfirmToken.confirmToken);
      }
    } catch (e) {
      throw e;
    }
  }
}

export default Auth;
