// @ts-ignore
import { default as MagnifyIcon } from '../../assets/img/search-icon.svg';
// @ts-ignore
import { default as CloseIcon } from '../../assets/img/icons8-close.svg';
import { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';

interface SearchInputProps {
  onSubmit: (val: string) => void;
  inputValue: string;
  placeholder: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  onSubmit,
  inputValue,
  placeholder,
}) => {
  const [val, setVal] = useState<string>('');
  const handler = useCallback(
    debounce((val: string) => onSubmit(val), 1000),
    [],
  );

  const onInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    handler(event.target.value);
    setVal(event.target.value);
  };

  useEffect(() => {
    setVal(inputValue);
  }, [inputValue]);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(val);
  };

  const onIconClick = () => {
    onSubmit(val);
  };

  const onReset = () => {
    setVal('');
    onSubmit('');
  };

  return (
    <div className="search-input">
      <form onSubmit={submit}>
        <input value={val} onChange={onInput} placeholder={placeholder} />
      </form>
      {!val.length ? (
        <img
          src={MagnifyIcon}
          alt="magnify"
          className="search-icon"
          onClick={onIconClick}
        />
      ) : (
        <img
          src={CloseIcon}
          alt="magnify"
          className="close-icon"
          onClick={onReset}
        />
      )}
    </div>
  );
};

export default SearchInput;
