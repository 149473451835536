import React, { useState, useRef, useEffect } from 'react';
// @ts-ignore
import { default as Passed } from '../../../assets/img/passed-icon.svg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from '@tinymce/tinymce-react';

interface AboutProps {
  updateData: (dataType: string, data: string) => void;
  selected: { description: string; about: string };
}

const AboutPosition: React.FC<AboutProps> = ({ updateData, selected }) => {
  const editorRef = useRef<any>(null);
  const [about, setAbout] = useState<string>('');
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [initial, setInitial] = useState<string>('');
  const [value, setValue] = useState(selected.description ?? '');

  useEffect(() => setValue(selected.description ?? ''), [selected.description]);

  useEffect(() => {
    if (selected.description && !isLoaded) {
      updateData('description', selected.description);
      setInitial(selected.description);
      setIsLoaded(true);
    }
  }, [selected.description]);

  useEffect(() => {
    if (selected && selected.about) {
      setAbout(selected.about);
    }
  }, [selected]);

  const onSummaryChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAbout(event.target.value);
    updateData('about', event.target.value);
  };

  // TODO style placeholder and text
  return (
    <div className="job-post--item">
      <div className="flex align-center justify-content-between head">
        <div className="flex align-center">
          {about.length ? (
            <img src={Passed} alt="success" />
          ) : (
            <p className="number">8</p>
          )}
          <p className="headline">About this position*</p>
        </div>
      </div>
      <div className="mt-2">
        <p className="sub-headline">
          This is a brief summary of the position, not a full job description
        </p>
        <textarea
          value={about}
          onChange={onSummaryChange}
          className="textarea"
          placeholder="Your description"
        />
        <p className="sub-headline">Detailed Job Description</p>
        <div className="rich-text-editor">
          <Editor
            apiKey="h8uki79259xwpdugkn2nytj30ih49mifn2ib83fwgalryoyo"
            onInit={(evt: any, editor: any) => (editorRef.current = editor)}
            initialValue={initial}
            value={value}
            onEditorChange={(newValue) => {
              setValue(newValue);
            }}
            onChange={() => {
              updateData('description', value);
            }}
            init={{
              placeholder: 'Your description',
              height: 200,
              fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
              menubar: false,
              placeholder_attrs: {
                style: {
                  position: 'absolute',
                  top: '5px',
                  left: 0,
                  color: '#ff0000',
                  padding: '1%',
                  width: '98%',
                  overflow: 'hidden',
                  'white-space': 'pre-wrap',
                },
              },
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
                'placeholder',
              ],
              toolbar:
                'styleselect fontselect fontsizeselect | forecolor backcolor' +
                'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat',
              content_style:
                'body { font-family:Nunito,Arial,sans-serif; font-size:14px }',
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default AboutPosition;
