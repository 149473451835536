import React, { useEffect, useState } from 'react';
import UserCard from '../../../components/Shared/UserCard';
// @ts-ignore
import { ReactComponent as ArrowBottom } from '../../../assets/img/arrow-bottom.svg';
import SortPopup from '../../../components/Shared/SortPopup';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_ADMIN_ALL_BUSINESS_USERS_SYSTEM_WIDE } from '../../../apollo/queries';
import { sortItems } from '../../../types/sortPopup';
import { users } from '../../../types/users';
import BusinessesSkeleton from '../../ManageBusiness/BusinessesSkeleton';
import ReactPaginate from 'react-paginate';
import qs from 'query-string';
import { observer } from 'mobx-react';
import proxy from '../../../tools/proxy';
// @ts-ignore
import SnackbarProvider from 'react-simple-snackbar';

type searchObj = {
  searchBy: string;
  sortBy: sortItems;
  paginationDto: {
    skip: number;
    limit: number;
  };
};

interface UsersProps {
  onEditUser: (data: users) => void;
}

const LIMIT = 10;

const UsersSystem: React.FC<UsersProps> = ({ onEditUser }) => {
  const [searchBy, setSearchBy] = useState<string>('');
  const [sortPopupOpen, toggleSortPopup] = useState<boolean>(false);
  const [pagesCount, updatePagesCount] = useState<number>(0);
  const [currentQueryPage, setCurrentQueryPage] = useState<number>(0);
  const [userList, setUsersList] = useState<[]>([]);
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
  const [searchedObject, setSearchObj] = useState<searchObj>({
    searchBy: '',
    sortBy: sortItems.BY_LAST_ADDED_DATE,
    paginationDto: {
      skip: 0,
      limit: LIMIT,
    },
  });

  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    setIsPageLoaded(true);
  }, []);

  const [getBusinessUsers, { data, loading }] = useLazyQuery(
    GET_ADMIN_ALL_BUSINESS_USERS_SYSTEM_WIDE,
    {
      variables: {
        ...searchedObject,
      },
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (data) {
      const count = Math.ceil(
        data.adminGetAllBusinessUsersSystemWide.count / LIMIT,
      );
      updatePagesCount(count);
      setUsersList(data.adminGetAllBusinessUsersSystemWide.collection);
    }
  }, [data]);

  useEffect(() => {
    getBusinessUsers();
    const queryParams = existingQuery();
    if ('searchBy' in queryParams) {
      setSearchBy(queryParams.searchBy as string);
    }
    proxy.subscribe('get_users', getBusinessUsers);

    return () => {
      proxy.unsubscribe('get_users');
    };
  }, []);

  useEffect(() => {
    const query = existingQuery();
    if ('page' in query) {
      const offset = (Number(query.page) - 1) * LIMIT;
      setCurrentQueryPage(Number(query.page) - 1);
      const obj = {
        paginationDto: {
          limit: LIMIT,
          skip: offset,
        },
      };
      setSearchObj((prevState): searchObj => {
        return { ...prevState, ...obj };
      });
    }

    // @ts-ignore
    if ('searchBy' in query && query.searchBy.length) {
      setSearchObj((prevState): searchObj => {
        return {
          ...prevState,
          paginationDto: {
            limit: 10,
            skip: 0,
          },
        };
      });
      setCurrentQueryPage(0);
    }
    setSearchObj((prevState): searchObj => {
      return { ...prevState, ...query };
    });
  }, [search]);

  const onPopupToggle = () => {
    toggleSortPopup(!sortPopupOpen);
  };

  const onPopupClose = () => {
    toggleSortPopup(false);
  };

  const onSelect = (type: string) => {
    const queryParams = existingQuery();
    const newQueries = { ...queryParams, sortBy: type };
    history.push({ search: qs.stringify(newQueries) });
    toggleSortPopup(false);
  };

  const existingQuery = () => {
    // eslint-disable-next-line no-restricted-globals
    return qs.parse(location.search);
  };

  const setSortText = () => {
    const queryObject = existingQuery();
    if ('sortBy' in queryObject) {
      switch (queryObject.sortBy) {
        case sortItems.BY_NAME_A_TO_Z:
          return 'Name (A to Z)';
        case sortItems.BY_NAME_Z_TO_A:
          return 'Name (Z to A)';
        case sortItems.BY_LAST_ADDED_DATE:
          return 'By last added date';
      }
    }
    return 'By last added date';
  };

  const onEditItem = ({ ...itemToEdit }: users) => {
    onEditUser(itemToEdit);
  };

  const getBusinessOnAdd = () => {
    getBusinessUsers();
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    if (!isPageLoaded) return;
    const queryParams = existingQuery();
    const newQueries = { ...queryParams, page: selectedItem.selected + 1 };
    history.push({ search: qs.stringify(newQueries) });
  };

  return (
    <div className="business location">
      {userList.length ? (
        <div className="label-holder flex align-center">
          <span className="item" onClick={onPopupToggle}>
            {setSortText()}
          </span>
          <ArrowBottom
            className={sortPopupOpen ? 'up' : 'down'}
            onClick={onPopupToggle}
          />
          {sortPopupOpen && (
            <SortPopup onClose={onPopupClose} onSelect={onSelect} />
          )}
        </div>
      ) : null}
      <div className="location-container">
        <SnackbarProvider>
          <div>
            {loading ? (
              <BusinessesSkeleton />
            ) : (
              userList.map((item: users, index: number) => {
                return (
                  <div
                    key={item._id}
                    className={`${index > 0 ? 'mt-2' : ''} ${
                      index === 0 ? 'first' : ''
                    }`}>
                    <UserCard
                      type="system"
                      onEdit={onEditItem}
                      data={item}
                      onSuccess={getBusinessOnAdd}
                    />
                  </div>
                );
              })
            )}
          </div>
        </SnackbarProvider>
        <div>
          {pagesCount > 1 && (
            <div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pagesCount}
                initialPage={currentQueryPage}
                disableInitialCallback={true}
                previousLabel="<"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(UsersSystem);
