import { Route, Redirect, Switch } from 'react-router-dom';
import Login from '../../screens/Login/Login';
import ZipCode from '../../screens/ZipCode/ZipCode';
import Register from '../../screens/Register/Register';
import React from 'react';
import TermsAndConditionsPage from '../../screens/TermsAndConditions/TermsAndConditions';
import ForgotPassword from '../../screens/ForgotPassword/ForgotPassword';
// @ts-ignore
import SnackbarProvider from 'react-simple-snackbar';

const UnAuthorizedLayout = () => {
  return (
    <div className="layout-wrap unauthorized">
      <div className="layout-content">
        <SnackbarProvider>
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/zip-code" component={ZipCode} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route
              path="/terms-and-conditions"
              component={TermsAndConditionsPage}
            />
            <Redirect to="/login" />
          </Switch>
        </SnackbarProvider>
      </div>
    </div>
  );
};

export default UnAuthorizedLayout;
