import { gql } from '@apollo/client';

export const ADMIN_BUSINESS_REGISTRATION = gql`
  mutation adminBusinessRegistration($dto: AdminBusinessRegistrationDto!) {
    adminBusinessRegistration(adminBusinessRegistrationDto: $dto) {
      _id
    }
  }
`;

export const ADMIN_CREATE_BUSINESS_REGISTRATION = gql`
  mutation adminCreateBusiness($dto: AdminCreateBusinessDto!) {
    adminCreateBusiness(adminCreateBusinessDto: $dto) {
      _id
      name
      phone
      zip
      address1
      address2
      city
      state
      active
    }
  }
`;

export const ADMIN_DELETE_BUSINESS = gql`
  mutation adminDeleteBusiness($businessId: String!) {
    adminDeleteBusiness(businessId: $businessId) {
      status
    }
  }
`;

export const ADMIN_UPDATE_BUSINESS = gql`
  mutation adminUpdateBusiness($dto: AdminUpdateBusinessDto!) {
    adminUpdateBusiness(adminUpdateBusinessDto: $dto) {
      _id
    }
  }
`;

export const ADMIN_CREATE_LOCATION = gql`
  mutation addAdminLocation(
    $createBusinessLocationDto: CreateBusinessLocationDto!
    $businessId: String!
  ) {
    addAdminLocation(
      createBusinessLocationDto: $createBusinessLocationDto
      businessId: $businessId
    ) {
      _id
      jobPosts
      name
      phone
      address1
      address2
      city
      state
      zip
      active
    }
  }
`;

export const ADMIN_UPDATE_LOCATION = gql`
  mutation updateAdminLocation(
    $updateBusinessLocationDto: UpdateBusinessLocationDto!
    $businessId: String!
    $locationId: String!
  ) {
    updateAdminLocation(
      updateBusinessLocationDto: $updateBusinessLocationDto
      businessId: $businessId
      locationId: $locationId
    ) {
      _id
      jobPosts
      name
      phone
      address1
      address2
      city
      state
      zip
      active
    }
  }
`;

export const ADMIN_DELETE_LOCATION = gql`
  mutation deleteAdminLocation($businessId: String!, $locationId: String!) {
    deleteAdminLocation(businessId: $businessId, locationId: $locationId) {
      status
    }
  }
`;

export const ADMIN_CREATE_BUSINESS_USER_BUSINESS = gql`
  mutation adminCreateBusinessUserBusinessWide(
    $adminCreateBusinessUser: AdminCreateBusinessUserDto!
    $businessId: String!
  ) {
    adminCreateBusinessUserBusinessWide(
      adminCreateBusinessUser: $adminCreateBusinessUser
      businessId: $businessId
    ) {
      _id
      phone
      email
      type
      firstName
      lastName
      businessRelations {
        _id
        businessRole
        business {
          createdAt
          name
          phone
          zip
          address1
          address2
          city
          state
          active
          locations {
            _id
            jobPosts
            name
            phone
            address1
            address2
            city
            state
            zip
            active
          }
          photos {
            _id
            key
            name
            active
          }
          video {
            key
            name
            active
          }
          categories {
            _id
            name
            active
          }
          backgroundColor
        }
      }
    }
  }
`;

export const ADMIN_UPDATE_BUSINESS_USER_BUSINESS = gql`
  mutation adminUpdateBusinessUserSystemWide(
    $adminUpdateBusinessUser: AdminUpdateBusinessUserDto!
    $businessRelationId: String!
    $userId: String!
  ) {
    adminUpdateBusinessUserSystemWide(
      adminUpdateBusinessUser: $adminUpdateBusinessUser
      businessRelationId: $businessRelationId
      userId: $userId
    ) {
      _id
      phone
      email
      type
      firstName
      lastName
      businessRelations {
        _id
        businessRole
        active
        business {
          createdAt
          name
          phone
          zip
          address1
          address2
          city
          state
          active
          locations {
            _id
            jobPosts
            name
            phone
            address1
            address2
            city
            state
            zip
            active
          }
          photos {
            _id
            key
            name
            active
          }
          video {
            key
            name
            active
          }
          categories {
            _id
            name
            active
          }
          backgroundColor
        }
      }
    }
  }
`;

export const ADMIN_REMOVE_BUSINESS_USER_BUSINESS = gql`
  mutation adminArchiveBusinessUserBusinessWide(
    $userId: String!
    $businessId: String!
  ) {
    adminArchiveBusinessUserBusinessWide(
      userId: $userId
      businessId: $businessId
    ) {
      status
    }
  }
`;

export const ADMIN_REMOVE_BUSINESS_USER_SYSTEM = gql`
  mutation adminArchiveBusinessUserSystemWide($userId: String!) {
    adminArchiveBusinessUserSystemWide(userId: $userId) {
      status
    }
  }
`;

export const ADMIN_REMOVE_BUSINESS_RELATION = gql`
  mutation adminDeleteRelationByBusiness(
    $businessRelationId: String!
    $businessId: String!
  ) {
    adminDeleteRelationByBusiness(
      businessRelationId: $businessRelationId
      businessId: $businessId
    ) {
      status
    }
  }
`;

export const ADMIN_REMOVE_JOB_POST = gql`
  mutation deleteAdminJobPostBusinessWide(
    $jobPostId: String!
    $businessId: String!
  ) {
    deleteAdminJobPostBusinessWide(
      jobPostId: $jobPostId
      businessId: $businessId
    ) {
      status
    }
  }
`;

export const ADMIN_CREATE_USER_SYSTEM_WIDE = gql`
  mutation adminCreateBusinessUserSystemWide(
    $adminCreateBusinessUser: AdminCreateBusinessUserDto!
  ) {
    adminCreateBusinessUserSystemWide(
      adminCreateBusinessUser: $adminCreateBusinessUser
    ) {
      _id
      phone
      email
      type
      firstName
      lastName
      businessRelations {
        _id
        businessRole
        business {
          name
          phone
          zip
          address1
          address2
          city
          state
          active
          locations {
            _id
            jobPosts
            name
            phone
            address1
            address2
            city
            state
            zip
            active
          }
          photos {
            _id
            key
            name
            active
          }
          video {
            key
            name
            active
          }
          categories {
            _id
            name
            active
          }
          backgroundColor
        }
      }
    }
  }
`;

export const ADMIN_REMOVE_USER_SYSTEM = gql`
  mutation adminDeleteRelationSystemWide($businessRelationId: String!) {
    adminDeleteRelationSystemWide(businessRelationId: $businessRelationId) {
      status
    }
  }
`;

export const ADMIN_SNOOZE_JOB_POST = gql`
  mutation adminSetJobPostIsSnoozeStatus(
    $jobPostId: String!
    $setJobPostIsSnoozeStatusDto: SetJobPostIsSnoozeStatusDto!
  ) {
    adminSetJobPostIsSnoozeStatus(
      jobPostId: $jobPostId
      setJobPostIsSnoozeStatusDto: $setJobPostIsSnoozeStatusDto
    ) {
      _id
      isSnooze
    }
  }
`;
