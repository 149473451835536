import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react';
import {
  ADMIN_CREATE_LOCATION,
  ADMIN_UPDATE_LOCATION,
} from '../../apollo/mutations';
import { STATES } from '../../constants';
import { location } from '../../types/locations';
import { useMutation } from '@apollo/client';
import { transformFieldsToString } from '../../tools/heplers';
import { useParams } from 'react-router-dom';
// @ts-ignore
import { useSnackbar } from 'react-simple-snackbar';
import extractGraphQLErrors from '../../tools/extractGraphqlErrors';

interface AddLocationProps {
  onClose: () => void;
  onSuccess: () => void;
  itemToEdit: location | null;
}

const AddLocation: React.FC<AddLocationProps> = ({
  onClose,
  onSuccess,
  itemToEdit,
}) => {
  const [createLocation] = useMutation(
    itemToEdit ? ADMIN_UPDATE_LOCATION : ADMIN_CREATE_LOCATION,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .required('Location name is required')
      .max(30, 'Location name should not exceed 30 characters'),
    phone: Yup.string()
      .test('empty', 'Phone is required', (val) => !!val)
      .test(
        'startsWith',
        'Phone should start with +1',
        (val) => !!val?.startsWith('+1'),
      )
      .test(
        'len',
        'Length should be 12 characters',
        (val) => val?.length === 12,
      ),
    address1: Yup.string()
      .required('Address1 is required')
      .max(50, 'Address1 should not exceed 50 characters'),
    address2: Yup.string().max(50, 'Address2 should not exceed 50 characters'),
    city: Yup.string()
      .required('City is required')
      .max(50, 'City should not exceed 50 characters'),
    zip: Yup.mixed().required('Zip is required'),
  });

  const initialValues: location = {
    name: itemToEdit && itemToEdit.name ? itemToEdit.name : '',
    phone: itemToEdit && itemToEdit.phone ? itemToEdit.phone : '+1',
    address1: itemToEdit && itemToEdit.address1 ? itemToEdit.address1 : '',
    address2: itemToEdit && itemToEdit.address2 ? itemToEdit.address2 : '',
    city: itemToEdit && itemToEdit.city ? itemToEdit.city : '',
    zip: itemToEdit && itemToEdit.zip ? Number(itemToEdit.zip) : '',
    state: itemToEdit && itemToEdit.state ? itemToEdit.state : STATES[0].value,
    active: itemToEdit && 'active' in itemToEdit ? itemToEdit.active : true,
  };

  const options = {
    style: {
      textAlign: 'center',
    },
  };

  const [openSnackbar] = useSnackbar(options);
  const { id } = useParams<{ id: string }>();

  const createNewLocation = async (data: location) => {
    const fieldsToTransform = ['zip'];
    if (itemToEdit) {
      data.businessId = itemToEdit._id;
    }

    const transformedData = transformFieldsToString(fieldsToTransform, data);
    if (itemToEdit && itemToEdit.name === data.name) {
      delete transformedData.name;
    }

    try {
      setIsLoading(true);
      if (!itemToEdit) {
        await createLocation({
          variables: {
            businessId: id,
            createBusinessLocationDto: {
              ...transformedData,
            },
          },
        });
      } else {
        delete transformedData.businessId;
        await createLocation({
          variables: {
            businessId: id,
            locationId: itemToEdit._id,
            updateBusinessLocationDto: {
              ...transformedData,
            },
          },
        });
      }
      onSuccess();
      onClose();
    } catch (e: any) {
      const err = extractGraphQLErrors(e);
      if (err.zip && err.zip === 'error.zip.zipNotFound') {
        openSnackbar("Wrong zip code or doesn't available");
        setIsLoading(false);
        return;
      }
      openSnackbar(e.message);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return (
    <div className="add-business">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={SignupSchema}
        onSubmit={(values: location) => {
          const valCopy = { ...values };
          createNewLocation(valCopy);
        }}>
        {({ errors, touched, dirty, isValid }) => (
          <Form>
            <div className="flex justify-between mb-4">
              <h5>{itemToEdit ? 'Edit Location' : 'Add Location'}</h5>
              <div className="flex align-center">
                <p className="m-0">Active Location</p>
                <div className="flex align-center styled-switcher">
                  <Field
                    type="checkbox"
                    id="switch"
                    name="active"
                    className="input"
                  />
                  <label htmlFor="switch" className="checkbox-label">
                    Toggle
                  </label>
                </div>
              </div>
            </div>
            <div className="input-wrap mb-3">
              <label className="input-label">LOCATION NAME</label>
              {isLoading ? (
                <div className="ssc-square skeleton-item"></div>
              ) : (
                <Field
                  name="name"
                  type="text"
                  placeholder="Location Name"
                  className="input"
                />
              )}
              {errors.name && touched.name ? (
                <div className="error">{errors.name}</div>
              ) : null}
            </div>
            <div className={`input-wrap mb-3 ${!isLoading && 'phone-wrap'}`}>
              <label className="input-label">PHONE NUMBER</label>
              {isLoading ? (
                <div className="ssc-square skeleton-item"></div>
              ) : (
                <Field
                  name="phone"
                  type="text"
                  placeholder="+11111111111"
                  className="input"
                />
              )}
              {errors.phone && touched.phone ? (
                <div className="error">{errors.phone}</div>
              ) : null}
            </div>
            <div className="input-wrap mb-3">
              <label className="input-label">ADDRESS 1</label>
              {isLoading ? (
                <div className="ssc-square skeleton-item"></div>
              ) : (
                <Field
                  name="address1"
                  type="text"
                  placeholder="Address 1"
                  className="input"
                />
              )}
              {errors.address1 && touched.address1 ? (
                <div className="error">{errors.address1}</div>
              ) : null}
            </div>
            <div className="input-wrap mb-3">
              <label className="input-label">ADDRESS 2</label>
              {isLoading ? (
                <div className="ssc-square skeleton-item"></div>
              ) : (
                <Field
                  name="address2"
                  type="text"
                  placeholder="Address 2"
                  className="input"
                />
              )}
              {errors.address2 && touched.address2 ? (
                <div className="error">{errors.address2}</div>
              ) : null}
            </div>
            <div className="input-wrap mb-3">
              <label className="input-label">CITY</label>
              {isLoading ? (
                <div className="ssc-square skeleton-item"></div>
              ) : (
                <Field
                  name="city"
                  type="text"
                  placeholder="City"
                  className="input"
                />
              )}
              {errors.city && touched.city ? (
                <div className="error">{errors.city}</div>
              ) : null}
            </div>
            <div className="flex justify-content-between mb-3">
              <div className="input-wrap w-48">
                <label className="input-label">STATE</label>
                {isLoading ? (
                  <div className="ssc-square skeleton-item"></div>
                ) : (
                  <Field as="select" name="state" className="select">
                    {STATES.map((el) => {
                      return (
                        <option key={el.value} value={el.value}>
                          {el.label}
                        </option>
                      );
                    })}
                  </Field>
                )}
              </div>
              <div className="input-wrap w-48">
                <label className="input-label">ZIP</label>
                {isLoading ? (
                  <div className="ssc-square skeleton-item"></div>
                ) : (
                  <Field
                    name="zip"
                    type="text"
                    placeholder="ZIP"
                    className="input"
                  />
                )}
                {errors.zip && touched.zip ? (
                  <div className="error">{errors.zip}</div>
                ) : null}
              </div>
            </div>
            <div className="half-wrap flex justify-content-between">
              <button
                disabled={isLoading || !(dirty && isValid)}
                type="submit"
                className="btn btn--main w-48">
                {itemToEdit ? 'Edit Location' : 'Add Location'}
              </button>
              <button
                className="btn btn--secondary w-48"
                type="button"
                onClick={onClose}>
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddLocation;
