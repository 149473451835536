import { FieldProps, useFormikContext, getIn } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
// import { OptionsType, ValueType } from 'react-select/lib/types';

interface Option {
  _id: string;
  name: string;
}

interface OptionForSelect {
  label: string;
  value: string;
}

interface CustomSelectProps extends FieldProps {
  options: Option[];
  isMulti?: boolean;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  onSelect?: (data: string) => void;
}

export const CustomSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  onSelect,
  disabled = false,
  isMulti = false,
}: CustomSelectProps) => {
  const [opts, setOpts] = useState<Array<OptionForSelect>>([]);
  useEffect(() => {
    const updatedArr = options.map((el: Option) => {
      return {
        value: el._id,
        label: el.name,
      };
    });
    setOpts(updatedArr);
  }, []);

  const { values } = useFormikContext();

  const fieldValue = getIn(values, field.name);

  useEffect(() => {
    if (onSelect) {
      onSelect(fieldValue);
    }
  }, [fieldValue]);

  const onChange = (option: any) => {
    form.setFieldValue(
      field.name,
      isMulti
        ? (option as OptionForSelect[]).map(
            (item: OptionForSelect) => item.value,
          )
        : (option as OptionForSelect).value,
    );
  };

  const getValue = () => {
    if (opts) {
      return isMulti
        ? opts.filter((option: any) => field.value.indexOf(option.value) >= 0)
        : opts.find((option: any) => option.value === field.value);
    } else {
      return isMulti ? [] : ('' as any);
    }
  };

  return (
    <Select
      className="custom-select"
      name={field.name}
      isDisabled={disabled}
      value={getValue()}
      onChange={onChange}
      placeholder={placeholder}
      options={opts}
      isMulti={isMulti}
    />
  );
};

export default CustomSelect;
