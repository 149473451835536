import React, { useState, useRef, useEffect } from 'react';
// @ts-ignore
import { default as Arrow } from '../../assets/img/arrow-bottom.svg';

type Item = {
  _id: string;
  name: string;
};

interface DropDownProps {
  data: [] | Item[];
  placeholder: string;
  selected?: string;
  onSelect: (item: Item) => void;
  up?: boolean;
}

const Dropdown: React.FC<DropDownProps> = ({
  data,
  placeholder,
  onSelect,
  selected,
  up,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [selectedName, setSelectedName] = useState<string | null>(null);
  const wrapperRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    document.addEventListener('closeDrop', handleClickOutside, true);
    return () => {
      document.removeEventListener('closeDrop', handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = () => {
    setOpen(false);
  };

  const selectItem = (item: { _id: string; name: string }) => {
    setSelectedName(item.name);
    onSelect(item);
    setOpen(false);
  };

  return (
    <div className="dropdown" data-dropdwown="drop">
      <div
        onClick={() => setOpen(!isOpen)}
        className="flex justify-content-between dropdown__input align-center">
        <div style={{ textTransform: 'capitalize' }}>
          {selectedName || selected || placeholder}
        </div>
        <img
          className={isOpen ? 'up' : 'down'}
          src={Arrow}
          alt="arrow"
          style={{ width: '24px' }}
        />
      </div>
      {isOpen && (
        <ul className={`dropdown__list ${up && 'items-up'}`} ref={wrapperRef}>
          {data.map((item: { _id: string; name: string }) => {
            return (
              <li
                key={item._id}
                className="dropdown__item"
                onClick={() => selectItem(item)}>
                {item.name}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
