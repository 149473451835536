import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import { default as BackButton } from '../../assets/img/back-button.svg';
import { Link, useHistory, useParams } from 'react-router-dom';
// @ts-ignore
import { default as AddIcon } from '../../assets/img/add-icon.svg';
import Locations from './Locations';
import JobPosts from './JobPosts';
import Users from './Users';
import Billing from './Billing';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
// @ts-ignore
import { default as CrossIcon } from '../../assets/img/cross.svg';
import AddLocation from '../../components/AddLocation/AddLocation';
import AddJobPosts from '../../components/AddJobPost/AddJobPosts';
// @ts-ignore
import SnackbarProvider from 'react-simple-snackbar';
import { observer } from 'mobx-react-lite';
import { location as locations } from '../../types/locations';
import { users } from '../../types/users';
import SearchInput from '../../components/Shared/SearchInput';
import qs from 'query-string';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_ADMIN_LOCATION, GET_BUSINESS_NAME } from '../../apollo/queries';
import { businessStore, jobPostStore, userStore } from '../../store';
import proxy from '../../tools/proxy';
import AddBusinessUser from '../../components/AddBusinessUser/AddBusinessUser';
import { Roles } from '../../types/roles';
import { axios } from '../../utils/axios';
import { sortItems } from '../../types/sortPopup';

const BusinessItem: React.FC = () => {
  const { userRole } = userStore;
  const { isLocation, setIsBilled, setLocationLength } = businessStore;
  const { isUsers, jobPostsCount } = jobPostStore;
  const ref = useRef<any>();
  const [itemToEdit, setItemToEdit] = useState<locations | users | null>(null);
  const [activeTab, setActiveTab] = useState<string>('locations');
  const [updateLocations, setUpdateLocations] = useState<boolean>(false);
  const [searchBy, setSearchBy] = useState<string>('');
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [crossStyles, setCrossStyle] = useState({
    top: 0,
    right: 0,
  });

  const history = useHistory();

  const onBack = () => {
    history.push('/manage-businesses');
    // history.goBack();
  };
  const { id } = useParams<{ id: string }>();

  const [getBusinessLocation] = useLazyQuery(GET_ADMIN_LOCATION, {
    variables: {
      businessId: id,
      getAdminLocationsDto: {
        searchBy: '',
        sortBy: sortItems.BY_LAST_ADDED_DATE,
        skip: 0,
        limit: 10,
      },
    },
    fetchPolicy: 'network-only',
  });

  const { data } = useQuery(GET_BUSINESS_NAME, {
    variables: {
      businessId: id,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    axios.get(`/business/check-billing/business/${id}`).then(({ data }) => {
      setIsBilled(data.status);
    });

    getBusinessLocation().then(({ data }) => {
      setLocationLength(!!data.getAdminLocations.collection.length);
    });
  }, []);

  const onLocationEdit = (data: locations) => {
    setItemToEdit(data);
    ref.current.open();
  };

  const onUsersEdit = (data: users) => {
    setItemToEdit(data);
    ref.current.open();
  };

  const renderTabInfo = (activeTab: string) => {
    switch (activeTab) {
      case 'locations': {
        return (
          <Locations
            getLocations={updateLocations}
            onEditLocation={onLocationEdit}
          />
        );
      }
      case 'job_post': {
        return <JobPosts />;
      }
      case 'users': {
        return (
          <Users
            currentBusiness={data.adminGetBusiness.name}
            onEditUser={onUsersEdit}
          />
        );
      }
      case 'billing': {
        return (
          <SnackbarProvider>
            <Billing />
          </SnackbarProvider>
        );
      }
      default:
        return;
    }
  };

  const renderButtonText = (activeTab: string) => {
    switch (activeTab) {
      case 'locations': {
        return 'Location';
      }
      case 'job_post': {
        return 'Job Post';
      }
      case 'users': {
        return 'Team Member';
      }
      case 'billing': {
        return 'New Card';
      }
      default:
        return;
    }
  };

  const renderPlaceholder = (activeTab: string) => {
    switch (activeTab) {
      case 'locations': {
        return 'Location name';
      }
      case 'job_post': {
        return 'Job post name ';
      }
      case 'users': {
        return 'User name';
      }
      case 'billing': {
        return 'Bill';
      }
      default:
        return '';
    }
  };

  const changeActiveTab = (active: string) => {
    const query = history.location.search;
    if (query.length) {
      history.push({ search: '' });
    }
    setActiveTab(active);
  };

  // TODO update location using pub/sub
  const onSuccess = () => {
    getBusinessLocation().then(({ data }) => {
      setLocationLength(!!data.getAdminLocations.collection.length);
    });
    setUpdateLocations(!updateLocations);
  };

  const onPopupOpen = () => {
    setIsPopupOpen(true);
    if (itemToEdit) {
      setItemToEdit(null);
    }
    const popup = document.querySelector('.post-content');
    if (popup instanceof HTMLElement) {
      const right = (window.innerWidth - popup.clientWidth) / 2 - 38;
      const top = popup.offsetTop;
      setCrossStyle({
        top,
        right,
      });
    }
  };

  const closePopup = () => {
    onClose();
    ref.current.close();
  };

  const onClose = () => {
    setItemToEdit(null);
    setIsPopupOpen(false);
  };

  const existingQuery = () => {
    // eslint-disable-next-line no-restricted-globals
    return qs.parse(location.search);
  };

  const openPopup = () => {
    ref.current.open();
  };

  const onSubmit = (searchValue: string) => {
    const queryParams = existingQuery();
    if ('page' in queryParams) {
      delete queryParams.page;
    }
    const newQueries = { ...queryParams, searchBy: searchValue };
    history.push({ search: qs.stringify(newQueries) });
  };

  useEffect(() => {
    const query = history.location.search;
    if (query.includes('success') || query.includes('cancel')) {
      setActiveTab('billing');
    }

    proxy.subscribe('switched_role', () => {
      if (history.location.pathname.includes('/manage-businesses')) {
        history.push('/manage-businesses');
      }
    });
    const queryParams = existingQuery();
    if ('searchBy' in queryParams) {
      setSearchBy(queryParams.searchBy as string);
    }

    return () => {
      proxy.unsubscribe('switched_role');
    };
  }, []);

  return (
    <div className="business-item">
      <div className="breadcrumbs mb-3 flex align-center">
        <Link to="/manage-businesses">Manage Business</Link>
        {data && data.adminGetBusiness.name && (
          <p>{data.adminGetBusiness.name}</p>
        )}
      </div>
      <div className="flex justify-content-between align-center">
        <div className="flex">
          <div onClick={onBack} className="back mr-1">
            <img src={BackButton} alt="back-button" />
          </div>
          <h3>{data && data.adminGetBusiness.name}</h3>
        </div>
        <div className="flex align-center">
          {activeTab !== 'billing' ? (
            <div className="mr-1">
              <SearchInput
                placeholder={renderPlaceholder(activeTab)}
                inputValue={searchBy}
                onSubmit={onSubmit}
              />
            </div>
          ) : null}
          {(((userRole === 'super' || userRole === 'admin') &&
            activeTab === 'locations') ||
            activeTab === 'job_post' ||
            activeTab === 'users') && (
            <button
              onClick={openPopup}
              disabled={
                (activeTab === 'job_post' && (!isLocation || !isUsers)) ||
                (activeTab === 'users' && !isLocation)
              }
              className="btn btn--main pr-1 pl-1 business-btn">
              <span className="pr-4">Add {renderButtonText(activeTab)}</span>
              <img src={AddIcon} alt="icon" className="icon-image" />
            </button>
          )}
        </div>
        {isPopupOpen && activeTab === 'job_post' && (
          <button
            className="close-cross"
            style={crossStyles}
            onClick={closePopup}>
            <img src={CrossIcon} alt="close" className="test" />
          </button>
        )}
        <Popup
          closeOnDocumentClick={false}
          onOpen={onPopupOpen}
          onClose={onClose}
          lockScroll={true}
          ref={ref}
          className={activeTab === 'job_post' ? 'post' : 'business'}
          modal
          nested>
          {(close: any) => (
            <div className="modal business-modal">
              <img
                onClick={close}
                src={CrossIcon}
                alt="close"
                className="cross-icon"
              />
              {activeTab === 'locations' && (
                <SnackbarProvider>
                  <AddLocation
                    onSuccess={onSuccess}
                    onClose={close}
                    itemToEdit={itemToEdit as locations}
                  />
                </SnackbarProvider>
              )}
              {activeTab === 'job_post' && (
                <SnackbarProvider>
                  <AddJobPosts onClose={close} />
                </SnackbarProvider>
              )}
              {activeTab === 'users' && (
                <SnackbarProvider>
                  <AddBusinessUser
                    onSuccess={onSuccess}
                    onClose={close}
                    itemToEdit={itemToEdit as users}
                  />
                </SnackbarProvider>
              )}
            </div>
          )}
        </Popup>
      </div>
      <ul className="tab-list flex">
        <li
          className={`tab-list--item ${
            activeTab === 'locations' ? 'active' : ''
          }`}
          onClick={() => changeActiveTab('locations')}>
          Locations
        </li>
        <li
          className={`tab-list--item ${
            activeTab === 'job_post' ? 'active' : ''
          }`}
          onClick={() => changeActiveTab('job_post')}>
          Job Posts
        </li>
        {userRole === Roles.admin || userRole === Roles.super ? (
          <li
            className={`tab-list--item ${
              activeTab === 'users' ? 'active' : ''
            }`}
            onClick={() => changeActiveTab('users')}>
            Users
          </li>
        ) : null}
        {userRole === Roles.admin || userRole === Roles.super ? (
          <li
            className={`tab-list--item ${
              activeTab === 'billing' ? 'active' : ''
            }`}
            onClick={() => changeActiveTab('billing')}>
            Billing
          </li>
        ) : null}
      </ul>
      <div
        style={{
          height: '100%',
        }}>
        {renderTabInfo(activeTab)}
      </div>
    </div>
  );
};

export default observer(BusinessItem);
