import Sidebar from '../Sidebar/Sidebar';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import Dashboard from '../../screens/Dashboard/Dashboard';
import ManageBusiness from '../../screens/ManageBusiness/ManageBusiness';
import ManageUsers from '../../screens/ManageUsers/ManageUsers';
import ManageJobPostings from '../../screens/ManageJobPostings/ManageJobPostings';
import Administration from '../../screens/Administration/Administration';
import { useEffect, useRef, useState } from 'react';
import PermissionRoute from './PermissionRoute';
import BusinessItem from '../../screens/BusinessItem/BusinessItem';
import { Roles } from '../../types/roles';
import { userStore } from '../../store';
import SelectBusinessRole from '../SelectBusinessRole/SelectBusinessRole';
import Popup from 'reactjs-popup';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import Skeleton from '../Skeleton/Skeleton';
import Auth from '../../tools/Auth';

const AuthorizedLayout = () => {
  const ref = useRef<any>();
  const [relations, setRelations] = useState<[]>([]);
  const {
    loggedInUser,
    isLoading,
    userRole,
    setUserRole,
    openPopupFromSidebar,
  } = userStore;

  const history = useHistory();

  useEffect(() => {
    if (userRole !== 'super') {
      const { businessRelations } = loggedInUser;
      // TODO handle types
      // @ts-ignore
      setRelations(businessRelations);
      ref.current.open();
    }
  }, [openPopupFromSidebar]);

  const authorizeSingleUser = async (id: string) => {
    try {
      await Auth.selectRole(id);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const user = toJS(loggedInUser);
    const { businessRelations } = user;
    const loc = history.location.pathname;
    const search = history.location.search;
    const QUERY_SUCCESS = '?success';
    const QUERY_CANCEL = '?cancel';

    if (businessRelations) {
      // TODO handle types
      // @ts-ignore
      setRelations(businessRelations);
    }

    const afterSuccessfullBilling = () => {
      return businessRelations.filter(
        (el) =>
          el.businessRole === 'ADMIN' && el.business._id === loc.split('/')[2],
      );
    };

    if (
      loc.split('/')[1] === 'manage-businesses' &&
      (QUERY_SUCCESS === search || QUERY_CANCEL === search) &&
      businessRelations.length > 1
    ) {
      const user = afterSuccessfullBilling();
      if (user.length) {
        authorizeSingleUser(user[0]?._id);
        setUserRole(Roles.admin);
      }
    } else if (user.type === 'BUSINESS' && businessRelations.length > 1) {
      ref.current.open();
      setUserRole(Roles.admin);
    }

    if (user.type === 'BUSINESS' && Number(businessRelations.length) === 1) {
      //TODO implement type for relation
      // @ts-ignore
      authorizeSingleUser(businessRelations[0]._id);
      // @ts-ignore
      switch (businessRelations[0].businessRole) {
        case 'ADMIN':
          setUserRole(Roles.admin);
          break;
        case 'MANAGER':
          setUserRole(Roles.manager);
          break;
        case 'RECRUITER':
          setUserRole(Roles.recruiter);
          break;
        default:
          setUserRole(Roles.admin);
      }
    }
    if (user.type === 'BUSINESS' && !businessRelations.length) {
      setUserRole(Roles.admin);
    }
    if (user.type === 'SUPER_ADMIN') {
      setUserRole(Roles.super);
    }
  }, []);

  const onClose = () => {
    ref.current.close();
  };

  if (isLoading) return <Skeleton />;

  return (
    <div className="layout-wrap">
      <Popup
        closeOnDocumentClick={true}
        ref={ref}
        className="select"
        modal
        nested>
        <div>
          <SelectBusinessRole data={relations} close={onClose} />
        </div>
      </Popup>
      <Sidebar />
      <div className="layout-content">
        <Switch>
          <PermissionRoute
            roles={[
              Roles.super,
              Roles.admin,
              Roles.recruiter,
              Roles.manager,
              Roles.appOnly,
            ]}
            path="/"
            exact
            component={Dashboard}
          />
          <PermissionRoute
            exact
            roles={[Roles.super, Roles.admin, Roles.manager, Roles.recruiter]}
            path="/manage-businesses"
            component={ManageBusiness}
          />
          <PermissionRoute
            roles={[Roles.super]}
            path="/manage-users"
            component={ManageUsers}
          />
          <PermissionRoute
            roles={[
              Roles.super,
              Roles.admin,
              Roles.recruiter,
              Roles.manager,
              Roles.appOnly,
            ]}
            path="/manage-job-postings"
            component={ManageJobPostings}
          />
          <PermissionRoute
            roles={[Roles.super]}
            path="/administration"
            component={Administration}
          />
          <PermissionRoute
            roles={[Roles.super, Roles.admin, Roles.recruiter, Roles.manager]}
            path="/manage-businesses/:id"
            component={BusinessItem}
          />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
};

export default observer(AuthorizedLayout);
