import React, { useState, useEffect } from 'react';
import Dropzone from './DropZone';
import { axios } from '../../../utils/axios';
import { useParams } from 'react-router-dom';
// @ts-ignore
import { default as Passed } from '../../../assets/img/passed-icon.svg';
// @ts-ignore
import { useSnackbar } from 'react-simple-snackbar';
import { observer } from 'mobx-react-lite';
import { jobPostStore } from '../../../store';

interface AddPhotosProps {
  onPhotosSelect: (data: []) => void;
  selected: {
    photos: { _id: string }[];
    postId: string | null;
  };
}

const AddPhotos: React.FC<AddPhotosProps> = ({ onPhotosSelect, selected }) => {
  const { updateImages } = jobPostStore;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isImagesRequested, setImagesRequested] = useState<boolean>(false);
  const [imagesFromApi, setImagesFromApi] = useState<
    Array<{ url: string; _id: string }>
  >([]);
  const [imagesData, setImagesData] = useState<object>({
    0: null,
    1: null,
    2: null,
    3: null,
  });

  const { id } = useParams<{ id: string }>();

  const options = {
    style: {
      textAlign: 'center',
    },
  };

  const [openSnackbar] = useSnackbar(options);

  useEffect(() => {
    if (selected && selected.postId && !isImagesRequested) {
      getImages();
      setImagesRequested(true);
    }
  }, [selected]);

  useEffect(() => {
    const arrayData = Object.values(imagesData).filter((n) => n);
    if (isLoaded) {
      onPhotosSelect(arrayData as []);
      updateImages([...imagesFromApi, ...arrayData] as []);
    }
    return () => {
      updateImages([]);
    };
  }, [imagesData]);

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  const onImageSelect = (index: number, file: File | null) => {
    setIsLoaded(true);
    setImagesData((prevState): any => {
      return { ...prevState, [index]: file };
    });
  };

  const getImages = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `/job-post/admin-job-posts/business/${id}/job-post/${selected?.postId}/photo`,
      );
      if (!data.length) {
        onPhotosSelect([]);
      } else {
        // TODO update images
        // const arrayData = Object.values(imagesData).filter((n) => n);
        // onPhotosSelect([...data, ...arrayData] as []);
      }
      setImagesFromApi(data);
      const arrayData = Object.values(imagesData).filter((n) => n);
      updateImages([...data, ...arrayData] as []);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  const removeImage = async (imageId: string) => {
    setIsLoading(true);
    const arrayData = Object.values(imagesData).filter((n) => n);
    onPhotosSelect(arrayData as []);
    try {
      const { data } = await axios.delete(
        `/job-post/admin-job-posts/business/${id}/job-post/${selected?.postId}/photo/${imageId}`,
      );
      setImagesFromApi(data);
      const arrayData = Object.values(imagesData).filter((n) => n);
      onPhotosSelect(arrayData as []);
      openSnackbar('Images were updated');
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  const onRemoveFromAPI = async (selectedImage: { _id: string }) => {
    setIsLoading(true);
    await removeImage(selectedImage._id);
    await getImages();
    setIsLoading(false);
  };

  return (
    <div className="job-post--item">
      <div
        onClick={toggleOpen}
        className="flex align-center justify-content-between head">
        <div className="flex align-center">
          {(selected && selected.photos.length) || imagesFromApi.length ? (
            <img src={Passed} alt="success" />
          ) : (
            <p className="number">9</p>
          )}
          <p className="headline">Add Photos*</p>
        </div>
      </div>
      <div className="mt-2">
        <p className="sub-headline mb-0">
          Add Information about your business to help attract job seekers. One
          required but the more the better!
        </p>
        <p className="sub-headline mt-0">
          Maximum photo size should not exceed 3mb.
        </p>
        <div className="flex drop-wrap">
          <div className="drop-wrap--item">
            <div
              className={`ssc-square small-item image-skeleton ${
                !isLoading && 'hidden'
              }`}></div>
            <div className={`${isLoading && 'hidden'}`}>
              <Dropzone
                onRemoveFromAPI={onRemoveFromAPI}
                selected={imagesFromApi[0]}
                name="1"
                onSelect={(value) => onImageSelect(0, value)}
              />
            </div>
          </div>
          <div className="drop-wrap--item">
            <div
              className={`ssc-square small-item image-skeleton ${
                !isLoading && 'hidden'
              }`}></div>
            <div className={`${isLoading && 'hidden'}`}>
              <Dropzone
                onRemoveFromAPI={onRemoveFromAPI}
                selected={imagesFromApi[1]}
                name="2"
                onSelect={(value) => onImageSelect(1, value)}
              />
            </div>
          </div>
          <div className="drop-wrap--item">
            <div
              className={`ssc-square small-item image-skeleton ${
                !isLoading && 'hidden'
              }`}></div>
            <div className={`${isLoading && 'hidden'}`}>
              <Dropzone
                onRemoveFromAPI={onRemoveFromAPI}
                selected={imagesFromApi[2]}
                name="3"
                onSelect={(value) => onImageSelect(2, value)}
              />
            </div>
          </div>
          <div className="drop-wrap--item">
            <div
              className={`ssc-square small-item image-skeleton ${
                !isLoading && 'hidden'
              }`}></div>
            <div className={`${isLoading && 'hidden'}`}>
              <Dropzone
                onRemoveFromAPI={onRemoveFromAPI}
                selected={imagesFromApi[3]}
                name="4"
                onSelect={(value) => onImageSelect(3, value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AddPhotos);
