import { makeAutoObservable } from 'mobx';

type User = {
  firstName: string;
  lastName: string;
  _id: string;
};

class BusinessStore {
  isLocation: boolean = false;
  allUsersList: [] = [];
  isBilled: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLocationLength = (isLocations: boolean) => {
    this.isLocation = isLocations;
  };

  setIsBilled = (isBilled: boolean) => {
    this.isBilled = isBilled;
  };

  setUsersList = (users: []) => {
    this.allUsersList = users;
  };

  get transformedUserList() {
    return this.allUsersList.map((el: User) => {
      return {
        name: `${el.firstName} ${el.lastName}`,
        _id: el._id,
      };
    });
  }
}

const businessStore = new BusinessStore();

export default businessStore;
