import React, { useEffect } from 'react';

interface AdditionalCompensationProps {
  updateData: (dataType: string, data: string | null) => void;
  data: {
    additionalCompensation: string;
    compensationType: string;
  };
}

enum Compensation {
  'tips' = 'TIPS',
  'commission' = 'COMMISSION',
}

const AdditionalCompensation: React.FC<AdditionalCompensationProps> = ({
  updateData,
  data,
}) => {
  useEffect(() => {
    // updateData('additionalCompensation', null);
  }, []);

  const onSelect = (type: Compensation) => {
    if (data.additionalCompensation === type) {
      updateData('additionalCompensation', null);
    } else {
      updateData('additionalCompensation', type);
    }
  };

  return (
    <div className="flex">
      {data && data.compensationType === 'HOURLY' && (
        <div
          className={`compensation mr-1 ${
            data &&
            data.additionalCompensation === Compensation.tips &&
            'selected'
          }`}
          onClick={() => onSelect(Compensation.tips)}>
          Plus Tips
        </div>
      )}
      {data && data.compensationType === 'SALARY' && (
        <div
          className={`compensation ${
            data &&
            data.additionalCompensation === Compensation.commission &&
            'selected'
          }`}
          onClick={() => onSelect(Compensation.commission)}>
          Plus Commission
        </div>
      )}
    </div>
  );
};

export default AdditionalCompensation;
