import React, { useRef, useState } from 'react';
import Popup from 'reactjs-popup';
// @ts-ignore
import { default as CrossIcon } from '../../assets/img/cross.svg';
// @ts-ignore
import { default as Success } from '../../assets/img/suc-icon.svg';
// @ts-ignore
import { default as Unmatch } from '../../assets/img/unmatch-icon.svg';
import RemovePopup from './RemovePopup';
import { jobPost } from '../../types/jobPost';
import AddJobPosts from '../AddJobPost/AddJobPosts';
// @ts-ignore
// @ts-ignore
import SnackbarProvider, { useSnackbar } from 'react-simple-snackbar';
import { observer } from 'mobx-react-lite';
import { jobPostStore, userStore } from '../../store';
import Preview from '../AddJobPost/components/Preview';
import { axios } from '../../utils/axios';
import { useParams } from 'react-router-dom';
import proxy from '../../tools/proxy';
import {
  ADMIN_REMOVE_JOB_POST,
  ADMIN_SNOOZE_JOB_POST,
} from '../../apollo/mutations';
import { useMutation } from '@apollo/client';
import { Roles } from '../../types/roles';

interface jobPostProps {
  jobPost: jobPost;
  count: number;
}

const JobPostsCard: React.FC<jobPostProps> = ({ jobPost, count }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false);
  const [isChecked, setChecked] = useState<boolean>(jobPost.isSnooze);
  const [crossStyles, setCrossStyle] = useState({
    top: 0,
    right: 0,
  });

  const [removeJobPost] = useMutation(ADMIN_REMOVE_JOB_POST);
  const [snoozeJobPost] = useMutation(ADMIN_SNOOZE_JOB_POST);

  const editPopup = useRef<any>();
  const previewPopup = useRef<any>();
  const removePopup = useRef<any>();

  const options = {
    style: {
      textAlign: 'center',
    },
  };

  const [openSnackbar] = useSnackbar(options);

  const { id } = useParams<{ id: string }>();

  const { updateImages } = jobPostStore;
  const { userRole } = userStore;

  const onRemove = async () => {
    setIsLoading(true);
    try {
      await removeJobPost({
        variables: {
          jobPostId: jobPost._id,
          businessId: id,
        },
      });
      proxy.publish('get_job_posts');
      removePopup.current.close();
      openSnackbar('Job post was successfully removed');
    } catch (e: any) {
      console.error(e);
      removePopup.current.close();
      openSnackbar(e.message);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    if (previewPopup) {
      previewPopup.current.close();
    }
    if (editPopup) {
      editPopup.current.close();
    }
  };

  const onClose = () => {
    setIsPopupOpen(false);
  };

  const getImages = async () => {
    setIsPreviewLoading(true);
    try {
      const { data } = await axios.get(
        `/job-post/admin-job-posts/business/${id}/job-post/${jobPost._id}/photo`,
      );
      updateImages([...data] as []);
    } catch (e) {
      console.error(e);
    }
    setIsPreviewLoading(false);
  };

  const onPopupOpen = (type: string) => {
    setIsPopupOpen(true);
    const popup = document.querySelector('.post-content');
    if (popup instanceof HTMLElement) {
      const right = (window.innerWidth - popup.clientWidth) / 2 - 38;
      const top = popup.offsetTop;
      setCrossStyle({
        top,
        right,
      });
    }
    if (type === 'preview') {
      getImages();
    }
  };

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    await snoozeJobPost({
      variables: {
        jobPostId: jobPost._id,
        setJobPostIsSnoozeStatusDto: {
          status: e.target.checked,
        },
      },
    });
    openSnackbar('Success');
  };

  return (
    <div className="card-long card-long--job">
      <div className="name flex align-center">
        <p className="name">{jobPost?.jobType?.name}</p>
      </div>
      <div className="address">
        <p>{jobPost?.availability?.name}</p>
      </div>
      <div className="email">
        {jobPost?.rateRange && (
          <p>
            $
            {`${jobPost?.rateRange?.from}-${jobPost?.rateRange?.to} ${jobPost?.compensationType}`}
          </p>
        )}
      </div>
      {userRole === Roles.super ||
      userRole === Roles.admin ||
      userRole === Roles.recruiter ||
      userRole === Roles.manager ? (
        <div className="snooze">
          <div className="flex align-center styled-switcher">
            <input
              type="checkbox"
              checked={isChecked}
              id={jobPost._id}
              name="active"
              className="input"
              onChange={onChange}
            />
            <label htmlFor={jobPost._id} className="checkbox-label">
              Toggle
            </label>
          </div>
        </div>
      ) : null}
      {count ? (
        <div className="phone flex align-center">
          <img src={Success} alt="log" style={{ width: '20px' }} />
          <p className="match-count">{count} Matches</p>
        </div>
      ) : (
        <div className="phone flex align-center">
          <img src={Unmatch} alt="log" style={{ width: '20px' }} />
          <p className="match-count">0 Matches</p>
        </div>
      )}
      <div className="card-long__item action">
        <Popup
          ref={previewPopup}
          onOpen={() => onPopupOpen('preview')}
          closeOnDocumentClick={false}
          onClose={onClose}
          className="post"
          trigger={
            <button
              className={`action-button mr-1 action-button--small action-preview ${
                userRole === 'recruiter' && 'no-remove'
              }`}></button>
          }
          modal
          nested>
          {(close: any) => (
            <div className="modal business-modal">
              {isPreviewLoading ? (
                <div className="preview-skeleton">
                  <div className="ssc-square business-item__skeleton intro"></div>
                  <div className="ssc-square business-item__skeleton sec mt-4"></div>
                  <div className="ssc-square business-item__skeleton mid mt-4"></div>
                  <div className="ssc-square business-item__skeleton sec mt-4"></div>
                </div>
              ) : (
                <Preview selectedData={jobPost} />
              )}
            </div>
          )}
        </Popup>
        {isPopupOpen && (
          <button
            className="close-cross"
            style={crossStyles}
            onClick={closePopup}>
            <img src={CrossIcon} alt="close" className="test" />
          </button>
        )}
        <Popup
          ref={editPopup}
          onOpen={() => onPopupOpen('edit')}
          onClose={onClose}
          closeOnDocumentClick={false}
          className="post"
          trigger={
            <button className="action-button action-button--small edit mr-1"></button>
          }
          modal
          nested>
          {(close: any) => (
            <div className="modal business-modal">
              <SnackbarProvider>
                <AddJobPosts onClose={close} itemToEdit={jobPost} />
              </SnackbarProvider>
            </div>
          )}
        </Popup>
        {userRole !== 'recruiter' && (
          <Popup
            ref={removePopup}
            closeOnDocumentClick={false}
            className="business"
            trigger={
              <button className="action-button action-button--small remove"></button>
            }
            modal
            nested>
            {(close: any) => (
              <div className="modal business-modal">
                <img
                  onClick={close}
                  src={CrossIcon}
                  alt="close"
                  className="cross-icon"
                />
                <RemovePopup
                  loading={isLoading}
                  onClose={close}
                  itemToRemove={jobPost?.jobType?.name}
                  onRemoveItem={onRemove}
                  type="job post"
                />
              </div>
            )}
          </Popup>
        )}
      </div>
    </div>
  );
};

export default observer(JobPostsCard);
