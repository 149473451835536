import TermsAndConditions from '../../components/TermsAndConditions/TermsAndConditions';

const TermsAndConditionsPage = () => {
  return (
    <div className="terms-page">
      <TermsAndConditions />
    </div>
  );
};

export default TermsAndConditionsPage;
