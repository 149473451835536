// @ts-ignore
import { ReactComponent as LogoIcon } from '../../assets/img/logo-white.svg';
// @ts-ignore
import { ReactComponent as DashboardIcon } from '../../assets/img/dashboard-ic.svg';
// @ts-ignore
import { ReactComponent as DashboardIconWhite } from '../../assets/img/dashboard-ic-white.svg';
// @ts-ignore
import { ReactComponent as FolderIcon } from '../../assets/img/folder-ic.svg';
// @ts-ignore
import { ReactComponent as FolderIconWhite } from '../../assets/img/folder-ic-white.svg';
// @ts-ignore
import { ReactComponent as UserIcon } from '../../assets/img/user-ic.svg';
// @ts-ignore
import { ReactComponent as UserIconWhite } from '../../assets/img/user-ic-white.svg';
// @ts-ignore
import { ReactComponent as AdminIcon } from '../../assets/img/admin-ic.svg';
// @ts-ignore
import { ReactComponent as AdminIconWhite } from '../../assets/img/admin-ic-white.svg';
// @ts-ignore
import { ReactComponent as JobPostingWhite } from '../../assets/img/job-post-ic-white.svg';
// @ts-ignore
import { ReactComponent as JobPosting } from '../../assets/img/job-post-ic.svg';
// @ts-ignore
import { ReactComponent as SwitchIcon } from '../../assets/img/switch-roles.svg';
import { NavLink } from 'react-router-dom';
import Profile from '../Profile/Profile';
import Permission from '../Layout/Permission';
import { Roles } from '../../types/roles';
import { userStore } from '../../store';
import { observer } from 'mobx-react';

const Sidebar = () => {
  const { userRole, openPopup, loggedInUser } = userStore;

  return (
    <div className="sidebar">
      <div>
        <div className="logo-wrap">
          <NavLink to="/">
            <LogoIcon />
          </NavLink>
        </div>
        <ul className="links-wrap">
          <Permission
            roles={[
              Roles.super,
              Roles.admin,
              Roles.recruiter,
              Roles.manager,
              Roles.appOnly,
            ]}>
            <li>
              <NavLink
                exact
                to="/"
                className="link"
                activeClassName="is-active">
                <span className="white">
                  <DashboardIconWhite />
                </span>
                <span className="black">
                  <DashboardIcon />
                </span>
                <p>Dashboard</p>
                <span className="faset faset-top"></span>
                <span className="faset faset-bottom"></span>
              </NavLink>
            </li>
          </Permission>
          <li>
            <Permission
              roles={[
                Roles.super,
                Roles.admin,
                Roles.manager,
                Roles.recruiter,
              ]}>
              <NavLink
                to="/manage-businesses"
                className="link"
                activeClassName="is-active">
                <div className="black">
                  <FolderIcon />
                </div>
                <div className="white">
                  <FolderIconWhite />
                </div>
                <p>Manage Businesses</p>
                <span className="faset faset-top"></span>
                <span className="faset faset-bottom"></span>
              </NavLink>
            </Permission>
          </li>
          <Permission roles={[Roles.super]}>
            <li>
              <NavLink
                to="/manage-users"
                className="link"
                activeClassName="is-active">
                <div className="black">
                  <UserIcon />
                </div>
                <div className="white">
                  <UserIconWhite />
                </div>
                <p>Manage Users</p>
                <span className="faset faset-top"></span>
                <span className="faset faset-bottom"></span>
              </NavLink>
            </li>
          </Permission>
          <li>
            <NavLink
              to="/manage-job-postings"
              className="link"
              activeClassName="is-active">
              <div className="black">
                <JobPosting />
              </div>
              <div className="white">
                <JobPostingWhite />
              </div>
              <p>Manage Job Postings</p>
              <span className="faset faset-top"></span>
              <span className="faset faset-bottom"></span>
            </NavLink>
          </li>
          <Permission roles={[Roles.super]}>
            <li>
              <NavLink
                to="/administration"
                className="link"
                activeClassName="is-active">
                <div className="black">
                  <AdminIcon />
                </div>
                <div className="white">
                  <AdminIconWhite />
                </div>
                <p>Administration</p>
                <span className="faset faset-top"></span>
                <span className="faset faset-bottom"></span>
              </NavLink>
            </li>
          </Permission>
          {userRole !== 'super' && loggedInUser.businessRelations.length > 1 && (
            <li className="switch-role" onClick={openPopup}>
              <SwitchIcon />
              <p className="switch-role__text">Change role</p>
            </li>
          )}
        </ul>
      </div>
      <Profile />
    </div>
  );
};

export default observer(Sidebar);
