// @ts-ignore
import { ReactComponent as LogoIcon } from '../../assets/img/logo-dark.svg';
// @ts-ignore
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import LoginScreen from '../../assets/img/login-screen-image.png';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Auth from '../../tools/Auth';
// @ts-ignore
import { useSnackbar } from 'react-simple-snackbar';
import { useMutation } from '@apollo/client';
import { ADMIN_BUSINESS_REGISTRATION } from '../../apollo/mutations';
import { userStore } from '../../store';
import extractGraphQLErrors from '../../tools/extractGraphqlErrors';
// @ts-ignore
import { default as EyeVisible } from '../../assets/img/eye-visible.svg';
// @ts-ignore
import { default as EyeInvisible } from '../../assets/img/eye-invisible.svg';

const Register: React.FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [registerUser] = useMutation(ADMIN_BUSINESS_REGISTRATION);
  const { setAuthorization } = userStore;
  const history = useHistory();

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password should be at least 8 characters')
      .max(120, 'Password should not exceed 120 characters'),
    phoneNumber: Yup.string()
      .test(
        'len',
        'Length should be 12 characters',
        (val) => val?.length === 12,
      )
      .test(
        'startsWith',
        'Phone should start with +1',
        (val) => !!val?.startsWith('+1'),
      ),
    firstName: Yup.string()
      .required('First name is required')
      .min(4, 'First name should be at least 4 characters')
      .max(120, 'First name should not exceed 120 characters'),
    lastName: Yup.string()
      .required('Last name is required')
      .min(4, 'Last name should be at least 4 characters')
      .max(120, 'Last name should not exceed 120 characters'),
    agreement: Yup.bool().oneOf(
      [true],
      'Accept Terms & Conditions is required',
    ),
  });

  const options = {
    style: {
      textAlign: 'center',
    },
  };

  const [openSnackbar] = useSnackbar(options);

  const redirect = (type: string) => {
    switch (type) {
      case 'forgot':
        history.push('/forgot-password');
        break;
      case 'login':
        history.push('/login');
        break;
      default:
        return false;
    }
  };

  const redirectToTerms = () => {
    history.push('/terms-and-conditions');
  };

  return (
    <div className="wrapper">
      <div className="auth-wrap">
        <div className="left-part">
          <div className="logo-wrap">
            <LogoIcon />
          </div>
          <div className="form-wrap">
            <div className="form-wrap--child">
              <h3 className="head">Sign Up</h3>
              <p className="subheadline">
                Welcome aboard! Complete the form below with your information to
                get things started.
              </p>
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                  firstName: '',
                  lastName: '',
                  phoneNumber: '+1',
                  agreement: false,
                }}
                validationSchema={SignupSchema}
                onSubmit={async (values) => {
                  const { email, password, firstName, lastName, phoneNumber } =
                    values;
                  setLoading(true);
                  sessionStorage.setItem('ongoingRegister', 'true');
                  try {
                    await Auth.registerUser(email, password);
                    await registerUser({
                      variables: {
                        dto: {
                          firstName,
                          lastName,
                          phone: phoneNumber,
                        },
                      },
                    });
                    await Auth.signInWithEmailAndPassword(
                      email,
                      password,
                      false,
                    );
                    setAuthorization(true);
                    sessionStorage.removeItem('ongoingRegister');
                  } catch (e: any) {
                    await Auth.removeUserFromFB();
                    const err = extractGraphQLErrors(e);
                    sessionStorage.removeItem('ongoingRegister');
                    if (e.graphQLErrors && err.phone) {
                      openSnackbar('Phone number is already in use');
                      setLoading(false);
                      return;
                    }
                    openSnackbar(e.message);
                    setLoading(false);
                  }
                  setLoading(false);
                }}>
                {({
                  errors,
                  touched,
                  handleSubmit,
                  setValues,
                  isSubmitting,
                  dirty,
                  isValid,
                }) => (
                  <Form>
                    <div className="input-wrap">
                      <label>FIRST NAME*</label>
                      <Field
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        className="input"
                      />
                      {errors.firstName && touched.firstName ? (
                        <div className="error">{errors.firstName}</div>
                      ) : null}
                    </div>
                    <div className="input-wrap">
                      <label>LAST NAME*</label>
                      <Field
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        className="input"
                      />
                      {errors.lastName && touched.lastName ? (
                        <div className="error">{errors.lastName}</div>
                      ) : null}
                    </div>
                    <div className="input-wrap">
                      <label>PHONE NUMBER*</label>
                      <Field
                        name="phoneNumber"
                        type="text"
                        placeholder="Phone Number"
                        className="input"
                      />
                      {errors.phoneNumber && touched.phoneNumber ? (
                        <div className="error">{errors.phoneNumber}</div>
                      ) : null}
                    </div>
                    <div className="input-wrap">
                      <label>EMAIL*</label>
                      <Field
                        name="email"
                        type="email"
                        placeholder="Email"
                        className="input"
                      />
                      {errors.email && touched.email ? (
                        <div className="error">{errors.email}</div>
                      ) : null}
                    </div>
                    <div className="input-wrap">
                      <label>PASSWORD*</label>
                      <Field
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        className="input"
                      />
                      {showPassword ? (
                        <img
                          onClick={() => setShowPassword(false)}
                          src={EyeVisible}
                          alt="EyeVisible"
                          className="input-wrap-img"
                        />
                      ) : (
                        <img
                          onClick={() => setShowPassword(true)}
                          src={EyeInvisible}
                          alt="EyeInvisible"
                          className="input-wrap-img"
                        />
                      )}
                      {errors.password && touched.password ? (
                        <div className="error">{errors.password}</div>
                      ) : null}
                    </div>
                    <p className="hint">Must be at least 8 characters.</p>
                    <div className="bottom-wrap register mb-3">
                      <Field
                        className="styled-checkbox"
                        id="styled-checkbox-1"
                        type="checkbox"
                        name="agreement"
                      />
                      <label htmlFor="styled-checkbox-1" className="flex">
                        <span>I agree to the</span>
                      </label>
                      <p
                        className="bottom-hint--left register"
                        onClick={redirectToTerms}>
                        Terms & Conditions
                      </p>
                    </div>
                    <div>
                      {errors.agreement && touched.agreement ? (
                        <div className="error terms">{errors.agreement}</div>
                      ) : null}
                    </div>
                    <div>
                      <button
                        className="btn btn--main w-100"
                        disabled={!(dirty && isValid) || isLoading}>
                        {isLoading ? 'Loading' : 'Create account'}
                      </button>
                    </div>
                    <div className="bottom-hint">
                      <p className="bottom-hint--left">
                        Already have an account?
                      </p>
                      <p
                        className="bottom-hint--right"
                        onClick={() => redirect('login')}>
                        Login
                      </p>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div
          className="image-wrap"
          style={{
            backgroundImage: 'url(' + LoginScreen + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}></div>
      </div>
    </div>
  );
};

export default Register;
