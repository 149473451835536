import Dropdown from '../../Shared/Dropdown';
import { BACKGROUND } from '../../../constants';
// @ts-ignore
import { default as Passed } from '../../../assets/img/passed-icon.svg';
import React, { useState, useEffect } from 'react';

interface BackgroundColoProps {
  updateData: (dataType: string, data: string) => void;
  selected: string;
}

type Item = {
  _id: string;
  name: string;
};

const SelectBackgroundColor: React.FC<BackgroundColoProps> = ({
  updateData,
  selected,
}) => {
  const [background, setBackground] = useState<Item[]>([]);

  useEffect(() => {
    const items = BACKGROUND.map((item, index) => {
      return {
        _id: index.toString(),
        name: item,
      };
    });
    setBackground(items);
  }, []);

  const onSelect = (data: Item) => {
    updateData('backgroundColor', data.name);
  };

  return (
    <div className="job-post--item">
      <div className="flex align-center justify-content-between head">
        <div className="flex align-center">
          {selected ? (
            <img src={Passed} alt="success" />
          ) : (
            <p className="number">11</p>
          )}
          <p className="headline">Select background color*</p>
        </div>
      </div>
      <div className="mt-3">
        <Dropdown
          up={true}
          onSelect={onSelect}
          data={background}
          selected={selected}
          placeholder="Select background color"
        />
      </div>
    </div>
  );
};

export default SelectBackgroundColor;
