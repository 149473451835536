import Auth from '../../tools/Auth';
import React, { useState, useEffect } from 'react';
import { userStore } from '../../store';
import { Roles } from '../../types/roles';
import { observer } from 'mobx-react';
import proxy from '../../tools/proxy';

interface Relation {
  _id: string;
  businessRole: string;
  business: {
    name: string;
  };
}

interface DataProps {
  data: any[];
  close: () => void;
}

const SelectBusinessRole: React.FC<DataProps> = ({ data, close }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const {
    setUserRole,
    userRole,
    setBusinessId,
    selectedBusinessId,
    updateBusiness,
  } = userStore;

  useEffect(() => {
    if (data) {
      const role: Relation[] = data.filter(
        (el: Relation) => el.businessRole.toLowerCase() === userRole,
      );
      if (!selectedBusinessId && role.length) {
        setBusinessId(role[0]._id);
      }
    }
  }, []);

  const onSelect = async (id: string) => {
    setBusinessId(id);
  };

  const onSubmitRole = async () => {
    if (selectedBusinessId) {
      setLoading(true);
      const role: Relation[] = data.filter(
        (item: Relation) => item._id === selectedBusinessId,
      );
      try {
        await Auth.selectRole(selectedBusinessId);
        if (role.length) {
          const selectedRole = role[0].businessRole.toLowerCase() as Roles;
          setUserRole(selectedRole);
        }
        updateBusiness();
        proxy.publish('switched_role');
        close();
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    }
  };

  return (
    <div className="select-role">
      <h3 className="mb-1">Select role</h3>
      <div className="select-role__wrap">
        {data.map((rel: Relation, index) => {
          return (
            <label key={rel._id}>
              <input
                type="radio"
                checked={selectedBusinessId === rel._id}
                onChange={() => onSelect(rel._id)}
              />
              <h4
                onClick={() => onSelect(rel._id)}
                className={`select-role__item ${
                  index !== data.length - 1 ? 'mb-1' : null
                } ${selectedBusinessId === rel._id ? 'selected' : null}`}>
                {rel.business.name}{' '}
                <span className="role">({rel.businessRole})</span>
              </h4>
            </label>
          );
        })}
      </div>
      <button
        onClick={onSubmitRole}
        disabled={isLoading}
        className="btn btn--main w-50 mt-3">
        {isLoading ? 'Loading' : 'Select'}
      </button>
    </div>
  );
};

export default observer(SelectBusinessRole);
