import React from 'react';
// @ts-ignore
import { ReactComponent as DeleteIcon } from '../../assets/img/delete-icon.svg';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
// @ts-ignore
import { default as CrossIcon } from '../../assets/img/cross.svg';
// @ts-ignore
import { default as editIcon } from '../../assets/img/edit-ic.svg';
import RemovePopup from './RemovePopup';
import { useParams } from 'react-router-dom';
import { location } from '../../types/locations';
import { useMutation } from '@apollo/client';
import { ADMIN_DELETE_LOCATION } from '../../apollo/mutations';
import { useRef } from 'react';
import { userStore } from '../../store';
import { observer } from 'mobx-react-lite';

interface CardBusinessProps {
  data: location;
  onSuccess: () => void;
  onEdit: (item: location) => void;
}

const CardBusiness: React.FC<CardBusinessProps> = ({
  data,
  onSuccess,
  onEdit,
}) => {
  const { userRole } = userStore;
  const ref = useRef<any>();
  const [removeBusiness] = useMutation(ADMIN_DELETE_LOCATION);
  const handleClick = () => {
    ref.current.open();
  };

  // const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const redirect = () => {
    // history.push(`/manage-businesses/${data._id}`);
  };

  const onRemove = async () => {
    try {
      await removeBusiness({
        variables: {
          businessId: id,
          locationId: data._id,
        },
      });
      onSuccess();
      ref.current.close();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="card-long">
      <div
        className="card-long__item name flex align-center"
        onClick={redirect}>
        <p className="name">{data.name}</p>
      </div>
      <div className="card-long__item address">
        <p>{data['address1']}</p>
      </div>
      <div className="card-long__item email">
        <p>{data.owner && data.owner.email}</p>
      </div>
      <div className="card-long__item phone">
        <p>
          {typeof data.phone === 'string'
            ? data.phone.includes('+1')
              ? data.phone
              : null
            : null}
        </p>
      </div>
      <div className="card-long__item action">
        {(userRole === 'super' || userRole === 'admin') && (
          <button
            className="action-button edit mr-1"
            onClick={() => onEdit(data)}></button>
        )}
        {(userRole === 'super' || userRole === 'admin') && (
          <div className="remove">
            {(userRole === 'super' || userRole === 'admin') && (
              <button
                className="action-button remove"
                onClick={handleClick}></button>
            )}
          </div>
        )}
      </div>
      <Popup
        closeOnDocumentClick={false}
        ref={ref}
        className="business"
        modal
        nested>
        {(close: any) => (
          <div className="modal business-modal">
            <img
              onClick={close}
              src={CrossIcon}
              alt="close"
              className="cross-icon"
            />
            <RemovePopup
              onClose={close}
              onRemoveItem={onRemove}
              itemToRemove={data.name || ''}
              type="location"
            />
          </div>
        )}
      </Popup>
    </div>
  );
};

export default observer(CardBusiness);
