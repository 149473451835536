import CardBusiness from '../../components/Shared/CardBusiness';
// @ts-ignore
import { ReactComponent as ArrowBottom } from '../../assets/img/arrow-bottom.svg';
import SortPopup from '../../components/Shared/SortPopup';
import { useState, useEffect, useRef } from 'react';
// @ts-ignore
import { default as AddIcon } from '../../assets/img/add-icon.svg';
import SearchInput from '../../components/Shared/SearchInput';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { observer } from 'mobx-react';
// @ts-ignore
import { default as CrossIcon } from '../../assets/img/cross.svg';
import AddBusiness from '../../components/AddBusiness/AddBusiness';
// @ts-ignore
import SnackbarProvider from 'react-simple-snackbar';
import { useLazyQuery } from '@apollo/client';
import { ADMIN_GET_ALL_ADMIN_BUSINESSES } from '../../apollo/queries';
import BusinessesSkeleton from './BusinessesSkeleton';
import { business } from '../../types/business';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { sortItems } from '../../types/sortPopup';
import ReactPaginate from 'react-paginate';
import { userStore } from '../../store';

type searchObj = {
  searchBy: string;
  sortBy: sortItems;
  paginationDto: {
    skip: number;
    limit: number;
  };
};

const LIMIT = 10;

const ManageBusiness: React.FC = () => {
  const { userRole, getBusiness } = userStore;
  const ref = useRef<any>();
  const [searchBy, setSearchBy] = useState<string>('');
  const [businessList, setBusinessList] = useState<[]>([]);
  const [itemToEdit, setItemToEdit] = useState<business | null>(null);
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
  const [sortPopupOpen, toggleSortPopup] = useState<boolean>(false);
  const [pagesCount, updatePagesCount] = useState<number>(0);
  const [currentQueryPage, setCurrentQueryPage] = useState<number>(0);
  const [searchedObject, setSearchObj] = useState<searchObj>({
    searchBy: '',
    sortBy: sortItems.BY_LAST_ADDED_DATE,
    paginationDto: {
      skip: 0,
      limit: LIMIT,
    },
  });

  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    setIsPageLoaded(true);
  }, []);

  useEffect(() => {
    const query = existingQuery();
    if ('page' in query) {
      const offset = (Number(query.page) - 1) * LIMIT;
      setCurrentQueryPage(Number(query.page) - 1);
      const obj = {
        ...query,
        paginationDto: {
          limit: LIMIT,
          skip: offset,
        },
      };
      setSearchObj((prevState): searchObj => {
        return { ...prevState, ...obj };
      });
    }

    // @ts-ignore
    if ('searchBy' in query && query.searchBy.length) {
      setSearchObj((prevState): searchObj => {
        return {
          ...prevState,
          paginationDto: {
            limit: 10,
            skip: 0,
          },
        };
      });
      setCurrentQueryPage(0);
    }
    setSearchObj((prevState): searchObj => {
      return { ...prevState, ...query };
    });
  }, [search]);

  const [getBusinesses, { data, loading }] = useLazyQuery(
    ADMIN_GET_ALL_ADMIN_BUSINESSES,
    {
      variables: {
        ...searchedObject,
      },
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    getBusinesses();
  }, [getBusiness]);

  useEffect(() => {
    if (data) {
      const count = Math.ceil(data.adminGetAllAdminBusinesses.count / LIMIT);
      updatePagesCount(count);
      setBusinessList(data.adminGetAllAdminBusinesses.collection);
    }
  }, [data]);

  useEffect(() => {
    getBusinesses();
    const queryParams = existingQuery();
    if ('searchBy' in queryParams) {
      setSearchBy(queryParams.searchBy as string);
    }
  }, []);

  const getBusinessOnAdd = () => {
    getBusinesses();
  };

  const onPopupToggle = () => {
    toggleSortPopup(!sortPopupOpen);
  };

  const onPopupClose = () => {
    toggleSortPopup(false);
  };

  const onSelect = (type: sortItems) => {
    const queryParams = existingQuery();
    const newQueries = { ...queryParams, sortBy: type };
    history.push({ search: qs.stringify(newQueries) });
    toggleSortPopup(false);
  };

  const existingQuery = () => {
    // eslint-disable-next-line no-restricted-globals
    return qs.parse(location.search);
  };

  const onSubmit = (searchValue: string) => {
    const queryParams = existingQuery();
    if ('page' in queryParams) {
      setCurrentQueryPage(0);
      delete queryParams.page;
    }
    const newQueries = { ...queryParams, searchBy: searchValue };
    history.push({ search: qs.stringify(newQueries) });
  };

  const setSortText = () => {
    const queryObject = existingQuery();
    if ('sortBy' in queryObject) {
      switch (queryObject.sortBy) {
        case sortItems.BY_NAME_A_TO_Z:
          return 'Name (A to Z)';
        case sortItems.BY_NAME_Z_TO_A:
          return 'Name (Z to A)';
        case sortItems.BY_LAST_ADDED_DATE:
          return 'By last added date';
      }
    }
    return 'By last added date';
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    if (!isPageLoaded) return;
    const queryParams = existingQuery();
    const newQueries = { ...queryParams, page: selectedItem.selected + 1 };
    history.push({ search: qs.stringify(newQueries) });
  };

  const onEditItem = ({ ...itemToEdit }: business) => {
    setItemToEdit(itemToEdit);
    ref.current.open();
  };

  const onClose = () => {
    setItemToEdit(null);
  };

  const onAddBusiness = () => {
    ref.current.open();
  };

  return (
    <div className={`business ${pagesCount > 1 ? 'pb-0' : ''}`}>
      <div>
        <div className="top-header mb-4 flex justify-between align-center">
          <h3>Manage Business</h3>
          <div className="flex align-center">
            <div className="mr-1">
              <SearchInput
                placeholder="Business name"
                inputValue={searchBy}
                onSubmit={onSubmit}
              />
            </div>
            {userRole === 'super' ? (
              <button
                className="btn btn--main pr-1 pl-1 business-btn"
                onClick={onAddBusiness}>
                Add Business
                <img src={AddIcon} alt="icon" className="icon-image" />
              </button>
            ) : null}
            <Popup
              closeOnDocumentClick={false}
              onClose={onClose}
              ref={ref}
              className="business"
              modal
              nested>
              {(close: any) => (
                <div className="modal business-modal">
                  <img
                    onClick={close}
                    src={CrossIcon}
                    alt="close"
                    className="cross-icon"
                  />
                  <SnackbarProvider>
                    <AddBusiness
                      itemToEdit={itemToEdit}
                      onClose={close}
                      onSuccess={getBusinessOnAdd}
                    />
                  </SnackbarProvider>
                </div>
              )}
            </Popup>
          </div>
        </div>
        {businessList.length ? (
          <div className="label-holder flex align-center">
            <span className="item" onClick={onPopupToggle}>
              {setSortText()}
            </span>
            <ArrowBottom
              className={sortPopupOpen ? 'up' : 'down'}
              onClick={onPopupToggle}
            />
            {sortPopupOpen && (
              <SortPopup onSelect={onSelect} onClose={onPopupClose} />
            )}
          </div>
        ) : null}
        {loading ? (
          <BusinessesSkeleton />
        ) : (
          businessList.map((item: business, index: number) => {
            return (
              <div
                key={index}
                className={`${index > 0 ? 'mt-2' : ''} ${
                  index === 0 ? 'first' : ''
                }`}>
                <CardBusiness
                  onEdit={onEditItem}
                  data={item}
                  onSuccess={getBusinessOnAdd}
                />
              </div>
            );
          })
        )}
      </div>
      <div>
        {pagesCount > 1 && (
          <div className="pagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pagesCount}
              initialPage={currentQueryPage}
              disableInitialCallback={true}
              previousLabel="<"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(ManageBusiness);
