// @ts-ignore
import { ReactComponent as LogoIcon } from '../../assets/img/logo-dark.svg';
// @ts-ignore
import React from 'react';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import LoginScreen from '../../assets/img/login-screen-image.png';
import { useFormik } from 'formik';

const ZipCode: React.FC = () => {
  const formik = useFormik({
    initialValues: {
      first: '',
      second: '',
      third: '',
      fourth: '',
      fifth: '',
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const history = useHistory();

  const redirect = () => {
    history.push('/login');
  };

  return (
    <div className="wrapper">
      <div className="auth-wrap">
        <div className="left-part">
          <div className="logo-wrap">
            <LogoIcon />
          </div>
          <div className="form-wrap">
            <div className="form-wrap--child">
              <h3 className="head">Enter the Zip Code</h3>
              <p className="subheadline">
                We are currently working only in a certain area. To register
                please enter the zip code to verify if we work in your area.
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div className="zip-wrap">
                  <div className="input-wrap">
                    <input
                      id="first"
                      name="first"
                      type="number"
                      className="input zip"
                      onChange={formik.handleChange}
                      value={formik.values.first}
                    />
                  </div>
                  <div className="input-wrap">
                    <input
                      id="second"
                      name="second"
                      type="number"
                      className="input zip"
                      onChange={formik.handleChange}
                      value={formik.values.second}
                    />
                  </div>
                  <div className="input-wrap">
                    <input
                      id="third"
                      name="third"
                      type="number"
                      className="input zip"
                      onChange={formik.handleChange}
                      value={formik.values.third}
                    />
                  </div>
                  <div className="input-wrap">
                    <input
                      id="fourth"
                      name="fourth"
                      type="number"
                      className="input zip"
                      onChange={formik.handleChange}
                      value={formik.values.fourth}
                    />
                  </div>
                  <div className="input-wrap">
                    <input
                      id="fifth"
                      name="fifth"
                      type="number"
                      className="input zip"
                      onChange={formik.handleChange}
                      value={formik.values.fifth}
                    />
                  </div>
                </div>
                <div>
                  <button className="btn btn--main">Verify zip code</button>
                </div>
              </form>
              <div className="bottom-hint">
                <p className="bottom-hint--left">Already have an account?</p>
                <p className="bottom-hint--right" onClick={redirect}>
                  Log in
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="image-wrap"
          style={{
            backgroundImage: 'url(' + LoginScreen + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}></div>
      </div>
    </div>
  );
};

export default ZipCode;
